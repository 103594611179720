import GroupWorkIcon from "@material-ui/icons/GroupWork"
import { AreaClusterList } from "./AreaClusterList"
import { AreaClusterEdit } from "./AreaClusterEdit"
import { AreaClusterCreate } from "./AreaClusterCreate"

export default {
  list: AreaClusterList,
  edit: AreaClusterEdit,
  create: AreaClusterCreate,
  icon: GroupWorkIcon,
}
