import englishMessages from "ra-language-english"

export const messages = {
  simple: {
    action: {
      close: "Close",
      resetViews: "Reset views",
    },
    "create-post": "New post",
  },
  ...englishMessages,
  pos: {
    menu: {
      products: "Products",
      discounts: "Discounts",
      shippings: "Shippings",
      users: "Users",
      agents: "Agents",
      orders: "Orders",
      cms: "CMS",
      urlShortner: "URL Shortner",
      credits: "Credits",
    },
    configuration: "Configurations",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    language: "Language",
    dashboard: {
      welcome: {
        title: "Welcome to Bhotahiti admin dashboard",
        subtitle:
          "A First and only online wholesale bazaar in Nepal. Business to Business wholesale ecommerce platform.",
        ra_button: "Dashboard",
      },
      newOrders: "New Orders",
      users: "Users",
      vendors: "Vendors",
      products: "Products",
      margin: "Margin",
      totalSales: "Total Sales",
      vendorPayable: "Vendor Payable",
      vendorRequest: "Payment Request",
      newVendors: "New Vendor Request",
      newAgents: "New Agent Request",
      sales: "Sold Item",
      deliveryBoyReceivable: "Payment Receivable (Delivery Partners)",
      agentPaymentRequest: "Agent Payment Request",
      monthHistory: "30 Days Sales History",
      shippings: "Shippings",
    },
    accountings: {
      totalPayable: "Total Payable",
      totalReceivable: "Total Receivable",
      vendorPayable: "Vendor Payable",
      vendorReceivable: "Vendor Receivable",
      deliveryBoyReceivable: "Delivery Partner Payable",
    },
  },

  resources: {
    oms: { name: "OMS |||| OMS" },
    packagingBox: { name: "Packaging Box |||| Packaging Box" },
    posts: {
      name: "Post |||| Posts",
      fields: {
        average_note: "Average note",
        body: "Body",
        comments: "Comments",
        commentable: "Commentable",
        commentable_short: "Com.",
        created_at: "Created at",
        notifications: "Notifications recipients",
        nb_view: "Nb views",
        password: "Password (if protected post)",
        pictures: "Related Pictures",
        published_at: "Published at",
        teaser: "Teaser",
        tags: "Tags",
        title: "Title",
        views: "Views",
        authors: "Authors",
      },
    },
    comments: {
      name: "Comment |||| Comments",
      fields: {
        body: "Body",
        created_at: "Created at",
        post_id: "Posts",
        author: {
          name: "Author",
        },
      },
    },
    accounting: {
      name: "Accounting |||| Accountings",
    },
    notifications: {
      name: "Nofiication CMS |||| Notifications CMS",
      fieldGroups: {
        information: "Information",
        image: "Image",
        options: "Options",
      },
    },
    users: {
      name: "User |||| Users",
      fieldGroups: {
        information: "Information",
        demographics: "Demographic",
        roles: "Roles",
        options: "Options",
        vendorProps: "Vendor Properties",
        userPreference: "User Preference",
        socialMediaLinks: "Social Media Links",
        payment: "Payments",
        faveCategories: "Favorite Categories",
        map: "Map",
      },
      filters: {
        roles: "Roles",
        activationStatus: "Activation Status",
      },
    },
    agents: {
      name: "Agent |||| Agents",
      fieldGroups: {
        user: "User",
        brands: "Affiliated Brands",
        categories: "Affiliated Categories",
        products: "Affiliated Products",
        options: "Options",
      },
    },
    agentScheme: {
      name: "Agent Scheme |||| Agent Schemes",
      fieldGroups: {
        title: "Title",
        agentScheme: "Agent Scheme",
        brands: "Affiliated Brands",
        categories: "Affiliated Categories",
        products: "Affiliated Products",
        options: "Options",
      },
    },
    permissions: {
      name: "Permission |||| Permissions",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    roles: {
      name: "Role |||| Roles",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    attributes: {
      name: "Attribute |||| Attributes",
      fieldGroups: {
        information: "Information",
        values: "Values",
        options: "Options",
      },
    },
    brands: {
      name: "Brand |||| Brands",
      fieldGroups: {
        information: "Information",
        image: "Image",
        options: "Options",
        seo: "SEO",
      },
    },
    categories: {
      name: "Category |||| Categories",
      fieldGroups: {
        information: "Information",
        image: "Image",
        options: "Options",
        seo: "SEO",
      },
    },
    tags: {
      name: "Tag |||| Tags",
      fieldGroups: {
        information: "Information",
      },
    },
    products: {
      name: "Product |||| Products",
      fieldGroups: {
        attributes: "Attributes",
        main: "Main",
        price: "Price",
        media: "Media",
        information: "Information",
        meta: "Meta",
        options: "Options",
        dimensions: "Dimensions",
        variations: "Variations",
        margin: "Margin",
        seo: "SEO",
        shipping: "Shipping",
        relatedProducts: "Related Products",
        discount: "Discount",
        tax: "Tax",
      },
    },
    reviews: {
      name: "Review |||| Reviews",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    discounts: {
      name: "Discount |||| Discounts",
      fieldGroups: {
        information: "Information",
        options: "Options",
        discounts: "Discounts",
      },
    },
    coupons: {
      name: "Coupon |||| Coupons",
      fieldGroups: {
        information: "Information",
        options: "Options",
        discounts: "Coupons",
      },
    },
    specialDiscounts: {
      name: "Special Discount |||| Special Discounts",
      fieldGroups: {
        information: "Information",
        options: "Options",
        specialDiscounts: "Special Discounts",
      },
    },
    specialDiscountRequests: {
      name: "Special Discount Request |||| Special Discount Requests",
      fieldGroups: {
        information: "Information",
        options: "Options",
        specialDiscounts: "Special Discounts",
      },
    },
    countries: {
      name: "Country |||| Countries",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    states: {
      name: "State |||| States",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    districts: {
      name: "District |||| Districts",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    areas: {
      name: "Area |||| Areas",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    areaCluster: {
      name: "Area Cluster |||| Area Clusters",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    shippingMethods: {
      name: "Shipping Method |||| Shipping Methods",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
    },
    shippings: {
      name: "Shipping |||| Shippings",
      fieldGroups: {
        information: "Information",
        parameter: "Parameter",
        delivery: "Delivery",
        cost: "Cost",
        options: "Options",
      },
    },
    orders: {
      name: "Order |||| Orders",
      fieldGroups: {
        information: "Information",
        options: "Options",
      },
      filters: {
        orderStatus: "Order Status",
        paymentStatus: "Payment Status",
        paymentType: "Payment Type",
        paymentToAdmin: "Payment to Admin",
      },
    },
    orderItems: {
      name: "Order Item |||| Order Items",
      fieldGroups: {
        information: "Information",
      },
    },
    orderItemsV1: {
      name: "Order Item (new) |||| Order Items (new)",
    },
    brandCMS: {
      name: "Brand CMS |||| Brand CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    categoryCMS: {
      name: "Category CMS |||| Category CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    productCMS: {
      name: "Product CMS |||| Product CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    sliderCMS: {
      name: "Slider CMS |||| Slider CMS",
      fieldGroups: {
        information: "Information",
        image: "Image",
        mobile: "Mobile",
        option: "Option",
      },
    },
    menuCMS: {
      name: "Menu CMS |||| Menu CMS",
      fieldGroups: {
        information: "Information",
      },
    },
    pageCMS: {
      name: "Page CMS |||| Page CMS",
      fieldGroups: {
        information: "Information",
        seo: "SEO",
      },
    },
    bannerCMS: {
      name: "Banner CMS |||| Banner CMS",
      fieldGroups: {
        information: "Information",
        seo: "SEO",
        image: "Image",
        mobile: "Mobile",
        option: "Option",
      },
    },
    sales: {
      name: "Sales",
      filters: {
        paymentRequest: "Request for Payment",
        paymentToVendor: "Payment to Vendor",
        saleType: "Sale Type",
        refundPaymentStatus: "Refund Payment to Cutomer",
        refundFromVendorStatus: "Refund Payment from Vendor",
      },
    },
    transport: {
      name: "Transport |||| Transports",
      fieldGroups: {
        information: "Information",
        options: "Options",
        branch: "Branch",
      },
    },
    creditSchemes: {
      name: "Credit Scheme |||| Credit Schemes",
    },
    creditTransactions: {
      name: "Credit Transaction |||| Credit Transactions",
    },
    vendorPayment: {
      name: "Vendor Payment |||| Vendor Payments",
    },
  },
  post: {
    list: {
      search: "Search",
    },
    form: {
      summary: "Summary",
      body: "Body",
      miscellaneous: "Miscellaneous",
      comments: "Comments",
    },
    edit: {
      title: 'Post "%{title}"',
    },
    action: {
      save_and_edit: "Save and Edit",
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
      save_with_average_note: "Save with Note",
    },
  },
  comment: {
    list: {
      about: "About",
    },
  },
  user: {
    list: {
      search: "Search",
    },
    form: {
      summary: "Summary",
      security: "Security",
    },
    edit: {
      title: 'User "%{title}"',
    },
    action: {
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
  product: {
    action: {
      save_and_add: "Save and Add",
      save_and_show: "Save and Show",
    },
  },
}

export default messages
