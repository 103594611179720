import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "proxy-polyfill"
import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import dataProviderFactory from "./dataProvider"
import addUploadFeature from "./addUploadFeature"

const prepareDataProvider = async () => {
  const dataProvider = addUploadFeature(dataProviderFactory("api/admin"))
  return { dataProvider }
}

prepareDataProvider().then(({ dataProvider }) => {
  ReactDOM.render(
    <App dataProvider={dataProvider} />,
    document.getElementById("root")
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
