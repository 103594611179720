import { makeStyles, TextField } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { useFormState } from "react-final-form"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  BooleanInput,
  SelectInput,
  minValue,
  DateTimeInput,
  NumberInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  discountType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  discountOn: { display: "block" },
}

const useStyles = makeStyles(styles)

const DiscountTitle = ({ record }: any) => (
  <span>Edit Discount {record ? `"${record.title}"` : `#${record.id}`}</span>
)

const DiscountToInput = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  console.log("values", values)
  return (
    <>
      {values.discountOn === "brand" && (
        <>
          <ReferenceInput
            label="Brands"
            source="discountTo"
            reference="products/brands"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "category" && (
        <>
          <ReferenceInput
            label="Category"
            source="discountTo"
            reference="products/categories"
            sort={{ field: "title", order: "ASC" }}
            filter={{ entry: true }}
          >
            <AutocompleteInput optionValue="id" optionText="title" />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "product" && (
        <>
          <ReferenceInput
            label="Product"
            source="discountTo"
            reference="products/products"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "tag" && (
        <>
          <ReferenceInput
            label="Tag"
            source="discountTo"
            reference="products/tags"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "vendor" && (
        <>
          <ReferenceInput
            label="Vendor"
            source="discountTo"
            reference="users/users"
            sort={{ field: "name", order: "ASC" }}
            filter={{ role: "VENDOR" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </>
      )}
    </>
  )
}

const VisibleInput = (props) => {
  const state = useFormState()
  const defaultValue = state.modified?.startsAt
    ? state.values.startsAt <= Date.now()
      ? true
      : false
    : state.initialValues?.isVisible
  return <BooleanInput {...props} defaultValue={defaultValue} />
}

const condition = (val: string) => !!val && val.trim().length > 5

export const DiscountEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<DiscountTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.discounts.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="slug"
          fullWidth
          formClassName={classes.slug}
          helperText="It must be unique through discounts list."
        />
        <TextInput
          source="description"
          fullWidth
          multiline
          formClassName={classes.description}
        />

        <SectionTitle label="resources.discounts.fieldGroups.discounts" />

        <SelectInput
          source="discountType"
          choices={[
            { id: "rupees", name: "Fixed Rupees" },
            { id: "percentage", name: "Percentage" },
          ]}
          validate={required()}
          formClassName={classes.discountType}
        />
        <NumberInput
          source="value"
          validate={[required(), minValue(0)]}
          formClassName={classes.value}
        />
        <SelectInput
          source="discountOn"
          choices={[
            { id: "brand", name: "Brand" },
            { id: "category", name: "Category" },
            { id: "product", name: "Product" },
            { id: "vendor", name: "Vendor" },
            { id: "tag", name: "Tag" },
          ]}
          validate={required()}
          formClassName={classes.discountOn}
        />

        <DiscountToInput source="discountTo" />

        <DateTimeInput source="startsAt" validate={required()} />
        <DateTimeInput source="expiresAt" validate={required()} />
        <SectionTitle label="resources.discounts.fieldGroups.options" />
        <VisibleInput label="Visible" source="isVisible" />
      </SimpleForm>
    </Edit>
  )
}
