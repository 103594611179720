import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { useFormState } from "react-final-form"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  ReferenceInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  minValue,
  required,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  discountType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  discountOn: { display: "block" },
}

const useStyles = makeStyles(styles)

const VisibleInput = (props) => {
  const { values } = useFormState()
  const defaultValue = values.startsAt <= Date.now() ? true : false
  return <BooleanInput {...props} defaultValue={defaultValue} />
}

const condition = (val: string) => !!val && val.trim().length > 6

const DiscountToInput = (props) => {
  const classes = useStyles()
  const { values } = useFormState()
  console.log("values", values)
  return (
    <>
      {values.discountOn === "brand" && (
        <>
          <ReferenceInput
            label="Brands"
            source="discountTo"
            reference="products/brands"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "category" && (
        <>
          <ReferenceInput
            label="Category"
            source="discountTo"
            reference="products/categories"
            sort={{ field: "title", order: "ASC" }}
            filter={{ entry: true }}
          >
            <AutocompleteInput optionValue="id" optionText="title" />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "product" && (
        <>
          <ReferenceInput
            label="Product"
            source="discountTo"
            reference="products/products"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "tag" && (
        <>
          <ReferenceInput
            label="Tag"
            source="discountTo"
            reference="products/tags"
            sort={{ field: "title", order: "ASC" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="title"
            />
          </ReferenceInput>
        </>
      )}
      {values.discountOn === "vendor" && (
        <>
          <ReferenceInput
            label="Vendor"
            source="discountTo"
            reference="users/users"
            sort={{ field: "name", order: "ASC" }}
            filter={{ role: "VENDOR" }}
          >
            <AutocompleteInput
              style={{ width: 544 }}
              optionValue="id"
              optionText="name"
            />
          </ReferenceInput>
        </>
      )}
    </>
  )
}

export const DiscountCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/discounts",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Discount saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save} initialValues={() => ({ from: "discount" })}>
        <SectionTitle label="resources.discounts.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="slug"
          fullWidth
          formClassName={classes.slug}
          helperText="It must be unique through discounts list."
        />
        <TextInput
          source="description"
          fullWidth
          multiline
          formClassName={classes.description}
        />

        <SectionTitle label="resources.discounts.fieldGroups.discounts" />

        <SelectInput
          source="discountType"
          choices={[
            { id: "rupees", name: "Fixed Rupees" },
            { id: "percentage", name: "Percentage" },
          ]}
          validate={required()}
          formClassName={classes.discountType}
        />
        <NumberInput
          source="value"
          validate={[required(), minValue(0)]}
          formClassName={classes.value}
        />
        <SelectInput
          source="discountOn"
          choices={[
            { id: "brand", name: "Brand" },
            { id: "category", name: "Category" },
            { id: "product", name: "Product" },
            { id: "vendor", name: "Vendor" },
            { id: "tag", name: "Tag" },
          ]}
          validate={required()}
          formClassName={classes.discountOn}
        />

        <DiscountToInput source="discountTo" />

        <DateTimeInput source="startsAt" validate={required()} />
        <DateTimeInput source="expiresAt" validate={required()} />
        <SectionTitle label="resources.discounts.fieldGroups.options" />
        <VisibleInput label="Visible" source="isVisible" />
      </SimpleForm>
    </Create>
  )
}
