import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  EditProps,
  AutocompleteInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const condition = (val: string) => !!val && val.trim().length > 6

const AreaTitle = ({ record }: any) => (
  <span>Edit Area {record ? `"${record.name}"` : `#${record.id}`}</span>
)

export const AreaEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<AreaTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.areas.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <ReferenceInput
          label="District"
          source="district"
          reference="shippings/districts"
          validate={requiredValidate}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={(val: string) =>
            condition(val) ? { name: val.trim() } : {}
          }
          perPage={1000}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <SectionTitle label="resources.areas.fieldGroups.options" />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
