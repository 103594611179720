import * as React from "react"
import { FC } from "react"
import { FieldProps } from "react-admin"

import { AreaCluster } from "../../ts/types"

const IDField: FC<FieldProps<AreaCluster>> = ({ record }) => {
  const recordId = record?.id?.toString().slice(record.id.toString().length - 5)

  return <span>{recordId}</span>
}

export default IDField
