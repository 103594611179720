import PublicIcon from "@material-ui/icons/Public"
import { CountryCreate } from "./CountryCreate"
import { CountryEdit } from "./CountryEdit"
import { CountryList } from "./CountryList"

export default {
  create: CountryCreate,
  list: CountryList,
  edit: CountryEdit,
  icon: PublicIcon,
}
