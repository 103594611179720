import { fetchUtils } from "react-admin"

const apiUrl = "api/admin"
const httpClient = fetchUtils.fetchJson

const buildFormData = (formData, data, parentKey = "") => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? "" : data

    formData.append(parentKey, value)
  }
}

const addUploadCapabilities = (dataProvider) => ({
  ...dataProvider,
  create: (resource, params) => {
    if (
      (resource === "products/brands" ||
        resource === "products/categories" ||
        resource === "products/products" ||
        resource === "cms/sliders" ||
        resource === "cms/banners" ||
        resource === "users/special-discount-request" ||
        resource === "products/reviews" ||
        resource === "users/users" ||
        resource === "notifications/notifications" ||
        resource === "vendor-payments") &&
      (params.data.media ||
        params.data.image ||
        params.data.bannerImage ||
        params.data.avatar ||
        params.data.panImage ||
        params.data.paymentImage)
    ) {
      let formData = new FormData()

      if (params.data.media) {
        for (let pic of params.data.media) {
          formData.append("media", pic.rawFile)
        }
        delete params.data.media
      }

      if (params.data.image) {
        formData.append("image", params.data.image.rawFile)
        delete params.data.image
      }

      if (params.data.bannerImage) {
        formData.append("bannerImage", params.data.bannerImage.rawFile)
        delete params.data.bannerImage
      }

      if (params.data.avatar) {
        formData.append("avatar", params.data.avatar.rawFile)
        delete params.data.avatar
      }

      if (params.data.panImage) {
        formData.append("panImage", params.data.panImage.rawFile)
        delete params.data.panImage
      }

      if (params.data.paymentImage) {
        formData.append("paymentImage", params.data.paymentImage.rawFile)
        delete params.data.paymentImage
      }

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }
    // fallback to the default implementation
    return dataProvider.create(resource, params)
  },
  update: (resource, params) => {
    // Image with media field (products, reviews)
    if (
      resource === "products/products" ||
      (resource === "products/reviews" && params.data.media)
    ) {
      const newPictures = params.data.media.filter(
        (p) => p.rawFile instanceof File
      )
      const formerPictures = params.data.media.filter(
        (p) => !(p.rawFile instanceof File)
      )

      let formData = new FormData()

      newPictures.forEach((pic) => {
        formData.append("media", pic.rawFile)
      })

      formerPictures.forEach((pic) => {
        formData.append("media", pic)
      })

      delete params.data.media

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }
    // Image with image field (products, banners, sliders)
    else if (
      (resource === "products/brands" ||
        resource === "products/categories" ||
        resource === "cms/sliders" ||
        resource === "cms/banners" ||
        resource === "users/special-discount-request" ||
        resource === "notifications/notifications" ||
        resource === "vendor-payments") &&
      (params.data.image || params.data.bannerImage || params.data.paymentImage)
    ) {
      let formData = new FormData()

      if (typeof params.data.image.rawFile !== "undefined") {
        formData.append("image", params.data.image.rawFile)
        delete params.data.image
      }

      if (typeof params.data?.bannerImage?.rawFile !== "undefined") {
        formData.append("bannerImage", params.data.bannerImage.rawFile)
        delete params.data.bannerImage
      }

      if (typeof params.data?.paymentImage?.rawFile !== "undefined") {
        formData.append("paymentImage", params.data.paymentImage.rawFile)
        delete params.data.paymentImage
      }

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }
    // Image with avatar field (users)
    else if (
      resource === "users/users" &&
      (params.data.avatar || params.data.panImage)
    ) {
      let formData = new FormData()

      if (typeof params.data?.avatar?.rawFile !== "undefined") {
        formData.append("avatar", params.data.avatar.rawFile)
        delete params.data.avatar
      }

      if (typeof params.data?.panImage?.rawFile !== "undefined") {
        formData.append("panImage", params.data.panImage.rawFile)
        delete params.data.panImage
      }

      buildFormData(formData, params.data)

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: "PUT",
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
    }

    // fallback to the default implementation
    return dataProvider.update(resource, params)
  },
})

export default addUploadCapabilities
