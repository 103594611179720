import React, { useState } from "react"
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useUpdateMany,
  useRefresh,
  useUnselectAll,
  ReferenceInput,
  useUpdate,
  TextInput,
  SelectInput,
  DateInput,
  NumberInput,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "mui-rff"
import { Box, MenuItem, Typography } from "@material-ui/core"
import UpdateIcon from "@material-ui/icons/Update"
import { optionRenderer, orderStatus } from "../resources/orders/common"

function OrderEditButton({ order, packagingBox }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [update, { loading }] = useUpdate("orders/orders")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    if (values.orderStatus === "delivering") {
      update(
        "oms/boxes",
        packagingBox?.id || packagingBox?._id,
        { isCompleted: true },
        {}
      )
    }
    update("orders/orders", order?.id || order?._id, { ...values }, order, {
      onSuccess: () => {
        setShowDialog(false)
        refresh()
        notify("Order is updated")
        unselectAll("orders/orders")
      },
      onFailure: ({ error }) => {
        notify(error.message, "error")
      },
    })
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Update Order & Transport"
        variant="outlined"
        color="primary"
      >
        <UpdateIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update Order"
      >
        <DialogTitle>Update Order</DialogTitle>

        <FormWithRedirect
          resource="orders/orders"
          initialValues={order}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving, record }) => (
            <>
              <DialogContent>
                <SelectInput
                  variant="outlined"
                  source="orderStatus"
                  choices={orderStatus}
                  optionText={optionRenderer}
                  fullWidth
                />
                <Typography variant="h6" gutterBottom>
                  Transport
                </Typography>
                <Box display="flex" gridGap={20}>
                  <Box flex={1}>
                    <ReferenceInput
                      source="transportId"
                      reference="transport/transport"
                      allowEmpty
                      emptyText="No Transport"
                      fullWidth
                      // style={{ width: "80%" }}
                    >
                      <SelectInput
                        variant="outlined"
                        optionValue="id"
                        optionText="name"
                      />
                    </ReferenceInput>
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      variant="outlined"
                      source="builtyNumber"
                      label="Builty Number"
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex" gridGap={20}>
                  <Box flex={1}>
                    <SelectInput
                      variant="outlined"
                      source="transportCostPayment"
                      choices={[
                        { id: "paid", name: "Paid" },
                        { id: "toPay", name: "To Pay" },
                        {
                          id: "fullFilledByVendor",
                          name: "Fullfilled by Vendor",
                        },
                        {
                          id: "fullFilledByAdmin",
                          name: "Fullfilled by Admin",
                        },
                      ]}
                      allowEmpty
                      emptyText=""
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <DateInput
                      variant="outlined"
                      source="transportEta"
                      label="ETA"
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex" gridGap={20}>
                  <Box flex={1}>
                    <NumberInput
                      variant="outlined"
                      source="transportCost"
                      label="Transport Cost"
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}></Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default OrderEditButton
