import LabelIcon from '@material-ui/icons/Label';
import { TagCreate } from "./TagCreate"
import { TagEdit } from "./TagEdit"
import { TagList } from "./TagList"

export default {
  list: TagList,
  create: TagCreate,
  edit: TagEdit,
  icon: LabelIcon,
}
