import * as React from "react"
import { useGetList } from "react-admin"
import { forwardRef } from "react"
import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin"
import Typography from "@material-ui/core/Typography"
import SettingsIcon from "@material-ui/icons/Settings"
import { makeStyles } from "@material-ui/core/styles"
import Notifications from "./Notifications"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
})

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate()
  return (
    // @ts-ignore
    <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText={translate("pos.configuration")}
      leftIcon={<SettingsIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  )
})

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
)

const useGetTotals = () => {
  const { total: totalPending } = useGetList(
    "products/products",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { "meta.isApproved": false, "meta.isDeleted": false }
  )

  return totalPending
}

const CustomAppBar = (props: any) => {
  const classes = useStyles()
  const total = useGetTotals() as any
  const ref = React.useRef<any>()
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      {/* TODO: LOGO here */}
      {/* <span className={classes.spacer} /> */}
      <Notifications total={total} ref={ref} />
    </AppBar>
  )
}

export default CustomAppBar
