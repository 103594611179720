import { FC } from "react"
import {
  List,
  Datagrid,
  DateField,
  Link,
  FunctionField,
  NumberField,
  downloadCSV,
  Pagination,
  FilterProps,
  Filter,
  ReferenceInput,
  DateInput,
  AutocompleteInput,
  Button,
  ListActionsProps,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
} from "react-admin"
import moment from "moment"
import GetAppIcon from "@material-ui/icons/GetApp"
import * as jsonExport from "jsonexport/dist"

import { IDField } from "../../common/form"

const LinkField = ({ source, field, reference, value, label }: any) => (
  <FunctionField
    source={source}
    label={label}
    render={(record) =>
      record &&
      record[field] && (
        <Link to={`/${reference}/${record[field].id}`}>
          {record[field][value]}
        </Link>
      )
    }
  />
)

const exporter = (sales) => {
  const salesForExport = sales.map((sale) => {
    const {
      meta,
      paymentStatus,
      paymentRequest,
      affiliation,
      productId,
      userId,
      variation,
      vendorId,
      updatedAt,
      product,
      user,
      vendor,
      order,
      orderId,
      hasVariation,
      createdAt,
      ...saleForExport
    } = sale
    saleForExport.product = product.title
    saleForExport.user = `${user?.name} (${user?.phone})`
    saleForExport.vendor = `${vendor?.name} (${vendor?.phone})`
    saleForExport.order = order?.trackingNumber
    saleForExport.salesAt = moment(createdAt).format("MM/DD/YYYY")
    return saleForExport
  })
  jsonExport(
    salesForExport,
    {
      headers: [
        "id",
        "product",
        "quantity",
        "price",
        "margin",
        "vendorPayable",
        "vendor",
        "user",
        "order",
        "salesAt",
        "discount",
        "specialDiscount",
        "note",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "sales")
    }
  )
}

const ListActions = (props: ListActionsProps) => {
  const { className, filterValues, ...rest } = props

  const { total } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        label="PDF"
        href={`${
          process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
        }/api/admin/sales/sales/pdf?filter=${JSON.stringify(filterValues)}`}
        disabled={total === 0}
      >
        <GetAppIcon />
      </Button>

      <Button
        label="CSV"
        href={`${
          process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
        }/api/admin/sales/sales/csv?filter=${JSON.stringify(filterValues)}`}
        disabled={total === 0}
      >
        <GetAppIcon />
      </Button>
    </TopToolbar>
  )
}

const SaleFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendorId"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      alwaysOn
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>
    <DateInput source="dateGte" label="Date from" alwaysOn />
    <DateInput source="dateLt" label="Date to" alwaysOn />
  </Filter>
)

const SalePagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
)

export default function Sale() {
  return (
    <List
      title="Sales"
      resource="sales/sales"
      basePath="sales/sales"
      perPage={25}
      sort={{ field: "createdAt", order: "DESC" }}
      // exporter={exporter}
      pagination={<SalePagination />}
      filters={<SaleFilter />}
      actions={<ListActions />}
      // bulkActionButtons={false}
      hasCreate={false}
    >
      <Datagrid>
        <DateField source="createdAt" showTime />
        <IDField source="id" sortable={false} />
        <LinkField
          source="product.title"
          label="Product"
          field="product"
          reference="products/products"
          value="title"
        />
        <LinkField
          source="user.name"
          label="User"
          field="user"
          reference="users/users"
          value="name"
        />
        <LinkField
          source="vendor.name"
          label="Vendor"
          field="vendor"
          reference="users/users"
          value="name"
        />
        <NumberField source="quantity" textAlign="center" />
        <FunctionField
          label="Order"
          render={(record) =>
            record &&
            record.order && (
              <Link to={`/orders/orders/${record.order.id}`}>
                TN-{record.order.trackingNumber}
              </Link>
            )
          }
        />

        <FunctionField
          label="Discount"
          render={(record) =>
            record && record.discount ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.discount.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : (
              <span style={{ fontWeight: "bold" }}>रू 0.00</span>
            )
          }
        />

        <FunctionField
          label="Special Discount"
          render={(record) =>
            record && record.specialDiscount ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.specialDiscount.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : (
              <span style={{ fontWeight: "bold" }}>रू 0.00</span>
            )
          }
        />

        <FunctionField
          label="Price"
          render={(record) =>
            record.vendorPayable && record.margin ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {(record.vendorPayable + record.margin).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </span>
            ) : null
          }
        />
      </Datagrid>
    </List>
  )
}
