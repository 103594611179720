import React, { useCallback, useState } from "react"
import { Card, CardHeader, CardContent, Radio } from "@material-ui/core"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts"
import { subDays } from "date-fns"

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"]

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? "start" : "end"

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.vendor.name.slice(0, 15)}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.vendor.name.slice(0, 15)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#333"
      >{`Sold ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={36}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`($ ${payload.totalPrice.toFixed(2)})`}
      </text>
    </g>
  )
}

export default function TopSellerPieChart(props: any) {
  const [activeIndex, setActiveIndex] = useState(0)
  const aMonthAgo = subDays(new Date(), 30)
  const aWeekAgo = subDays(new Date(), 7)
  const aDayAgo = subDays(new Date(), 1)
  const [selectedValue, setSelectedValue] = useState("month")
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  const handleChange = (event) => {
    console.log(event.target.value)
    setSelectedValue(event.target.value)
    if (event.target.value === "month") {
      props.setTopSalerDateFilter(aMonthAgo.toISOString())
    } else if (event.target.value === "week") {
      props.setTopSalerDateFilter(aWeekAgo.toISOString())
    } else if (event.target.value === "day") {
      props.setTopSalerDateFilter(aDayAgo.toISOString())
    }
  }

  return (
    <Card>
      <div style={{display: "flex", alignItems: "center"}}>
        <CardHeader title="Top Sellers" />
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <div>
            <Radio
              checked={selectedValue === "month"}
              onChange={handleChange}
              value="month"
              name="radio-buttons"
            />
            <span>Monthly</span>
          </div>
          <div>
            <Radio
              checked={selectedValue === "week"}
              onChange={handleChange}
              value="week"
              name="radio-buttons"
            />
            <span>Weekly</span>
          </div>
          <div>
            <Radio
              checked={selectedValue === "day"}
              onChange={handleChange}
              value="day"
              name="radio-buttons"
            />
            <span>Daily</span>
          </div>
        </div>
      </div>
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          {props.data && (
            <ResponsiveContainer>
              <PieChart width={400} height={400}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={props.data}
                  cx={220}
                  cy={120}
                  innerRadius={70}
                  outerRadius={90}
                  paddingAngle={2}
                  fill="#8884d8"
                  dataKey="productCounts"
                  onMouseEnter={onPieEnter}
                >
                  {props.data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
