import React, { useCallback, useState } from "react"
import {
  AutocompleteInput,
  Button,
  FormWithRedirect,
  NumberField,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  useCreate,
  useGetOne,
  useNotify,
  useRefresh,
} from "react-admin"
import { useFormState } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"

import {
  Box,
  Button as MuiButton,
  IconButton,
  Typography,
  Avatar,
} from "@material-ui/core"
import { blue } from "@material-ui/core/colors"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import AddIcon from "@material-ui/icons/Add"
import IconCancel from "@material-ui/icons/Cancel"
import RemoveIcon from "@material-ui/icons/Remove"

const PriceQuantityInput = (props: any) => {
  const { values } = useFormState()

  const { data, loading, error } = useGetOne(
    "products/products/variation",
    values.product
  )

  if (error) return null

  return (
    <>
      {data?.product && (
        <div>
          <strong>Mininum Order:</strong> {data.product.minOrder}
        </div>
      )}

      {/* No variation */}
      {data?.product && !data?.product?.hasVariation && (
        <>
          <NumberInput source="quantity" />
        </>
      )}
      {/* Variation */}
      {data?.product && data?.product?.hasVariation && (
        <>
          <FieldArray name="variations">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <>
                    {/* <ConfField data={data.product.combination[index]?.config} /> */}
                    <Box display="flex" width={544}>
                      <Box flex={1} mr="1em">
                        <SelectInput
                          source={`${name}.combination`}
                          choices={data.combination.map((comb) => ({
                            id: comb._id,
                            name: comb.config,
                          }))}
                          label="Combination"
                          fullWidth
                        />
                      </Box>
                      <Box display={1}>
                        <NumberInput
                          source={`${name}.quantity`}
                          label="Quantity"
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <IconButton
                          type="button"
                          onClick={() => fields.remove(index)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ))}
                <MuiButton
                  onClick={() =>
                    fields.push({
                      combination: "",
                      quantity: 0,
                    })
                  }
                >
                  <AddIcon /> Add Combination
                </MuiButton>
              </>
            )}
          </FieldArray>
        </>
      )}
    </>
  )
}

const ProductField = ({ record }: any) => {
  // console.log(record)
  return (
    <Box display="flex">
      <Box flex={1} mr="1em">
        <Avatar
          src={`${process.env.REACT_APP_AWS_BUCKET_URL}${record?.media[0]}`}
        />
      </Box>
      <Box flex={3}>
        <Typography variant="body1" gutterBottom={false}>
          <strong>
            {record?.title} (
            {record?.priceMin !== record?.priceMax
              ? `Rs. ${record?.priceMin}-${record?.priceMax}`
              : `Rs. ${record?.priceMin}`}
            )
          </strong>
        </Typography>
        <Typography variant="body2">{record?.vendor?.name}</Typography>
      </Box>
    </Box>
  )
}

function ItemAddButton({ orderId }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const [create] = useCreate("orders/items")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = useCallback(
    (values, redirect) => {
      create(
        {
          payload: { data: { ...values, orderId } },
        },
        {
          onSuccess: () => {
            setShowDialog(false)
            refresh()
            notify("Order updated")
          },
        }
      )
    },
    [create, notify]
  )

  // const optionRenderer = (choice) => {
  //   console.log(choice)
  //   return "Sudip"
  // }

  // const optionRenderer = useCallback((choice) => {
  //   return choice?.title
  // }, [])

  const matchSuggestion = (filterValue, choice) =>
    choice?.title?.match(filterValue)

  return (
    <>
      <Button
        onClick={handleClick}
        label="Add Item"
        style={{ color: blue[500] }}
      >
        <AddIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Add order item</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  source="product"
                  reference="products/products"
                  perPage={20}
                  fullWidth
                >
                  <AutocompleteInput
                    optionValue="id"
                    inputText={(choice) =>
                      `${choice?.title} - ${choice?.vendor?.name}`
                    }
                    optionText={<ProductField />}
                    matchSuggestion={matchSuggestion}
                  />
                </ReferenceInput>
                <PriceQuantityInput />
              </DialogContent>
              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleCloseClick}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default ItemAddButton
