import * as React from "react"
import { FC, useCallback } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  useNotify,
  useMutation,
  ReferenceInput,
  useRefresh,
  AutocompleteInput,
  EditProps,
  required,
} from "react-admin"

import { SectionTitle } from "../../common/form"

const styles = {
  id: { width: 544 },
  user: { width: 544 },
  agent: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const AgentTitle = ({ record }: any) => (
  <span>Edit Agent {record && `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 6

export const AgentEdit: FC<EditProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const [mutate] = useMutation()
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "agents/agents",
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        )
        notify("Product updated successfully.")
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )

  return (
    <Edit title={<AgentTitle />} {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.agentScheme.fieldGroups.title" />
        <ReferenceInput
          source="userId"
          reference="users/users"
          filter={{ role: "AGENT" }}
          filterToQuery={(val: string) =>
            condition(val) ? { title: val.trim() } : {}
          }
          perPage={1000}
          fullWidth
          formClassName={classes.user}
          validate={[required()]}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <SectionTitle label="resources.agentScheme.fieldGroups.agentScheme" />
        <ReferenceInput
          source="agentSchemeId"
          reference="agents/agentScheme"
          filterToQuery={(val: string) =>
            condition(val) ? { title: val.trim() } : {}
          }
          perPage={1000}
          fullWidth
          formClassName={classes.agent}
          validate={[required()]}
        >
          <AutocompleteInput optionText="title" optionValue="id" />
        </ReferenceInput>
        <BooleanInput
          label="Activated"
          source="isActivated"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
