import { useEffect, useState } from "react"
import { fetchEnd, fetchStart, useTranslate, useVersion } from "react-admin"
import { useDispatch } from "react-redux"

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"

import MiniCard from "./MiniCard"

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
  flexChild: { flex: "1 0 30%", margin: "5px" },
}

interface State {
  totalReceivable?: number
  totalReceivableFromVendor?: number
  totalReceivableFromDeliveryBoy?: number
}

const Spacer = () => <span style={{ width: "1em" }} />

const AccountingReceivable = () => {
  const translate = useTranslate()

  const dispatch = useDispatch()
  const version = useVersion()
  const [state, setState] = useState<State>({})

  const fetchStats = () => {
    dispatch(fetchStart())
    const request = new Request("api/admin/accounting/stats", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    fetch(request)
      .then((res) => {
        if (res.ok) return res.json()
        else console.error("Not fetching")
      })
      .then((data) => {
        const {
          totalReceivable,
          totalReceivableFromVendor,
          totalReceivableFromDeliveryBoy,
        } = data
        setState((state) => ({
          ...state,
          totalReceivable,
          totalReceivableFromVendor,
          totalReceivableFromDeliveryBoy,
        }))
      })
      .finally(() => {
        dispatch(fetchEnd())
      })
  }

  useEffect(() => {
    fetchStats()
  }, [version])

  console.log("State", state)

  return (
    <>
      <div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <MiniCard
                to={`/accounting/payable`}
                title={translate("pos.accountings.vendorReceivable")}
                icon={MonetizationOnIcon}
                value={`$${
                  state?.totalReceivableFromVendor
                    ? state?.totalReceivableFromVendor.toFixed(2)
                    : `0.00`
                }`}
              />
              <Spacer />
              <MiniCard
                to={`/accounting/receivable`}
                title={translate("pos.accountings.deliveryBoyReceivable")}
                icon={MonetizationOnIcon}
                value={`$${
                  state?.totalReceivableFromDeliveryBoy
                    ? state?.totalReceivableFromDeliveryBoy.toFixed(2)
                    : `0.00`
                }`}
              />
              {/* <Spacer />
              <MiniCard
                to={`orders/orders?filter={"orderStatus":"pending"}`}
                title={translate("pos.dashboard.shippings")}
                icon={MonetizationOnIcon}
                value={`$${0}`}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountingReceivable
