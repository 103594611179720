import { Chip } from "@material-ui/core"
import {
  amber,
  blue,
  cyan,
  deepOrange,
  green,
  indigo,
  orange,
  purple,
  red,
  yellow,
} from "@material-ui/core/colors"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import DoneIcon from "@material-ui/icons/Done"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import ListIcon from "@material-ui/icons/List"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import MoodBadIcon from "@material-ui/icons/MoodBad"
import UndoIcon from "@material-ui/icons/Undo"
import TouchAppIcon from "@material-ui/icons/TouchApp"

export const paymentStatus = [
  {
    id: "",
    name: "All",
    color: orange[100],
    icon: <TouchAppIcon />,
  },
  {
    id: false,
    name: "Unpaid",
    color: red[100],
    icon: <CancelIcon style={{ color: red[500] }} />,
  },
  {
    id: true,
    name: "Paid",
    color: green[100],
    icon: <CheckCircleIcon style={{ color: green[500] }} />,
  },
]

export const saleType = [
  {
    id: "sale",
    name: "Sale",
    color: green[100],
    icon: <LocalShippingIcon style={{ color: green[500] }} />,
  },
  {
    id: "sale_return",
    name: "Sale Return",
    color: red[100],
    icon: <UndoIcon style={{ color: red[500] }} />,
  },
  // { id: "purchase", name: "Purchase",  },
  // { id: "purchase_return", name: "Purchase Return" },
]

export const paymentRequest = [
  {
    id: "",
    name: "All",
    color: orange[100],
    icon: <TouchAppIcon />,
  },
  {
    id: true,
    name: "Yes",
    color: green[100],
    icon: <CheckCircleIcon style={{ color: green[500] }} />,
  },
  {
    id: false,
    name: "No",
    color: red[100],
    icon: <CancelIcon style={{ color: red[500] }} />,
  },
]

export const optionRenderer = (choice: {
  name: string
  color: string
  icon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
}) => (
  <Chip
    label={choice.name}
    style={{ backgroundColor: choice.color }}
    icon={choice.icon}
  />
)
