import {
  BooleanField,
  Datagrid,
  FunctionField,
  Link,
  List,
  ListProps,
} from "react-admin"
import ShowIcon from "@material-ui/icons/RemoveRedEye"

import { IDField } from "../../common/form"

const PackagingBoxList = (props: ListProps) => {
  return (
    <List {...props}>
      <Datagrid>
        <IDField source="id" />
        <FunctionField
          label="Order"
          render={(record) => {
            return (
              <Link to={`/orders/orders/${record?.orderId}`}>
                {record?.trackingNumber}
              </Link>
            )
          }}
        />
        <BooleanField source="isCompleted" />
        <FunctionField
          label="Remaining Items"
          render={(record) => {
            return record?.remainingItems?.length
          }}
        />
        <FunctionField
          label="Box Count"
          render={(record) => {
            return record?.boxes?.length
          }}
        />
        <FunctionField
          label=""
          render={(record) => {
            return (
              <Link to={`/oms/scan/${record?.trackingNumber}`}>
                <ShowIcon />
              </Link>
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

export { PackagingBoxList }
