import NotesIcon from "@material-ui/icons/Notes"
import { AgentSchemeCreate } from "./AgentSchemeCreate"
import { AgentSchemeEdit } from "./AgentSchemeEdit"
import { AgentSchemeList } from "./AgentSchemeList"

export default {
  create: AgentSchemeCreate,
  list: AgentSchemeList,
  edit: AgentSchemeEdit,
  icon: NotesIcon,
}
