import moment from "moment"
import { FC, useEffect, useState } from "react"
import {
  AutocompleteInput,
  BooleanField,
  Button,
  Confirm,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  fetchEnd,
  fetchStart,
  Filter,
  FilterProps,
  FormWithRedirect,
  FunctionField,
  ImageField,
  ImageInput,
  Link,
  List,
  ListActionsProps,
  NumberField,
  Pagination,
  ReferenceInput,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  TextInput,
  TopToolbar,
  useCreate,
  useListContext,
  useNotify,
  useRefresh,
  useTranslate,
  useUpdateMany,
  useVersion,
} from "react-admin"
import { useDispatch } from "react-redux"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button as MuiButton,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCancel from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import GetAppIcon from "@material-ui/icons/GetApp"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { IDField } from "../common/form"
import MakePaymentButton from "../resources/sales/MakePaymentButton"
import MiniCard from "./MiniCard"
import { ExpandMoreSharp } from "@material-ui/icons"

const LinkField = ({ source, field, reference, value, label }: any) => (
  <FunctionField
    source={source}
    label={label}
    render={(record) =>
      record &&
      record[field] && (
        <Link to={`/${reference}/${record[field].id}`}>
          {record[field][value]}
        </Link>
      )
    }
  />
)

const ListActions = (props: ListActionsProps) => {
  const { className, filterValues, ...rest } = props

  const { total } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        label="PDF"
        href={`${
          process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
        }/api/admin/sales/sales/pdf?filter=${JSON.stringify(filterValues)}`}
        disabled={total === 0}
      >
        <GetAppIcon />
      </Button>

      <Button
        label="CSV"
        href={`${
          process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
        }/api/admin/sales/sales/csv?filter=${JSON.stringify(filterValues)}`}
        disabled={total === 0}
      >
        <GetAppIcon />
      </Button>
    </TopToolbar>
  )
}

const SaleFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendorId"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      alwaysOn
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>
    <DateInput source="dateGte" label="Date from" alwaysOn />
    <DateInput source="dateLt" label="Date to" alwaysOn />
  </Filter>
)

const SalePagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
)

const SalePaymentUpdateActionButtons = (props) => (
  <MakePaymentButton lable="Make payment" {...props} />
)

interface State {
  vendorPayable?: any
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
  flexChild: { flex: "1 0 30%", margin: "5px" },
}

const Spacer = () => <span style={{ width: "1em" }} />

const AccountingPayble = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const version = useVersion()
  const refresh = useRefresh()
  const notify = useNotify()

  const [open, setOpen] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [data, setData] = useState<any>({})

  const [state, setState] = useState<State>({})

  const fetchStats = () => {
    dispatch(fetchStart())
    const request = new Request("api/admin/accounting/payment", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    fetch(request)
      .then((res) => {
        if (res.ok) return res.json()
        else console.error("Not fetching")
      })
      .then((data) => {
        const { vendorPayable } = data
        setState((state) => ({
          ...state,
          vendorPayable,
        }))
      })
      .finally(() => {
        dispatch(fetchEnd())
      })
  }

  useEffect(() => {
    fetchStats()
  }, [version])

  const { vendorPayable } = state

  const handleClick = (data: any) => {
    setShowDialog(true)
    setData(data)
  }

  const handleConfirmClick = () => setOpen(true)

  const handleDialogClose = () => setShowDialog(false)
  const handleConfirmClose = () => setOpen(false)

  // const [updateMany, { loading }] = useUpdateMany(
  //   "sales/sales",
  //   data?.sales?.map((sale) => sale._id),
  //   { paymentStatus: { status: true } },
  //   {
  //     onSuccess: () => {
  //       refresh()
  //       notify("Sales updated")
  //     },
  //     onFailure: (error) => notify("Error: sales not updated", "warning"),
  //   }
  // )

  const [create, { loading }] = useCreate("vendor-payments")

  const handleConfirm = async (values) => {
    const orderItemIds = data.sales.map((sale) => sale.orderItemId)
    const saleIds = data.sales.map((sale) => sale._id)
    const vendorId = data.vendor.id
    const amount = data.total.toFixed(2)

    create(
      "vendor-payments",
      {
        orderItemIds,
        vendorId,
        amount,
        saleIds,
        ...values,
      },
      {
        onSuccess: () => {
          refresh()
          notify("Payment created")
          setShowDialog(false)
        },
        onFailure: (error) => notify("Error: payment not created", "warning"),
      }
    )
  }

  return (
    <div>
      <br />
      <div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <MiniCard
                to={`/accounting/payable`}
                title={translate("pos.accountings.totalPayable")}
                icon={MonetizationOnIcon}
                value={`$${
                  vendorPayable ? vendorPayable.payable.toFixed(2) : `0.00`
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      <br />

      <div style={{ ...styles.flex, flexWrap: "wrap" }}>
        {vendorPayable ? (
          vendorPayable.data &&
          vendorPayable.data.map((data: any, index) => (
            <Card key={index} style={{ ...styles.flexChild }}>
              <CardContent>
                <Typography variant="h6">{data.vendor.name}</Typography>
                <Typography variant="subtitle1">
                  <span style={{ padding: "2px", backgroundColor: red[200] }}>
                    रु{" "}
                    {data.total.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </Typography>
              </CardContent>
              <CardActions disableSpacing={true}>
                <MuiButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleClick(data)
                  }}
                >
                  Make a Payment
                </MuiButton>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="h6">There are no payable vendors.</Typography>
        )}
      </div>

      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleDialogClose}
        aria-lable="Make Payment"
        maxWidth="md"
      >
        <DialogTitle>
          Make Payment for{" "}
          <span style={{ backgroundColor: green[200] }}>
            {data?.vendor?.name}
          </span>
        </DialogTitle>
        <DialogContent>
          <Accordion style={{ marginBottom: "0.5em" }}>
            <AccordionSummary expandIcon={<ExpandMoreSharp />}>
              <Typography variant="body1"> Sales </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {data?.sales?.map((sale: any) => (
                  <div
                    key={sale.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                      // flexDirection: "column",
                    }}
                  >
                    <Typography variant="subtitle2">
                      ( {moment(sale.createdAt).format("DD/MM/YYYY")})
                    </Typography>
                    <Typography variant="subtitle1" style={{ flex: 4 }}>
                      {sale.product.title}
                    </Typography>
                    <Typography variant="subtitle2" style={{ flex: 1 }}>
                      रु{" "}
                      {sale.vendorPayable.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogContent>
          <Typography variant="subtitle2" align="right">
            Total: रु{" "}
            {data?.total?.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
          <Typography variant="h6">Payment Details</Typography>
          <div style={{ gap: "0.5em" }}>
            <FormWithRedirect
              save={handleConfirm}
              redirectTo={`/accounting/payable`}
              render={({ handleSubmitWithRedirect, pristine, saving }) => (
                <>
                  <div
                    style={{
                      flex: 4,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", gap: "0.5em" }}>
                      <SelectInput
                        source="paymentMethod"
                        choices={[
                          { id: "cash", name: "Cash" },
                          { id: "cheque", name: "Cheque" },
                          { id: "connectIps", name: "Connect Ips" },
                          { id: "esewa", name: "Esewa" },
                          { id: "bankTranfer", name: "Bank Transfer" },
                          { id: "other", name: "Other" },
                        ]}
                        fullWidth
                      />
                      <DateTimeInput source="paymentDate" fullWidth />
                    </div>
                    <div style={{ display: "flex", gap: "0.5em" }}>
                      <TextInput source="bankAccountNumber" fullWidth />
                      <TextInput source="bankAccountName" fullWidth />
                    </div>
                    <div style={{ display: "flex", gap: "0.5em" }}>
                      <TextInput source="paymentReference" fullWidth />
                      <TextInput source="paymentNote" fullWidth />
                    </div>
                    <TextInput source="otherDetails" multiline />
                  </div>
                  <div style={{ flex: 3 }}>
                    <ImageInput
                      source="paymentImage"
                      label="Payment Image"
                      accept="image/*"
                      placeholder={<p>Drop your image here</p>}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </div>
                  <DialogActions>
                    {/* <Button
                      label="Make payment"
                      onClick={handleConfirmClick}
                      style={{ color: green[500] }}
                    >
                      <CheckCircleIcon />
                    </Button> */}
                    <MuiButton onClick={handleDialogClose} color="primary">
                      <IconCancel /> Close
                    </MuiButton>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      // @ts-ignore
                      pristine={pristine}
                      saving={saving}
                      disabled={loading}
                    />
                  </DialogActions>
                </>
              )}
            />
          </div>
        </DialogContent>
      </Dialog>
      {/* <Confirm
        isOpen={open}
        loading={loading}
        title="Update Vendor Payment"
        content="Are you sure you want to make the vendor payment?"
        onConfirm={handleConfirm}
        onClose={handleConfirmClose}
      /> */}
    </div>
  )
}

export default AccountingPayble
