import * as React from "react"
import { Fragment, useState } from "react"
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { green } from "@material-ui/core/colors"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const MakeProductInStockButton = ({ selectedIds }) => {
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const [updateMany, { loading }] = useUpdateMany(
    "products/products",
    selectedIds,
    { outOfStock: false },
    {
      onSuccess: () => {
        refresh()
        notify("Product updated")
        unselectAll("products/products")
      },
      onFailure: (error) => notify("Error: products not updated", "warning"),
    }
  )
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    updateMany()
    setOpen(false)
  }

  return (
    <Fragment>
      <Button
        label="Make in stock"
        onClick={handleClick}
        style={{ color: green[500] }}
      >
        <CheckCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Update Product In Stock status"
        content="Are you sure you want to make the product in stock?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

export default MakeProductInStockButton
