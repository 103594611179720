import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { SpecialDiscountRequestCreate } from "./SpecialDiscountRequestCreate"
import { SpecialDiscountRequestEdit } from "./SpecialDiscountRequestEdit"
import { SpecialDiscountRequestList } from "./SpecialDiscountRequestList"

export default {
  icon: AttachMoneyIcon,
  list: SpecialDiscountRequestList,
  edit: SpecialDiscountRequestEdit,
  create: SpecialDiscountRequestCreate,
}
