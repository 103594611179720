import * as React from "react"
import { FC, useState } from "react"
import {
  BooleanInput,
  BulkDeleteButton,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
} from "react-admin"

import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"
import { IDField } from "../../common/form"
import ListActions from "../../common/listActions"
import RecoverButton from "../../common/RecoverButton"

const TagFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const TagActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <>
          <DeletePermanentlyButton
            label="Delete tag permanently"
            resourceLabel="Tag"
            {...props}
          />
          <RecoverButton {...props} />
        </>
      )}
    </>
  )
}

export const TagList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<TagFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<TagActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="title" />
        <DateField source="updatedAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
