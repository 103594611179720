import * as React from "react"
import { Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined"
import { FilterList, FilterListItem } from "react-admin"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import {
  optionRenderer,
  orderStatus,
  paymentStatus,
  paymentToAdmin,
  paymentTypes,
} from "./common"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "20em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const OrderAside = ({ totals }: any) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <FilterList
          label="resources.orders.filters.orderStatus"
          icon={<LocalOfferIcon />}
        >
          {orderStatus.map((status) => (
            <FilterListItem
              // label={`${status.name} (${totals[status.id]})`}
              label={optionRenderer({
                ...status,
                name: `${status.name} (${
                  totals[status.id] ? totals[status.id] : `0`
                })`,
              })}
              key={status.id}
              value={{ orderStatus: status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.orders.filters.paymentStatus"
          icon={<AttachMoneyIcon />}
        >
          {paymentStatus.map((status) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={status.id}
              value={{ paymentStatus: status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.orders.filters.paymentType"
          icon={<LocalOfferIcon />}
        >
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              label={paymentType.name}
              key={paymentType.id}
              value={{ paymentType: paymentType.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.orders.filters.paymentToAdmin"
          icon={<LocalOfferIcon />}
        >
          {paymentToAdmin.map((status) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={status.name}
              value={{ "paymentToAdmin.status": status.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  )
}

export default OrderAside
