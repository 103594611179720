import PaymentIcon from "@material-ui/icons/Payment"

import { VendorPaymentEdit } from "./VendorPaymentEdit"
import { VendorPaymentList } from "./VendorPaymentList"

export default {
  list: VendorPaymentList,
  edit: VendorPaymentEdit,
  icon: PaymentIcon,
}
