import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"
import { BannerCMSCreate } from "./BannerCMSCreate"
import { BannerCMSEdit } from "./BannerCMSEdit"
import { BannerCMSList } from "./BannerCMSList"

export default {
  list: BannerCMSList,
  create: BannerCMSCreate,
  edit: BannerCMSEdit,
  icon: ViewCarouselIcon,
}
