import { Create, CreateProps, SimpleForm, TextInput } from "react-admin"

export const URLShortnerCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="longUrl" />
      </SimpleForm>
    </Create>
  )
}
