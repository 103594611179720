import * as React from "react"
import { useState } from "react"
import { AutocompleteArrayInput, useCreateSuggestionContext } from "react-admin"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
} from "@material-ui/core"

const SeoKeywordInput = (props: any) => {
  const { record } = props

  const [choices, setChoices]: any = useState(
    record?.seo?.meta_keywords
      ? [
          ...record.seo.meta_keywords.map((keyword: string) => ({
            id: keyword,
            name: keyword,
          })),
        ]
      : []
  )

  return (
    <AutocompleteArrayInput
      create={<CreateKeyword choices={choices} setChoices={setChoices} />}
      source="seo.meta_keywords"
      label="Meta keywords"
      choices={choices}
    />
  )
}

const CreateKeyword = ({ choices, setChoices }: any) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [value, setValue] = React.useState(filter || "")
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setChoices([...choices, { id: value, name: value }])
    onCreate({ id: value, name: value })
    setValue("")
    return false
  }
  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <MuiTextField
            label="New tag"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SeoKeywordInput
