import * as React from "react"
import { FC } from "react"
import {
  Card,
  CardContent,
  Typography,
  List as MuiList,
  ListItem,
} from "@material-ui/core"
import { green } from "@material-ui/core/colors"

const styles = {
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
}

interface StatsBoxDataProps {
  Icon?: any
  color?: any
  title?: string
  value?: number | string
  link?: string
}

interface StatsBoxProps {
  data?: StatsBoxDataProps[]
  maxWidth?: number
  title?: string
}

const StatsBox: FC<StatsBoxProps> = (props) => {
  const { data } = props
  return (
    <Card style={{ maxWidth: props.maxWidth ?? "" }}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {props.title ?? "Store Stats"}
        </Typography>
        <MuiList>
          {data &&
            data.map((content, index) => (
              <ListItem
                key={index}
                component="a"
                href={content.link}
                style={{ color: "black" }}
              >
                {content.Icon && (
                  <content.Icon htmlColor={content.color ?? green[500]} />
                )}
                <Typography style={styles.statsNumber}>
                  {content.value}
                </Typography>
                <Typography>{content.title}</Typography>
              </ListItem>
            ))}
        </MuiList>
      </CardContent>
    </Card>
  )
}

export default StatsBox
