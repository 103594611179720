import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  parameter: { display: "inline-block" },
  minParameter: { display: "inline-block", marginLeft: 32 },
  maxParameter: { display: "block" },
  delivery: { display: "inline-block" },
  minDelivery: { display: "inline-block", marginLeft: 32 },
  maxDelivery: { display: "block" },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const ShippingTitle = ({ record }: any) => (
  <span>Edit shippings {`#${record.id}`}</span>
)

export const ShippingEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  const condition = (val: string) => !!val && val.trim().length > 5

  return (
    <Edit title={<ShippingTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.shippings.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <ReferenceInput
          label="Method"
          source="method"
          reference="shippings/methods"
          validate={requiredValidate}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>

        <SectionTitle label="resources.shippings.fieldGroups.parameter" />
        <SelectInput
          label="Parameter"
          source="parameter"
          choices={[
            { id: "cost", name: "Cost" },
            { id: "weight", name: "Weight" },
            { id: "sensitivity", name: "Sensitivity" },
            { id: "package", name: "Package" },
          ]}
          formClassName={classes.parameter}
          validate={requiredValidate}
        />
        <NumberInput
          label="Min"
          source="parameterMin"
          formClassName={classes.minParameter}
          validate={requiredValidate}
        />
        <NumberInput
          label="Max"
          source="parameterMax"
          formClassName={classes.maxParameter}
          validate={requiredValidate}
        />

        <SectionTitle label="resources.shippings.fieldGroups.delivery" />
        <SelectInput
          label="Delivery Time Type"
          source="deliveryTimeType"
          choices={[
            { id: "minutes", name: "Minutes" },
            { id: "hours", name: "Hours" },
            { id: "days", name: "Days" },
            { id: "weeks", name: "Weeks" },
            { id: "months", name: "Months" },
            { id: "years", name: "Years" },
          ]}
          formClassName={classes.delivery}
          validate={requiredValidate}
        />
        <NumberInput
          label="Min"
          source="minDeliveryTime"
          formClassName={classes.minDelivery}
          validate={requiredValidate}
        />
        <NumberInput
          label="Max"
          source="maxDeliveryTime"
          formClassName={classes.maxDelivery}
          validate={requiredValidate}
        />

        <SectionTitle label="resources.shippings.fieldGroups.cost" />
        <ReferenceArrayInput
          label="Clusters"
          source="clusters"
          reference="shippings/area-clusters"
          sort={{ field: "name", order: "ASC" }}
          perPage={100}
          filterToQuery={(val: string) =>
            condition(val) ? { name: val.trim() } : {}
          }
          validate={requiredValidate}
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>

        <NumberInput label="Cost" source="cost" validate={requiredValidate} />

        <SectionTitle label="resources.shippings.fieldGroups.options" />
        <BooleanInput
          label="Cash on Delivery"
          source="cod"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
          validate={requiredValidate}
        />
      </SimpleForm>
    </Edit>
  )
}
