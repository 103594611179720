import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
  SelectInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  RadioButtonGroupInput,
} from "react-admin"
import { useFormState } from "react-final-form"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  image: { width: 544 },
  message: { width: 544 },
  users: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const UserInput = (props: any) => {
  const { values } = useFormState()
  return !values?.meta?.isPromotional ? (
    <ReferenceArrayInput
      source="users"
      label="Send to"
      reference="users/users"
      {...props}
    >
      <AutocompleteArrayInput optionText="name" optionValue="id" />
    </ReferenceArrayInput>
  ) : null
}

export const NotificationCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "notifications/notifications",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Notification saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )

  const choices = [
    { id: "info", name: "Info" },
    { id: "warning", name: "Warning" },
    { id: "error", name: "Error" },
    { id: "success", name: "Success" },
    { id: "promotional", name: "Promotional" },
    { id: "done", name: "Done" },
    { id: "pending", name: "New order" },
    { id: "confirmed", name: "Confirmed order" },
    { id: "approved", name: "Approved order" },
    { id: "picked", name: "Picked order" },
    { id: "delivering", name: "Delivering order" },
    { id: "delivered", name: "Delivered order" },
    { id: "cancelled", name: "Cancelled" },
    { id: "rejected", name: "Rejected" },
    { id: "returned", name: "Returned" },
    { id: "defective", name: "Defective" },
  ]

  return (
    <Create {...props}>
      <SimpleForm
        save={save}
        initialValues={() => ({ "meta.isPromotional": true })}
      >
        <SectionTitle label="resources.notifications.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="message"
          fullWidth
          formClassName={classes.message}
          multiline
        />
        <SelectInput source="type" choices={choices} />

        <RadioButtonGroupInput
          label="Send to"
          source="meta.isPromotional"
          choices={[
            { id: true, name: "All Users" },
            { id: false, name: "Choose Users" },
          ]}
        />
        <UserInput fullWidth formClassName={classes.users} />
        <SectionTitle label="resources.notifications.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Notification image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}
