import MenuIcon from "@material-ui/icons/Menu"
import { MenuCMSCreate } from "./MenuCMSCreate"
import { MenuCMSEdit } from "./MenuCMSEdit"
import { MenuCMSList } from "./MenuCMSList"

export default {
  list: MenuCMSList,
  create: MenuCMSCreate,
  edit: MenuCMSEdit,
  icon: MenuIcon,
}
