import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  name: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const PermissionTitle = ({ record }: any) => (
  <span>Edit Permission {record ? `"${record.name}"` : `#${record.id}`}</span>
)

export const PermissionEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<PermissionTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.permissions.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <SectionTitle label="resources.permissions.fieldGroups.options" />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
