import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanInput,
  FilterProps,
  ListProps,
  BulkDeleteButton,
} from "react-admin"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"

const URLShortnerFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const URLShortnerActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <DeletePermanentlyButton
          label="Delete tag permanently"
          resourceLabel="URLShortner"
          {...props}
        />
      )}
    </>
  )
}

export const URLShortnerList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<URLShortnerFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={
        <URLShortnerActionButtons deletefilter={deletefilter} />
      }
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="urlCode" />
        <TextField source="longUrl" />
        <TextField source="shortUrl" />
        <DateField source="createdAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
