import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const DistrictTitle = ({ record }: any) => (
  <span>Edit District {record ? `"${record.name}"` : `#${record.id}`}</span>
)

export const DistrictEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<DistrictTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.districts.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <ReferenceInput
          label="State"
          source="state"
          reference="shippings/states"
          validate={requiredValidate}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <SectionTitle label="resources.districts.fieldGroups.options" />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
