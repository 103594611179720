import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  ReferenceInput,
  SelectInput,
  CreateProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

export const StateCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "shippings/states",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`State saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.states.fieldGroups.information" />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <ReferenceInput
          label="Country"
          source="country"
          reference="shippings/countries"
          validate={requiredValidate}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <SectionTitle label="resources.states.fieldGroups.options" />

        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
          defaultValue={false}
        />
      </SimpleForm>
    </Create>
  )
}
