import React, { useState, useEffect, useCallback, CSSProperties } from "react"
import { useDispatch } from "react-redux"
import { subDays } from "date-fns"
import {
  useVersion,
  fetchStart,
  fetchEnd,
  useNotify,
  useTranslate,
  useDataProvider,
  useGetList,
  List,
  Datagrid,
  TextField,
  Link,
} from "react-admin"
import {
  useMediaQuery,
  Theme,
  Box,
  Typography,
  List as MuiList,
  ListItem,
  Card,
  CardContent,
} from "@material-ui/core"
import PeopleIcon from "@material-ui/icons/People"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"

import Welcome from "./Welcome"
import MiniCard from "./MiniCard"
import SaleChart from "./SaleChart"
import { blue, green, orange, pink, red } from "@material-ui/core/colors"
import StatsBox from "./StatsBox"
import People from "@material-ui/icons/People"
import { MonetizationOn, Money } from "@material-ui/icons"
import TopSellerPieChart from "./TopSellerPieChart"
import OrderStatusSteppers from "./OrderStepsChart"
import FeesCollectionsBarChart from "./FeesCollectionsBarChart"
import { IDField } from "../common/form"

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
}

const Empty = () => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h5" paragraph>
        You don't have products to be reviewed.
      </Typography>
      <Typography variant="body2">
        You can see product list here that are waiting for review.
      </Typography>
    </Box>
  )
}

const ProductReviewAction = () => {
  return <Typography variant="h6">Products to be reviewed</Typography>
}

const MyImageField = ({ record }: any) => {
  return (
    <div>
      <img
        src={
          record.media && record.media.length > 0
            ? `${process.env.REACT_APP_AWS_BUCKET_URL}${record.media[0]}`
            : ""
        }
        height={75}
        width="auto"
      />
    </div>
  )
}

const Spacer = () => <span style={{ width: "1em" }} />
const VerticalSpacer = () => <span style={{ height: "1em" }} />

interface State {
  users?: number
  totalDeliveryBoy?: number
  totalAgents?: number
  newOrders?: number
  vendors?: number
  totalProducts?: number
  totalMargin?: number
  totalSalesAmount?: number
  vendorPayable?: number
  totalReceivableFromDeliveryBoy?: number
  recentSales?: any
  totalSales?: number
  totalPaymentRequest?: number
  totalNewVendorRequest?: number
  totalNewAgentRequest?: number
  totalAgentPaymentRequest?: number
  totalSpecialDiscountRequest?: number
  topSellers?: any
  feesByPaymentType?: any
  totalOrderAmount?: number | string
}

const useGetOrderTotals = () => {
  const { total: totalPending } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "pending" }
  )
  const { total: totalApprove } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "approve" }
  )
  const { total: totalConfirmed } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "confirmed" }
  )
  const { total: totalPickup } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "pickup" }
  )
  const { total: totalDelivering } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "delivering" }
  )
  const { total: totalDelivered } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "delivered" }
  )
  const { total: totalCancelled } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "cancelled" }
  )
  const { total: totalDefect } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "defect" }
  )
  const { total: totalRefund } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { orderStatus: "cancelled" }
  )

  return {
    pending: totalPending,
    confirmed: totalConfirmed,
    approve: totalApprove,
    pickup: totalPickup,
    delivering: totalDelivering,
    delivered: totalDelivered,
    cancelled: totalCancelled,
    defect: totalDefect,
    refund: totalRefund,
  }
}

const Dashboard = ({ permissions }) => {
  // Date Filters
  const aMonthAgo = subDays(new Date(), 30)
  const aWeekAgo = subDays(new Date(), 7)
  const aDayAgo = subDays(new Date(), 1)
  const [state, setState] = useState<State>({})
  const [topSalerDateFilter, setTopSalerDateFilter] = useState(
    aMonthAgo.toISOString()
  )
  const [feesByPaymentTypeFilter, setFeesByPaymentTypeFilter] = useState(
    aMonthAgo.toISOString()
  )

  const dataProvider = useDataProvider()
  const version = useVersion()
  const dispatch = useDispatch()
  const notify = useNotify()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const orderTotals = useGetOrderTotals() as any

  const fetchTopSalerStats = useCallback(
    async (date) => {
      const { data: topSellers } = await dataProvider.getList(
        "dashboard/stats/topSellers",
        {
          filter: { dateGte: date },
          sort: { field: "productsCount", order: "DESC" },
          pagination: { page: 1, perPage: 5 },
        }
      )
      setState((state) => ({
        ...state,
        topSellers,
      }))
    },
    [useDataProvider]
  )

  const fetchOrderStats = useCallback(async () => {
    const { data: orderStats } = await dataProvider.getOne(
      "orders/order-stats",
      { id: "" }
    )
    const { totalOrderAmount } = orderStats
    console.log("orderStats", orderStats)
    setState((state) => ({
      ...state,
      totalOrderAmount,
    }))
  }, [useDataProvider])

  const fetchFeesByPaymentType = useCallback(
    async (date) => {
      const { data: feesByPaymentType } = await dataProvider.getList(
        "dashboard/stats/fees-by-payment-type",
        {
          filter: { dateGte: date },
          sort: { field: "productsCount", order: "DESC" },
          pagination: { page: 1, perPage: 5 },
        }
      )
      setState((state) => ({
        ...state,
        feesByPaymentType,
      }))
    },
    [useDataProvider]
  )

  const fetchRecentSales = useCallback(async () => {
    const { data: recentSales, total: totalSales } = await dataProvider.getList(
      "sales/sales",
      {
        filter: { dateGte: aMonthAgo.toISOString() },
        sort: { field: "createdAt", order: "DESC" },
        pagination: { page: 1, perPage: 1000 },
      }
    )
    setState((state) => ({
      ...state,
      recentSales,
      totalSales,
    }))
  }, [useDataProvider])

  const fetchTotalUsers = useCallback(async () => {
    const { total: users } = await dataProvider.getList("users/users", {
      filter: { isActivated: true },
      sort: { field: "updatedAt", order: "ASC" },
      pagination: { perPage: 2, page: 1 },
    })
    setState((state) => ({ ...state, users }))
  }, [useDataProvider])

  const fetchTotalNewOrders = useCallback(async () => {
    const { total: newOrders } = await dataProvider.getList("orders/orders", {
      filter: { orderStatus: "pending" },
      sort: { field: "updatedAt", order: "ASC" },
      pagination: { perPage: 2, page: 1 },
    })
    setState((state) => ({ ...state, newOrders }))
  }, [useDataProvider])

  const fetchDeliveryBoys = useCallback(async () => {
    const { total: totalDeliveryBoy } = await dataProvider.getList(
      "users/users",
      {
        filter: { role: "DELIVERYBOY" },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    setState((state) => ({ ...state, totalDeliveryBoy }))
  }, [useDataProvider])

  const fetchAgents = useCallback(async () => {
    const { total: totalAgents } = await dataProvider.getList("users/users", {
      filter: { role: "AGENT" },
      sort: { field: "updatedAt", order: "ASC" },
      pagination: { perPage: 2, page: 1 },
    })
    setState((state) => ({ ...state, totalAgents }))
  }, [useDataProvider])

  const fetchProducts = useCallback(async () => {
    const { total: totalProducts } = await dataProvider.getList(
      "products/products",
      {
        filter: { "meta.isDeleted": false },
        sort: { field: "updatedAt", order: "DESC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    setState((state) => ({ ...state, totalProducts }))
  }, [useDataProvider])

  const fetchVendors = useCallback(async () => {
    const { total: vendors } = await dataProvider.getList("users/users", {
      filter: { role: "VENDOR" },
      sort: { field: "updatedAt", order: "ASC" },
      pagination: { perPage: 2, page: 1 },
    })
    setState((state) => ({ ...state, vendors }))
  }, [useDataProvider])

  const fetchStats = useCallback(async () => {
    const { total: totalPaymentRequest } = await dataProvider.getList(
      "sales/sales",
      {
        filter: {
          "paymentRequest.status": true,
          "paymentStatus.status": false,
        },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    const { total: totalNewVendorRequest } = await dataProvider.getList(
      "users/users",
      {
        filter: { role: "VENDOR", isActivated: false },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    const { total: totalNewAgentRequest } = await dataProvider.getList(
      "users/users",
      {
        filter: { role: "AGENT", isActivated: false },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )

    setState((state) => ({
      ...state,
      totalPaymentRequest,
      totalNewVendorRequest,
      totalNewAgentRequest,
    }))
  }, [useDataProvider])

  const fetchTotalSpecialDiscountRequest = useCallback(async () => {
    const { total: totalSpecialDiscountRequest } = await dataProvider.getList(
      "users/special-discount-requests",
      {
        filter: { status: "pending" },
        sort: { field: "updatedAt", order: "ASC" },
        pagination: { perPage: 2, page: 1 },
      }
    )
    setState((state) => ({
      ...state,
      totalSpecialDiscountRequest,
    }))
  }, [useDataProvider])

  useEffect(() => {
    if (permissions?.roles.includes("ADMIN")) {
      fetchStats()
      fetchRecentSales()
      fetchTotalUsers()
      fetchTotalNewOrders()
      fetchDeliveryBoys()
      fetchAgents()
      fetchProducts()
      fetchVendors()
      fetchTotalSpecialDiscountRequest()
    }
  }, [version, permissions?.roles.includes("ADMIN")])

  useEffect(() => {
    fetchTopSalerStats(topSalerDateFilter)
  }, [version, topSalerDateFilter])

  useEffect(() => {
    fetchOrderStats()
  }, [version])

  useEffect(() => {
    fetchFeesByPaymentType(feesByPaymentTypeFilter)
  }, [version, feesByPaymentTypeFilter])

  const fetchPayment = () => {
    dispatch(fetchStart())
    const request = new Request("api/admin/dashboard/stats", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    fetch(request)
      .then((res) => {
        if (res.ok) return res.json()
        else console.error("Not fetching")
      })
      .then((data) => {
        const {
          totalMargin,
          totalSalesAmount,
          vendorPayable,
          totalReceivableFromDeliveryBoy,
          totalAgentPaymentRequest,
        } = data
        setState((state) => ({
          ...state,
          totalMargin,
          totalSalesAmount,
          vendorPayable,
          totalReceivableFromDeliveryBoy,
          totalAgentPaymentRequest,
        }))
      })
      .finally(() => {
        dispatch(fetchEnd())
      })
  }

  useEffect(() => {
    fetchPayment()
  }, [version])

  const {
    users,
    totalDeliveryBoy,
    totalAgents,
    newOrders,
    vendors,
    totalProducts,
    totalMargin,
    totalSalesAmount,
    vendorPayable,
    totalReceivableFromDeliveryBoy,
    recentSales,
    totalSales,
    totalPaymentRequest,
    totalNewVendorRequest,
    totalNewAgentRequest,
    totalAgentPaymentRequest,
    topSellers,
    feesByPaymentType,
    totalSpecialDiscountRequest,
    totalOrderAmount,
  } = state

  const translate = useTranslate()

  return permissions?.roles.includes("ADMIN") ? (
    <div>
      <VerticalSpacer />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MiniCard
              to={`orders/orders?filter={"orderStatus":"pending"}`}
              title={translate("pos.dashboard.newOrders")}
              icon={ShoppingCartIcon}
              value={`${newOrders ? newOrders : 0}`}
              value2={`${totalOrderAmount ? `$${totalOrderAmount}` : 0}`}
            />
            <Spacer />
            <MiniCard
              to={`sales/sales`}
              title={translate("pos.dashboard.totalSales")}
              icon={MonetizationOnIcon}
              value={`$ ${totalSalesAmount ? totalSalesAmount.toFixed(2) : 0}`}
            />
            <Spacer />
            <MiniCard
              to={`sales/sales`}
              title={translate("pos.dashboard.vendorPayable")}
              icon={MonetizationOnIcon}
              value={`$ ${vendorPayable ? vendorPayable.toFixed(2) : 0}`}
            />
            <Spacer />
            <MiniCard
              to={`orders/orders?filter={"orderStatus":"delivered","paymentType":"cod"}`}
              title={translate("pos.dashboard.deliveryBoyReceivable")}
              icon={MonetizationOnIcon}
              value={`$ ${
                totalReceivableFromDeliveryBoy
                  ? totalReceivableFromDeliveryBoy.toFixed(2)
                  : 0
              }`}
            />
          </div>
        </div>
      </div>
      <br />
      <Box display="flex" paddingRight={1}>
        <Box flex={2}>
          <OrderStatusSteppers totals={orderTotals} />
        </Box>
        <Spacer />
        <Box flex={1}>
          <StatsBox
            data={[
              {
                title: "Users",
                value: users,
                link: "#/users/users",
                color: orange[500],
                Icon: People,
              },
              {
                title: "Vendors",
                value: vendors,
                link: '#/users/users?filter={"role":"VENDOR"}&order=DESC&page=1&perPage=10&sort=updatedAt',
                color: pink[500],
                Icon: People,
              },
              {
                title: "Agents",
                value: totalAgents,
                color: red[500],
                Icon: People,
              },
              {
                title: "Delivery Boy",
                value: totalDeliveryBoy,
                color: green[500],
                Icon: People,
              },
              {
                title: "Products",
                value: totalProducts,
                link: "#/products/products",
                color: blue[500],
                Icon: PhotoLibraryIcon,
              },
              {
                title: "Sold Items",
                value: totalSales,
                color: red[500],
                Icon: Money,
              },
            ]}
            title="Store Stats"
          />
        </Box>
        <Spacer />
        <Box flex={1}>
          <StatsBox
            data={[
              {
                title: "Payment Request",
                value: totalPaymentRequest,
                link: '#/sales/sales?filter={"paymentRequest.status":true,"paymentStatus.status":false}',
                color: red[500],
                Icon: MonetizationOn,
              },
              {
                title: "New Vendor Request",
                value: totalNewVendorRequest,
                link: '#/users/users?filter={"role":"VENDOR","isActivated":false}',
                color: green[500],
                Icon: People,
              },
              {
                title: "New Agent Request",
                value: totalNewAgentRequest,
                link: '#/users/users?filter={"role":"AGENT","isActivated":false}',
                color: orange[500],
                Icon: People,
              },
              {
                title: "Agent Payment Request",
                value: totalAgentPaymentRequest,
                color: red[500],
                Icon: MonetizationOn,
              },
              {
                title: "Special Discount Request",
                value: totalSpecialDiscountRequest,
                link: '#/users/special-discount-requests?filter={"status":"pending"}',
                color: red[500],
                Icon: MonetizationOn,
              },
            ]}
            title="Requests"
          />
        </Box>
      </Box>
      <br />
      <Box display="flex">
        <Box flex={2} marginRight={2}>
          <SaleChart sales={recentSales} />
        </Box>
        <Box flex={1} marginRight={1}>
          <TopSellerPieChart
            data={topSellers}
            topSalerDateFilter={topSalerDateFilter}
            setTopSalerDateFilter={setTopSalerDateFilter}
          />
        </Box>
      </Box>

      <Box display="flex" marginTop={2}>
        <FeesCollectionsBarChart
          data={feesByPaymentType}
          feesByPaymentTypeFilter={feesByPaymentTypeFilter}
          setFeesByPaymentTypeFilter={setFeesByPaymentTypeFilter}
        />
        <Box marginLeft={1} marginRight={1}>
          <Card style={{ padding: 5 }}>
            <List
              title={<span></span>}
              pagination={false}
              hasCreate={false}
              basePath="/products/products"
              resource="products/products"
              filter={{ "meta.isApproved": false }}
              sort={{ field: "updatedAt", order: "DESC" }}
              exporter={false}
              actions={<ProductReviewAction />}
              empty={<Empty />}
              perPage={5}
            >
              <Datagrid>
                <IDField source="id" />
                <MyImageField source="media" />
                <TextField source="title" />
              </Datagrid>
            </List>
            <div style={{ margin: 4, textAlign: "right" }}>
              <Link to='products/products?filter={"meta":{"isApproved":false},"meta.isDeleted":false}'>
                More...{" "}
              </Link>
            </div>
          </Card>
        </Box>
      </Box>
    </div>
  ) : null
}

export default Dashboard
