import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  BooleanInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
  title: { width: 544 },
  placeholder: { width: 544 },
  products: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const ProductCMSTitle = ({ record }: any) => (
  <span>Edit ProductCMS {record ? `"${record.title}"` : `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 5

export const ProductCMSEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<ProductCMSTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.productCMS.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <NumberInput
          source="placeholder"
          min={1}
          fullWidth
          formClassName={classes.placeholder}
        />
        <ReferenceArrayInput
          source="products"
          reference="products/products"
          sort={{ field: "title", order: "ASC" }}
          perPage={50}
          fullWidth
          formClassName={classes.products}
        >
          <AutocompleteArrayInput optionValue="id" optionText="title" />
        </ReferenceArrayInput>
        <DateInput source="expiresAt" />
        <BooleanInput source="isCollection" />
      </SimpleForm>
    </Edit>
  )
}
