import FileCopyIcon from "@material-ui/icons/FileCopy"
import { PageCMSCreate } from "./PageCMSCreate"
import { PageCMSEdit } from "./PageCMSEdit"
import { PageCMSList } from "./PageCMSList"

export default {
  list: PageCMSList,
  create: PageCMSCreate,
  edit: PageCMSEdit,
  icon: FileCopyIcon,
}
