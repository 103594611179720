import CreditCardIcon from "@material-ui/icons/CreditCard"

import { CreditSchemeCreate } from "./CreditSchemeCreate"
import { CreditSchemeEdit } from "./CreditSchemeEdit"
import { CreditSchemeList } from "./CreditSchemeList"

export default {
  list: CreditSchemeList,
  create: CreditSchemeCreate,
  edit: CreditSchemeEdit,
  icon: CreditCardIcon,
}
