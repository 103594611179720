import {
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"

interface SocialShareProps {
  url: string
  title?: string
  size?: number
}

const SocialShare = ({ url, title, size }: SocialShareProps) => {
  size = size || 25
  return (
    <>
      <WhatsappShareButton title={title} separator=": " url={url}>
        <WhatsappIcon size={size} round />
      </WhatsappShareButton>
      <ViberShareButton url={url}>
        <ViberIcon size={size} round />
      </ViberShareButton>
    </>
  )
}

export default SocialShare
