import { FC, useState } from "react"
import {
  Button,
  ButtonProps,
  FormWithRedirect,
  SaveButton,
  TextInput,
  useNotify,
  useRefresh,
  useUpdate,
} from "react-admin"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCancel from "@material-ui/icons/Cancel"
import CreateIcon from "@material-ui/icons/Create"
import ShareIcon from "@material-ui/icons/Share"
import EmailIcon from "@material-ui/icons/Email"
import SocialShare from "../../../components/SocialShare"
import { Box } from "@material-ui/core"

const ShareButton: FC<ButtonProps> = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const [update, { loading }] = useUpdate()
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const vendor = record?.vendor

  const handleSubmit = async (values) => {
    console.log("Value = ", values)
    update(
      `orders/items/v1/share/pdf`,
      vendor!.id,
      values,
      {},
      {
        onSuccess: () => {
          console.log("Success")
          setShowDialog(false)
          notify("Pdf shared successfully.")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  const url = `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : process.env.REACT_APP_BASE_URL
  }/api/admin/orders/items/v1/pdf/${vendor?.id}`

  return (
    <>
      <Box display="flex" justifyItems="center" gridGap={4}>
        <Button
          onClick={handleClick}
          style={{
            minHeight: 1,
            minWidth: 1,
            padding: 1,
          }}
          size="large"
        >
          <EmailIcon fontSize="large" />
        </Button>
        <SocialShare title="Order Item List" url={url} />
      </Box>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Share"
      >
        <DialogTitle>Share pdf</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="recipient"
                  defaultValue={vendor?.email}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                  variant="outlined"
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  label="Share"
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default ShareButton
