import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  CreateProps,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

const styles = {
  amount: { width: 544 },
  userIds: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

export const CreditSchemeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()

  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput source="amount" fullWidth formClassName={classes.amount} />
        <ReferenceArrayInput
          source="userIds"
          reference="users/users"
          fullWidth
          formClassName={classes.amount}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}
