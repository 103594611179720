import * as React from "react"
import { FC } from "react"
import classnames from "classnames"
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { FieldProps, useTranslate } from "react-admin"

const useStyles = makeStyles({
  container: { minWidth: "35em" },
  rightAlignedCell: { textAlign: "right" },
  boldCell: { fontWeight: "bold" },
})

const Totals: FC<FieldProps> = ({ record }: { record?: any }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Table className={classes.container}>
      <TableBody>
        <TableRow>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.sum")} */}
            Sum
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            रू{" "}
            {(
              record?.totalPrice +
              (record?.couponDiscount ?? 0) +
              (record?.specialDiscount ?? 0) +
              (record?.discount ?? 0)
            )?.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.sum")} */}
            Delivery Cost
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            रू{" "}
            {record?.shippingCost?.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Special Discount</TableCell>
          <TableCell className={classes.rightAlignedCell}>
            रू{" "}
            {record?.specialDiscount?.toLocaleString("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) ?? "0.00"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.total")} */}
            Discount
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            रू{" "}
            {record?.discount
              ? (record?.discount ?? 0)?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.total")} */}
            Coupon Discount
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            रू{" "}
            {record?.couponDiscount
              ? (record?.couponDiscount ?? 0)?.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.boldCell}>
            {/* {translate("resources.commands.fields.basket.total")} */}
            Total
          </TableCell>
          <TableCell
            className={classnames(classes.boldCell, classes.rightAlignedCell)}
          >
            रू{" "}
            {(record?.discountedPrice + record?.shippingCost).toLocaleString(
              "en-IN",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default Totals
