import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { SpecialDiscountCreate } from "./SpecialDiscountCreate"
import { SpecialDiscountEdit } from "./SpecialDiscountEdit"
import { SpecialDiscountList } from "./SpecialDiscountList"

export default {
  list: SpecialDiscountList,
  create: SpecialDiscountCreate,
  edit: SpecialDiscountEdit,
  icon: AttachMoneyIcon,
}
