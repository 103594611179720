import * as React from "react"
import { FC, memo } from "react"
import { Avatar, Box, Chip } from "@material-ui/core"
import { FieldProps } from "react-admin"

const CategoryField: FC<FieldProps> = ({ record }) => {
  return record && record?.title ? (
    <div key={record.title}>
      <Chip
        label={record.title}
        avatar={
          record.image ? (
            <Avatar
              src={`${process.env.REACT_APP_AWS_BUCKET_URL}${record.image}`}
            />
          ) : (
            <Avatar>{record.title.charAt(0)}</Avatar>
          )
        }
        clickable
        //   color="primary"
        //   variant="outlined"
      />
      <Box pt="0.1em" />
    </div>
  ) : null
}

export default memo<FieldProps>(CategoryField)
