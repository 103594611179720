import * as React from "react"
import { FC, useCallback } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  useNotify,
  useMutation,
  ReferenceInput,
  useRefresh,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  EditProps,
  required,
  TextInput,
} from "react-admin"

import { SectionTitle } from "../../common/form"

const styles = {
  id: { width: 544 },
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  discountType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const AgentSchemeTitle = ({ record }: any) => (
  <span>Edit AgentScheme {record && `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 6

export const AgentSchemeEdit: FC<EditProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const [mutate] = useMutation()
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "agents/agentScheme",
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        )
        notify("Product updated successfully.")
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )

  return (
    <Edit title={<AgentSchemeTitle />} {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.agentScheme.fieldGroups.title" />
        <TextInput
          source="title"
          formClassName={classes.title}
          fullWidth
          validate={[required()]}
        />
        <SectionTitle label="resources.agentScheme.fieldGroups.brands" />
        <ArrayInput source="affiliatedBrands">
          <SimpleFormIterator>
            <ReferenceInput
              label="Brands"
              source="brandId"
              reference="products/brands"
              sort={{ field: "title", order: "ASC" }}
              perPage={10}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.brands}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>
        <SectionTitle label="resources.agentScheme.fieldGroups.categories" />
        <ArrayInput source="affiliatedCategories">
          <SimpleFormIterator>
            <ReferenceInput
              label="Categories"
              source="categoryId"
              reference="products/categories"
              sort={{ field: "title", order: "ASC" }}
              filter={{ entry: true }}
              perPage={10}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.categories}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>
        <SectionTitle label="resources.agentScheme.fieldGroups.products" />
        <ArrayInput source="affiliatedProducts">
          <SimpleFormIterator>
            <ReferenceInput
              label="Products"
              source="productId"
              reference="products/products"
              sort={{ field: "title", order: "ASC" }}
              perPage={10}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.products}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>

        <SectionTitle label="resources.agentScheme.fieldGroups.options" />
        <BooleanInput
          label="Activated"
          source="isActivated"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
