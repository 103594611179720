import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  DateInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  user: { width: 544 },
  category: { display: "inline-block" },
  status: { display: "inline-block", marginLeft: 32 },
  message: { width: 544 },
  approvedAt: { display: "inline-block" },
  rejectedAt: { display: "inline-block", marginLeft: 32 },
  approvedBy: { width: 544 },
}

const useStyles = makeStyles(styles)

export const SpecialDiscountRequestCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "users/special-discount-requests",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Special discount request saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.specialDiscountRequests.fieldGroups.information" />
        <ReferenceInput
          source="user"
          reference="users/users"
          fullWidth
          formClassName={classes.user}
        >
          <AutocompleteInput />
        </ReferenceInput>
        <SelectInput
          source="category"
          choices={[
            { id: "teacher", name: "Teacher" },
            { id: "student", name: "Student" },
            { id: "corporate", name: "Corporate" },
            { id: "farmers", name: "Farmers" },
            { id: "surakshaKarmi", name: "Suraksha Karmi" },
          ]}
          formClassName={classes.category}
        />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "approved", name: "Approved" },
            { id: "rejected", name: "Rejected" },
          ]}
          formClassName={classes.status}
        />
        <TextInput
          source="message"
          multiline
          fullWidth
          formClassName={classes.message}
        />
        <DateInput source="approvedAt" formClassName={classes.approvedAt} />
        <DateInput source="rejectedAt" formClassName={classes.rejectedAt} />
        <ReferenceInput
          source="approvedBy"
          reference="users/users"
          formClassName={classes.approvedBy}
          fullWidth
        >
          <AutocompleteInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
