// Authenticated by default
export default {
  login: async ({ phone, password }) => {
    const request = new Request("api/users/signin", {
      method: "POST",
      body: JSON.stringify({ phone, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })

    const res = await fetch(request)
    if (res.ok) {
      const { roles } = await res.json()
      for (let role of roles) {
        if (role.name === "ADMIN" || "STAFF" || "DATA_ENTRY" || "MANAGER")
          return Promise.resolve()
      }
      return Promise.reject()
    }
    const { errors } = await res.json()
    return Promise.reject(errors[0].message)
  },
  logout: async () => {
    const request = new Request("api/users/signout", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    try {
      await fetch(request)
      return Promise.resolve("/login")
    } catch (err) {
      return Promise.reject(err)
    }
  },
  checkError: ({ status }) => {
    return status === 401 || status === 403
      ? Promise.resolve("You arenot authorized")
      : Promise.resolve()
  },
  checkAuth: async () => {
    const request = new Request("api/users/currentuser", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    const res = await fetch(request)
    if (res.ok) {
      const { currentUser } = await res.json()
      if (!currentUser) return Promise.reject()
      if (
        currentUser.roles.includes("ADMIN") ||
        currentUser.roles.includes("STAFF") ||
        currentUser.roles.includes("DATA_ENTRY") ||
        currentUser.roles.includes("MANAGER")
      )
        return Promise.resolve()
      return Promise.reject("You aren't authorized to access admin Dashboard!")
    }

    const { errors } = await res.json()
    return Promise.reject(errors[0].message)
  },
  getPermissions: async () => {
    const request = new Request("api/users/currentuser", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    try {
      const response = await fetch(request)
      const { currentUser } = await response.json()
      const { roles, permissions } = await currentUser
      return Promise.resolve({ roles, permissions })
    } catch (err) {
      return Promise.reject(err)
    }
  },
  getIdentity: async () => {
    const request = new Request("api/users/currentuser", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    try {
      const response = await fetch(request)
      const { currentUser } = await response.json()
      const { id, name: fullName, avatar } = await currentUser
      return Promise.resolve({ id, fullName, avatar })
    } catch (err) {
      return Promise.reject(err)
    }
  },
}
