import LoyaltyIcon from "@material-ui/icons/Loyalty"
import { BrandCreate } from "./BrandCreate"
import { BrandEdit } from "./BrandEdit"
import { BrandList } from "./BrandList"

export default {
  create: BrandCreate,
  list: BrandList,
  edit: BrandEdit,
  icon: LoyaltyIcon,
}
