import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff"
import { ShippingMethodCreate } from "./ShippingMethodCreate"
import { ShippingMethodEdit } from "./ShippingMethodEdit"
import { ShippingMethodList } from "./ShippingMethodList"

export default {
  create: ShippingMethodCreate,
  list: ShippingMethodList,
  edit: ShippingMethodEdit,
  icon: FlightTakeoffIcon,
}
