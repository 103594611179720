import * as React from "react"
import { FC } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import { Link, FieldProps, useTranslate, useQueryWithStore } from "react-admin"

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: "right" },
  bold: { fontWeight: "bold" },
})

const MiniBasket: FC<FieldProps> = ({ record }: any) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { items, ...otherProps } = record

  if (!items) return null
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Item Id</TableCell>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.reference")} */}
            Product
          </TableCell>
          <TableCell>
            {/* {translate("resources.commands.fields.basket.unit_price")} */}
            Unit Price
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {/* {translate("resources.commands.fields.basket.quantity")} */}
            Quantity
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>
            {/* {translate("resources.commands.fields.basket.total")} */}
            Total
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(
          (item: any, index) =>
            item.product && (
              <TableRow key={index}>
                <TableCell>
                  {/* @ts-ignore */}
                  <Link to={`/orders/items/${item.id}`}>
                    #{item.id.substr(item.id.length - 4)}
                  </Link>
                </TableCell>
                <TableCell>
                  {/* @ts-ignore */}
                  <Link to={`/products/products/${item.product.id}`}>
                    {item.product.title}
                  </Link>
                </TableCell>
                {item?.hasVariation ? (
                  <TableCell>
                    {item.variations.map((variation) => {
                      const { conf } = variation
                      const keys = Object.keys(conf)
                      return (
                        <div>
                          {keys.map((key) => (
                            <>
                              <span>{key}: </span>
                              <span className={classes.bold}>
                                {conf[key].title},{" "}
                              </span>
                            </>
                          ))}{" "}
                          रू{" "}
                          {variation.price.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      )
                    })}
                  </TableCell>
                ) : (
                  <TableCell>
                    रू{" "}
                    {item?.price.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </TableCell>
                )}
                {item?.hasVariation ? (
                  <TableCell className={classes.rightAlignedCell}>
                    {item.variations.map((variation) => (
                      <div>{variation.quantity}</div>
                    ))}
                  </TableCell>
                ) : (
                  <TableCell className={classes.rightAlignedCell}>
                    {item.quantity}
                  </TableCell>
                )}
                <TableCell className={classes.rightAlignedCell}>
                  रू{" "}
                  {item.totalPrice.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  )
}

export default MiniBasket
