import { makeStyles, TextField } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
  title: { width: 544 },
  link: { width: 544 },
  position: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const MenuCMSTitle = ({ record }: any) => (
  <span>Edit MenuCMS {record ? `"${record.title}"` : `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 5

export const MenuCMSEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<MenuCMSTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.menuCMS.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput source="link" fullWidth formClassName={classes.link} />
        <NumberInput source="position" formClassName={classes.position} />
        <ReferenceArrayInput
          source="parents"
          reference="cms/menus"
          sort={{ field: "title", order: "ASC" }}
          perPage={100}
          filterToQuery={(val: string) =>
            condition(val) ? { name: val.trim() } : {}
          }
        >
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}
