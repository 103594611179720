import CategoryIcon from "@material-ui/icons/Category"
import { CategoryEdit } from "./CategoryEdit"
import { CategoryList } from "./CategoryList"
import { CategoryCreate } from "./CategoryCreate"

// TODO: Category Edit

export default {
  create: CategoryCreate,
  list: CategoryList,
  edit: CategoryEdit,
  icon: CategoryIcon,
}
