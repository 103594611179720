import * as React from "react"
import { FC } from "react"
import { Box, makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
  TextField,
  SelectInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { display: "inline-block" },
  phone: { display: "inline-block", marginLeft: 32 },
  trackingNumber: { display: "inline-block" },
  orderNumber: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const SaleTitle = ({ record }: any) => <span>Edit Sale #{record.id}</span>

export const SaleEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<SaleTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="Product Details" />
        <TextField label="Title" source="product.title" />
        <SectionTitle label="Buyer's Details" />
        <TextField
          label="Name"
          source="user.name"
          formClassName={classes.name}
        />
        <TextField
          label="Phone"
          source="user.phone"
          formClassName={classes.phone}
        />
        <SectionTitle label="Order Details" />
        <TextField
          label="Tracking Number"
          source="order.trackingNumber"
          formClassName={classes.trackingNumber}
        />
        <TextField
          label="Order Number"
          source="order.orderNumber"
          formClassName={classes.orderNumber}
        />
        <SectionTitle label="Actions" />
        <SelectInput
          source="type"
          choices={[
            { id: "sale", name: "Sale" },
            { id: "sale_return", name: "Sale Return" },
            { id: "purchase", name: "Purchase" },
            { id: "purchase_return", name: "Purchase Return" },
          ]}
        />
        <BooleanInput
          label="Refund"
          source="isRefund"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Payment status"
          source="paymentStatus.status"
          formClassName={classes.boolean}
        />
        <SectionTitle label="Note" />
        <TextInput source="note" multiline />
      </SimpleForm>
    </Edit>
  )
}
