import NotificationsIcon from "@material-ui/icons/Notifications"
import { NotificationCreate } from "./NotificationCreate"
import { NotificationEdit } from "./NotificationEdit"
import { NotificationList } from "./NotificationList"

export default {
  list: NotificationList,
  create: NotificationCreate,
  edit: NotificationEdit,
  icon: NotificationsIcon,
}
