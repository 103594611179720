import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanField,
  NumberField,
  FilterProps,
  ListProps,
  FunctionField,
  ChipField,
  ReferenceField,
} from "react-admin"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import { Chip } from "@material-ui/core"
import red from "@material-ui/core/colors/red"

const DiscountFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

const DiscountValueField = ({ record }: any) => {
  return (
    <Chip
      label={
        record.discountType === "percentage"
          ? `${record.value}%`
          : `Rs.${record.value.toLocaleString("en-IN")}`
      }
      style={{ backgroundColor: red[500], color: "white" }}
    />
  )
}

const DiscountToField = ({ record }: any) => {
  return (
    <span>
      {record.discountOn === "brand" && (
        <ReferenceField source="discountTo" reference="products/brands">
          <TextField source="title" />
        </ReferenceField>
      )}
      {record.discountOn === "category" && (
        <ReferenceField source="discountTo" reference="products/categories">
          <TextField source="title" />
        </ReferenceField>
      )}
      {record.discountOn === "product" && (
        <ReferenceField source="discountTo" reference="products/products">
          <TextField source="title" />
        </ReferenceField>
      )}
      {record.discountOn === "tag" && (
        <ReferenceField source="discountTo" reference="products/tags">
          <TextField source="title" />
        </ReferenceField>
      )}
      {record.discountOn === "vendor" && (
        <ReferenceField source="discountTo" reference="users/users">
          <TextField source="name" />
        </ReferenceField>
      )}
    </span>
  )
}

export const DiscountList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<DiscountFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        {/* <TextField source="title" /> */}
        <DiscountToField source="discountTo" />
        <ChipField
          source="discountOn"
          style={{ textTransform: "capitalize" }}
        />
        <DiscountValueField source="discountValue" />
        <DateField source="startsAt" showTime />
        <DateField source="expiresAt" showTime />
        <BooleanField
          label="Visible"
          source="isVisible"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
