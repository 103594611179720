import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  BooleanInput,
  useNotify,
  useRedirect,
  useMutation,
  ReferenceInput,
  CreateProps,
  useRefresh,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  required,
  TextInput,
} from "react-admin"

import { SectionTitle } from "../../common/form"

const styles = {
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  discountType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const condition = (val: string) => !!val && val.trim().length > 6

export const AgentSchemeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()
  const refresh = useRefresh()

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      console.log("values", values)
      try {
        await mutate(
          {
            type: "create",
            resource: "agents/agentScheme",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify("Agent scheme created successfully.")
        if (!redirect) {
          redirectTo("create", props.basePath)
          refresh()
        }
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.agentScheme.fieldGroups.title" />
        <TextInput
          source="title"
          formClassName={classes.title}
          fullWidth
          validate={[required()]}
        />
        <SectionTitle label="resources.agentScheme.fieldGroups.brands" />
        <ArrayInput source="affiliatedBrands">
          <SimpleFormIterator>
            <ReferenceInput
              label="Brands"
              source="brandId"
              reference="products/brands"
              sort={{ field: "title", order: "ASC" }}
              perPage={1000}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.brands}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>
        <SectionTitle label="resources.agentScheme.fieldGroups.categories" />
        <ArrayInput source="affiliatedCategories">
          <SimpleFormIterator>
            <ReferenceInput
              label="Categories"
              source="categoryId"
              reference="products/categories"
              sort={{ field: "title", order: "ASC" }}
              filter={{ entry: true }}
              perPage={1000}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.categories}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>
        <SectionTitle label="resources.agentScheme.fieldGroups.products" />
        <ArrayInput source="affiliatedProducts">
          <SimpleFormIterator>
            <ReferenceInput
              label="Products"
              source="productId"
              reference="products/products"
              sort={{ field: "title", order: "ASC" }}
              perPage={10000}
              filterToQuery={(val: string) =>
                condition(val) ? { title: val.trim() } : {}
              }
              fullWidth
              formClassName={classes.products}
            >
              <AutocompleteInput optionValue="id" optionText="title" />
            </ReferenceInput>
            <NumberInput label="Percentage" source="percentage" />
          </SimpleFormIterator>
        </ArrayInput>

        <SectionTitle label="resources.agentScheme.fieldGroups.options" />
        <BooleanInput
          label="Activated"
          source="isActivated"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Create>
  )
}
