import React, { useState } from "react"
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useUpdateMany,
  useRefresh,
  useUnselectAll,
  ReferenceInput,
  SelectInput,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "mui-rff"
import { MenuItem } from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"

function RefundPaymentButton({ selectedIds }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [updateMany, { loading }] = useUpdateMany("orders/items")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    updateMany(
      "orders/items",
      selectedIds,
      { ...values },
      {
        onSuccess: () => {
          setShowDialog(false)
          refresh()
          notify("Order items updated")
          unselectAll("orders/items")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="Update payment to admin.">
        <CreateIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update payment"
      >
        <DialogTitle>Update payment to admin</DialogTitle>

        <FormWithRedirect
          resource="orders/items"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <SelectInput
                  label="Paid to Admin"
                  source="refundPaymentStatus.status"
                  choices={[
                    { id: true, name: "Yes" },
                    { id: false, name: "No" },
                  ]}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

const PickupBoySelectInput = ({ choices }) => {
  if (!choices) return <span>loading</span>
  return (
    <TextField
      name="pickupBoyId"
      label="Pickup Boy"
      variant="filled"
      size="small"
      select
    >
      {choices.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default RefundPaymentButton
