import * as React from "react"
import { Fragment, useState } from "react"
import {
  Button,
  Confirm,
  useDeleteMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin"
import { red } from "@material-ui/core/colors"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

const DeletePermanentlyButton = ({
  selectedIds,
  resource,
  resourceLabel,
  label = "delete",
}) => {
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const [deleteMany, { loading }] = useDeleteMany(
    `${resource}/delete`,
    selectedIds,
    {
      onSuccess: () => {
        refresh()
        notify(`${resourceLabel} deleted successfully`)
        unselectAll(resource)
      },
      onFailure: (error) =>
        notify(`Error: ${resourceLabel} not updated`, "warning"),
    }
  )
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    deleteMany()
    setOpen(false)
  }

  return (
    <Fragment>
      <Button
        label={
          label != "delete" ? label : `Delete ${resourceLabel} permanently`
        }
        onClick={handleClick}
        style={{ color: red[500] }}
      >
        <CheckCircleIcon />
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={`Delete ${resourceLabel} Permanently`}
        content="Are you sure you want to delete permanently?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

export default DeletePermanentlyButton
