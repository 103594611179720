import { FC } from "react"
import {
  DateField,
  Edit,
  EditProps,
  InputProps,
  SimpleForm,
  TextField,
} from "react-admin"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { Button, makeStyles } from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
  title: { width: 544 },
  link: { width: 544 },
  position: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const CopyToClipboardShortUrl = (props: InputProps) => {
  const { record } = props
  if (!record) return null
  return (
    <CopyToClipboard text={record.shortUrl}>
      <Button variant="contained" startIcon={<FileCopyIcon />}>
        Copy Url
      </Button>
    </CopyToClipboard>
  )
}

export const URLShortnerEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit {...props}>
      <SimpleForm>
        <DateField source="createdAt" />
        <TextField source="urlCode" />
        <TextField source="longUrl" />
        <TextField source="shortUrl" />
        <CopyToClipboardShortUrl source="shortUrl" />
      </SimpleForm>
    </Edit>
  )
}
