import DomainIcon from "@material-ui/icons/Domain"
import { DistrictList } from "./DistrictList"
import { DistrictEdit } from "./DistrictEdit"
import { DistrictCreate } from "./DistrictCreate"

export default {
  list: DistrictList,
  edit: DistrictEdit,
  create: DistrictCreate,
  icon: DomainIcon,
}
