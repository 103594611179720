import * as React from "react"
import { ListProps } from "@material-ui/core"

import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  ReferenceField,
  EditButton,
} from "react-admin"
import { IDField } from "../../common/form"

export const VendorPaymentList: React.FC<ListProps> = (props) => (
  <List {...props}>
    <Datagrid>
      <DateField source="paymentDate" showTime />
      <ReferenceField source="vendorId" reference="users/users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="paymentReference" />
      <FunctionField
        label="Payment Method"
        source="paymentMethod"
        render={(record) => record?.paymentMethod?.toUpperCase()}
      />
      <TextField source="otherDetails" />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record) => <strong>{record?.amount}</strong>}
      />
      <EditButton />
    </Datagrid>
  </List>
)
