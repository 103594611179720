import * as React from "react"
import { FC, useCallback, useState, useEffect } from "react"
import { useFormState } from "react-final-form"
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useMutation,
  useNotify,
  useQuery,
  useRedirect,
  useRefresh,
} from "react-admin"
import { makeStyles, MenuItem, TextField } from "@material-ui/core"

import { requiredValidate, SectionTitle, Separator } from "../../common/form"
import SeoKeywordInput from "../../common/form/SeoKeywordInput"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  level: { display: "inline-block" },
  parent: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
  metaTitle: { width: 544 },
  metaDescription: { width: 544 },
}

const useStyles = makeStyles(styles)
interface RenderParentCategoryInputProps {
  name: string
}

const RenderParentCategoryInput: FC<RenderParentCategoryInputProps> = ({
  name,
}) => {
  const { values } = useFormState()
  const classes = useStyles()
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "products/categories",
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "updatedAt", order: "DESC" },
      filter: { level: values.level - 1 },
    },
  })
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <p>ERROR</p>
  }

  const choices = [
    ...data.map((item: any) => ({ id: item.id, name: item.title })),
  ]

  return values.level !== 1 ? (
    <AutocompleteInput
      source="parent"
      choices={choices}
      formClassName={classes.parent}
    />
  ) : null
}

export const CategoryCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const choices = [
    { id: 1, name: "Level 1" },
    { id: 2, name: "Level 2" },
    { id: 3, name: "Level 3" },
    { id: 4, name: "Level 4" },
  ]
  const notify = useNotify()
  const refresh = useRefresh()
  const redirectTo = useRedirect()
  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/categories",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify("Category created successfully.")
        if (!redirect) {
          redirectTo("create", props.basePath)
          refresh()
        }
        redirectTo("edit", basePath, mutant.data.id, mutant.data)
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm>
        <SectionTitle label="resources.categories.fieldGroups.information" />
        <TextInput
          source="title"
          autoFocus
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          formClassName={classes.description}
        />
        <NumberInput source="rank" />
        <SelectInput
          source="level"
          choices={choices}
          formClassName={classes.level}
        />
        <RenderParentCategoryInput name="parent" />
        <Separator />
        <SectionTitle label="resources.categories.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Category image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="bannerImage"
          label="Banner image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <SectionTitle label="resources.categories.fieldGroups.options" />
        <BooleanInput
          label="featured"
          source="isFeatured"
          defaultValue={true}
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="visible"
          source="isVisible"
          defaultValue={true}
          formClassName={classes.boolean}
        />
        <SectionTitle label="resources.categories.fieldGroups.seo" />
        <TextInput
          label="Meta title"
          source="seo.meta_title"
          fullWidth
          formClassName={classes.metaTitle}
        />
        <TextInput
          label="Meta description"
          source="seo.meta_description"
          fullWidth
          formClassName={classes.metaDescription}
        />
        <SeoKeywordInput />
      </SimpleForm>
    </Create>
  )
}
