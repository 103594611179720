import * as React from "react"
import { FC, useState } from "react"
import {
  Button,
  FormWithRedirect,
  SaveButton,
  NumberInput,
  useUpdate,
  SelectInput,
  useRefresh,
  useNotify,
  ReferenceInput,
  TextInput,
  useGetOne,
} from "react-admin"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Button as MuiButton,
  Link as MaterialLink,
  IconButton,
  TextField,
} from "@material-ui/core"
import IconCancel from "@material-ui/icons/Cancel"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import EditIcon from "@material-ui/icons/Edit"
import { Link, FieldProps, useTranslate } from "react-admin"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"
import GetAppIcon from "@material-ui/icons/GetApp"
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda"
import { FieldArray } from "react-final-form-arrays"
import { useFormState } from "react-final-form"
import DoneIcon from "@material-ui/icons/Done"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import DeleteIcon from "@material-ui/icons/Delete"

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: "right" },
  bold: { fontWeight: "bold" },
  quantity: { display: "inline-block", marginRight: "1em" },
  discount: { marginLeft: 32 },
  download: { display: "none", "&:hover": { display: "block" } },
})

const API_URL =
  process.env.NODE_ENV == "production"
    ? process.env.REACT_APP_BASE_URL
    : "http://localhost:4000"

const Basket: FC<FieldProps> = ({ record }: any) => {
  const [showDialog, setShowDialog] = useState(false)
  const [showImageDialog, setShowImageDialog] = useState(false)
  const [showPriceUpdateDialog, setShowPriceUpdateDialog] = useState(false)
  const [showNoteUpdateDialog, setShowNoteUpdateDialog] = useState(false)
  const [showPrintLabelDialog, setShowPrintLabelDialog] = useState(false)
  const [orderItem, setOrderItem] = useState<any>({})
  const [quantity, setQuantity] = useState(1)
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()

  const [update, { loading }] = useUpdate()

  const { items, ...otherProps } = record

  const handleClick = (item: any) => {
    setOrderItem(item)
    setShowDialog(true)
  }

  const handleImageClick = (item: any) => {
    setOrderItem(item)
    setShowImageDialog(true)
  }

  const handlePriceUpdateClick = (item: any) => {
    setOrderItem(item)
    setShowPriceUpdateDialog(true)
  }

  const handleCloseImageClick = () => {
    setShowImageDialog(false)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleClosePriceUpdateClick = () => {
    setShowPriceUpdateDialog(false)
  }

  const handleNoteUpdateClick = (item: any) => {
    setOrderItem(item)
    setShowNoteUpdateDialog(true)
  }

  const handleCloseNoteUpdateClick = () => {
    setShowNoteUpdateDialog(false)
  }

  const handlePrintLabelClick = (item: any) => {
    setOrderItem(item)
    setShowPrintLabelDialog(true)
  }

  const handleClosePrintLabelClick = () => {
    setShowPrintLabelDialog(false)
  }

  const handleSubmit = async (values) => {
    const record = {
      // @ts-ignore
      id: orderItem?.id,
      // @ts-ignore
      quantity: orderItem?.quantity,
      // @ts-ignore
      discount: orderItem?.discount,
      // @ts-ignore
      status: orderItem?.status,
      // @ts-ignore
      specialDiscount: orderItem?.specialDiscount,
      // @ts-ignore
      pickupBoyId: orderItem?.pickupBoyId,
      // @ts-ignore
      variations: orderItem?.variations,
      // @ts-ignore
      note: orderItem?.note,
    }
    const {
      id,
      quantity,
      discount,
      status,
      specialDiscount,
      pickupBoyId,
      variations,
      note,
    } = values

    update(
      "orders/items",
      id,
      {
        id,
        quantity,
        discount,
        status,
        specialDiscount,
        pickupBoyId,
        // @ts-ignore
        product: orderItem.product.id,
        variations,
        note,
      },
      record,
      {
        onSuccess: () => {
          notify("Order item updated successfully.")
          setShowDialog(false)
          refresh()
        },
        onFailure: () => {
          setShowDialog(false)
          notify("Error updating order item.", "warning")
        },
      }
    )
  }

  const handlePriceUpdateSubmit = async (values) => {
    const { id } = orderItem

    update(
      "orders/items/update-price",
      id,
      {},
      {},
      {
        onSuccess: () => {
          notify("Price updated successfully.")
          setShowDialog(false)
          refresh()
        },
        onFailure: () => {
          setShowDialog(false)
          notify("Error updating price.", "warning")
        },
      }
    )
  }

  if (!items) return null

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item Id</TableCell>
            <TableCell>
              {/* {translate("resources.commands.fields.basket.reference")} */}
              Product
            </TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Item Status</TableCell>
            <TableCell>Pickup Boy Assign</TableCell>
            <TableCell>
              {/* {translate("resources.commands.fields.basket.unit_price")} */}
              Unit Price
            </TableCell>
            <TableCell>Unit Discount</TableCell>
            <TableCell>Coupon Discount</TableCell>
            <TableCell>Special Discount</TableCell>
            <TableCell className={classes.rightAlignedCell}>
              {/* {translate("resources.commands.fields.basket.quantity")} */}
              Quantity
            </TableCell>
            <TableCell className={classes.rightAlignedCell}>
              {/* {translate("resources.commands.fields.basket.total")} */}
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(
            (item: any, index) =>
              item.product && (
                <>
                  <TableRow key={index}>
                    <TableCell>
                      {/* @ts-ignore */}
                      <Link to={`/orders/items/${item.id}`}>
                        #{item.id.substr(item.id.length - 4)}
                      </Link>
                      <br />
                      <Button
                        onClick={() => handleClick(item)}
                        label="Update"
                        variant="outlined"
                      >
                        <EditIcon />
                      </Button>
                      <DeletePermanentlyButton
                        resourceLabel="Order Item"
                        resource="orders/items"
                        selectedIds={[item.id]}
                        label="Delete"
                      />
                    </TableCell>
                    <TableCell>
                      {/* @ts-ignore */}
                      <Link to={`/products/products/${item.product.id}`}>
                        {item.product.title}
                      </Link>
                      <br />
                      <Button
                        onClick={() => handleImageClick(item)}
                        label="Images"
                        variant="outlined"
                      >
                        <ViewAgendaIcon />
                      </Button>
                      <br />
                      {item?.note ?? "---"}
                      <br />
                      <Button
                        onClick={() => handleNoteUpdateClick(item)}
                        label="Note"
                        variant="outlined"
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      {/* @ts-ignore */}
                      <Link to={`/users/users/${item.product?.productBy?.id}`}>
                        {item.product?.productBy?.name}
                      </Link>
                    </TableCell>
                    <TableCell>{item.status?.toUpperCase()}</TableCell>
                    <TableCell>
                      {item.pickupBoyId ? (
                        <CheckCircleIcon
                          fontSize="small"
                          style={{ color: green[500] }}
                        />
                      ) : (
                        <CancelIcon
                          fontSize="small"
                          style={{ color: red[500] }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.hasVariation ? (
                        item.variations.map((variation) => {
                          const { conf } = variation
                          const keys = Object.keys(conf)
                          const unitVariationPrice =
                            variation?.price +
                            (variation?.discount ?? 0) +
                            (variation?.specialDiscount ?? 0) +
                            (variation?.couponDiscount ?? 0)
                          const showUnitVariationPrice =
                            unitVariationPrice?.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          return (
                            <div>
                              {keys &&
                                keys?.map((key) => (
                                  <>
                                    <span>{key}: </span>
                                    <span className={classes.bold}>
                                      {conf[key]?.title},{" "}
                                    </span>
                                  </>
                                ))}{" "}
                              रू{" "}
                              {unitVariationPrice == 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    padding: "8px",
                                  }}
                                >
                                  {showUnitVariationPrice}
                                </span>
                              ) : (
                                showUnitVariationPrice
                              )}
                            </div>
                          )
                        })
                      ) : (
                        <>
                          रू{" "}
                          {(
                            item?.price +
                            (item?.discount ?? 0) +
                            (item?.specialDiscount ?? 0) +
                            (item?.couponDiscount ?? 0)
                          )?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </>
                      )}
                      {item && item?.status !== "delivered" && (
                        <Button
                          label="Update"
                          onClick={() => handlePriceUpdateClick(item)}
                          variant="outlined"
                          size="small"
                        >
                          <AttachMoneyIcon />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      रू{" "}
                      {item?.hasVariation ? (
                        <>
                          {item.variations.map((variation) => {
                            return (
                              <div>
                                {variation?.discount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) ?? "0.00"}
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          {item?.discount
                            ? item?.discount?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : `0.00`}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      रू{" "}
                      {item?.couponDiscount?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell>
                      रू{" "}
                      {item?.hasVariation ? (
                        <>
                          {item.variations.map((variation) => {
                            return (
                              <div>{variation?.specialDiscount ?? "0.00"}</div>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          {item?.specialDiscount
                            ? item?.specialDiscount?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : `0.00`}
                        </>
                      )}
                    </TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      {item?.hasVariation ? (
                        item.variations.map((variation) => (
                          <div>{variation.quantity}</div>
                        ))
                      ) : (
                        <div>{item.quantity}</div>
                      )}
                      <Button
                        onClick={() => handlePrintLabelClick(item)}
                        label="Label"
                        variant="outlined"
                      >
                        <ViewAgendaIcon />
                      </Button>
                    </TableCell>
                    <TableCell className={classes.rightAlignedCell}>
                      रू{" "}
                      {item?.totalPrice?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={11}
                      style={{ paddingTop: "0", paddingBottom: "0" }}
                    >
                      <div>
                        <p>
                          <strong>Note: </strong>
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )
          )}
        </TableBody>
      </Table>
      {/* Update order item */}
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update Order Item"
      >
        <DialogTitle>Update Order Item</DialogTitle>

        <FormWithRedirect
          resource="orders/items"
          initialValues={{ ...orderItem }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                {
                  // @ts-ignore
                  !orderItem?.hasVariation && (
                    <Box display="flex">
                      <Box flex={1}>
                        <NumberInput source="quantity" fullWidth />
                      </Box>
                      <Box width="1em"></Box>
                      <Box flex={1}>
                        <NumberInput source="discount" disabled fullWidth />
                      </Box>
                    </Box>
                  )
                }
                {/* Has Variation */}
                {
                  // @ts-ignore
                  orderItem?.hasVariation && (
                    <SelectInput
                      source="status"
                      choices={[
                        { id: "pending", name: "New Orders" },
                        { id: "confirmed", name: "Confirmed" },
                        { id: "approve", name: "Approve" },
                        { id: "pickup", name: "Pickup" },
                        { id: "delivering", name: "Delivering" },
                        { id: "delivered", name: "Delivered" },
                        { id: "cancelled", name: "Cancelled" },
                        { id: "defect", name: "Defect" },
                        { id: "refund", name: "Refund" },
                      ]}
                      fullWidth
                    />
                  )
                }
                {
                  // @ts-ignore
                  !orderItem?.hasVariation && (
                    <>
                      <Box display="flex">
                        <Box flex={1}>
                          <SelectInput
                            source="status"
                            choices={[
                              { id: "pending", name: "New Orders" },
                              { id: "confirmed", name: "Confirmed" },
                              { id: "approve", name: "Approve" },
                              { id: "pickup", name: "Pickup" },
                              { id: "delivering", name: "Delivering" },
                              { id: "delivered", name: "Delivered" },
                              { id: "cancelled", name: "Cancelled" },
                              { id: "defect", name: "Defect" },
                              { id: "refund", name: "Refund" },
                            ]}
                            fullWidth
                          />
                        </Box>
                        <Box width="1em"></Box>
                        <Box flex={1}>
                          <NumberInput
                            source="specialDiscount"
                            disabled
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </>
                  )
                }
                <Box flex={1}>
                  <ReferenceInput
                    source="pickupBoyId"
                    reference="users/users"
                    filter={{ role: "DELIVERYBOY" }}
                    allowEmpty
                  >
                    <SelectInput optionText="name" fullWidth />
                  </ReferenceInput>
                </Box>
                <OrderItemVariation />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      {/* Price Update */}
      <Dialog open={showPriceUpdateDialog}>
        <DialogTitle>Are you sure you want to update price?</DialogTitle>
        <DialogContent>
          It will change order item price for product{" "}
          <strong>{orderItem && orderItem?.product?.title}</strong>.
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClosePriceUpdateClick}
            disabled={loading}
          >
            <IconCancel />
          </Button>
          <Button
            label="Yes"
            variant="outlined"
            onClick={handlePriceUpdateSubmit}
          >
            <DoneIcon />
          </Button>
        </DialogActions>
      </Dialog>
      {/* Show product image */}
      <Dialog
        fullWidth
        open={showImageDialog}
        onClose={handleCloseImageClick}
        aria-label="Show product images"
        maxWidth={"md"}
      >
        <DialogTitle>Product Images</DialogTitle>
        <DialogContent>
          {/* <p>You can download image</p> */}
          <div style={{ display: "flex" }}>
            {orderItem &&
              // @ts-ignore
              orderItem?.product?.media.map((image) => (
                <div style={{ position: "relative", margin: "5px" }}>
                  <a
                    // @ts-ignore
                    download={orderItem.product.title + ".jpg"}
                    href={process.env.REACT_APP_AWS_BUCKET_URL + image}
                    // @ts-ignore
                    title={orderItem.product.title}
                  >
                    <img
                      src={process.env.REACT_APP_AWS_BUCKET_URL + image}
                      width="auto"
                      height={200}
                    />
                    <GetAppIcon
                      style={{
                        position: "absolute",
                        top: "100px",
                        left: "90px",
                        color: "white",
                      }}
                    />
                  </a>
                </div>
              ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleCloseImageClick}
            disabled={loading}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
      {/* Add Note */}
      <Dialog
        fullWidth
        open={showNoteUpdateDialog}
        onClose={handleCloseNoteUpdateClick}
        aria-label="Add Note"
      >
        <DialogTitle>Add Note</DialogTitle>

        <FormWithRedirect
          resource="orders/items"
          initialValues={{ ...orderItem }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="note" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
      {/* Print Label */}
      <Dialog
        fullWidth
        open={showPrintLabelDialog}
        onClose={handleClosePrintLabelClick}
        aria-label="Print Label"
      >
        <DialogTitle>Print Label</DialogTitle>
        <DialogContent>
          {/* <pre>{JSON.stringify(orderItem, null, 2)}</pre> */}
          {orderItem?.hasVariation ? (
            <>
              {/* <pre>{JSON.stringify(orderItem, null, 2)}</pre> */}
              <Box display="flex" alignItems="center" gridGap={20}>
                <Box>
                  {orderItem?.variations.map((variation) => {
                    return (
                      <Box marginBottom="20px">
                        <TextField
                          label="Barcode"
                          variant="outlined"
                          size="small"
                          value={variation?.barcode}
                          disabled={true}
                        />
                      </Box>
                    )
                  })}
                </Box>
                <TextField
                  variant="outlined"
                  type="number"
                  label="Quantity"
                  size="small"
                  // value={orderItem?.quantity}
                  defaultValue={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Box display="flex" alignItems="center" gridGap={20}>
                <TextField
                  label="Barcode"
                  variant="outlined"
                  size="small"
                  value={orderItem?.barcode}
                  disabled={true}
                />
                <TextField
                  variant="outlined"
                  type="number"
                  label="Quantity"
                  size="small"
                  // value={orderItem?.quantity}
                  defaultValue={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleClosePrintLabelClick}>
            <IconCancel />
          </Button>
          <MaterialLink
            href={`${API_URL}/api/admin/orders/items/print-label/${orderItem?.id}?quantity=${quantity}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button label="Print" variant="outlined" />
          </MaterialLink>
        </DialogActions>
      </Dialog>
    </>
  )
}

const OrderItemVariation = (props: any) => {
  const { values } = useFormState()

  const { data, loading, error } = useGetOne(
    "products/products/variation",
    values?.product?.id
  )

  return (
    <FieldArray name="variations">
      {({ fields }) => (
        <>
          {values.hasVariation && (
            <Typography variant="subtitle1" gutterBottom>
              Variations
            </Typography>
          )}
          {fields.map((name, index) => (
            <div key={index}>
              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Variation #{index + 1}
                  </Typography>
                </Box>
                <Box flex={1} style={{ textAlign: "right" }}>
                  <IconButton
                    type="button"
                    onClick={() => fields.remove(index)}
                  >
                    <DeleteIcon style={{ color: "red" }} />
                  </IconButton>
                </Box>
              </Box>
              {/* {variationTitle[index]} */}
              <SelectInput
                source={`${name}.combination`}
                choices={data?.combination.map((comb) => ({
                  id: comb._id,
                  name: comb.config,
                }))}
                label="Combination"
                fullWidth
              />
              <Box display="flex">
                <Box flex={1}>
                  <NumberInput
                    label="quantity"
                    source={`${name}.quantity`}
                    fullWidth
                  />
                </Box>
                <Box width="1em" />
                <Box flex={1}>
                  <NumberInput
                    label="discount"
                    source={`${name}.discount`}
                    disabled
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display="flex">
                <Box flex={1}>
                  <SelectInput
                    label="Status"
                    source={`${name}.status`}
                    disabled={true}
                    choices={[
                      { id: "pending", name: "New Orders" },
                      { id: "confirmed", name: "Confirmed" },
                      { id: "approve", name: "Approve" },
                      { id: "pickup", name: "Pickup" },
                      { id: "delivering", name: "Delivering" },
                      { id: "delivered", name: "Delivered" },
                      { id: "cancelled", name: "Cancelled" },
                      { id: "defect", name: "Defect" },
                      { id: "refund", name: "Refund" },
                    ]}
                    fullWidth
                  />
                </Box>
                <Box width="1em"></Box>
                <Box flex={1}>
                  <NumberInput
                    label="Special Discount"
                    source={`${name}.specialDiscount`}
                    disabled
                    fullWidth
                  />
                </Box>
              </Box>
            </div>
          ))}
          <MuiButton
            onClick={() =>
              fields.push({
                combination: "",
                quantity: 0,
              })
            }
          >
            <AddIcon /> Add Combination
          </MuiButton>
        </>
      )}
    </FieldArray>
  )
}

export default Basket
