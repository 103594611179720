import * as React from "react"
import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  EditProps,
  FieldProps,
  FunctionField,
  Link,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { useFormState } from "react-final-form"

import { makeStyles, Typography } from "@material-ui/core"

import { requiredValidate, SectionTitle } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  title: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const TagTitle = ({ record }: any) => (
  <span>Edit Tag {record ? `"${record.title}"` : `#${record.id}`}</span>
)

const MyImageField = ({ record }: any) => {
  return (
    <div>
      <img
        src={
          record.media && record.media.length > 0
            ? `${process.env.REACT_APP_AWS_BUCKET_URL}${record.media[0]}`
            : ""
        }
        height={75}
        width="auto"
      />
    </div>
  )
}

const ProductList = (props: FieldProps) => {
  const { values } = useFormState()
  return (
    <>
      <Typography variant="h6">Products</Typography>
      <List
        title={<span></span>}
        hasCreate={false}
        basePath="/products/products"
        resource="products/products"
        filter={{ tags: [values.title] }}
        sort={{ field: "updatedAt", order: "DESC" }}
        exporter={false}
        actions={false}
        perPage={15}
        component="div"
        bulkActionButtons={false}
        className=""
      >
        <Datagrid>
          <FunctionField
            label="Id"
            render={(record: any) => {
              const recordId = record?.id
                .toString()
                .slice(record.id.toString().length - 5)

              return <Link to={`/orders/orders/${record.id}`}>{recordId}</Link>
            }}
          />
          <MyImageField source="media" />
          <TextField source="title" />
          <DateField source="updatedAt" showTime />
          <EditButton />
        </Datagrid>
      </List>
    </>
  )
}

export const TagEdit: React.FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<TagTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.tags.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <ProductList />
      </SimpleForm>
    </Edit>
  )
}
