import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanInput,
  FilterProps,
  EmailField,
  NumberField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  FunctionField,
  BulkDeleteButton,
} from "react-admin"
import { useMediaQuery, Theme } from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"
import { makeStyles } from "@material-ui/core"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import { ListProps } from "@material-ui/core"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"
import Aside from "./Aside"

const styles = {
  true: { color: green[500] },
  false: { color: red[500] },
}

const useStyles = makeStyles(styles)

const UserFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    {/* <ReferenceInput
      source="role"
      reference="users/roles"
      filter={{ "meta.isDeleted": false }}
    >
      <SelectInput optionText="name" optionValue="name" />
    </ReferenceInput>
    <BooleanInput label="Status" source="status" />
    <BooleanInput label="Activated" source="isActivated" /> */}
  </Filter>
)

const userFilters = (isSmall: any) => {
  const filters = [<SearchInput source="q" alwaysOn />]

  if (isSmall) {
    filters.push(
      <ReferenceInput
        source="role"
        reference="users/roles"
        filter={{ "meta.isDeleted": false }}
      >
        <SelectInput optionText="name" optionValue="name" />
      </ReferenceInput>
    )
    filters.push(<BooleanInput label="Status" source="status" />)
    filters.push(<BooleanInput label="Activated" source="isActivated" />)
  }

  return filters
}

const DeleteActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <DeletePermanentlyButton
          label="Delete user permanently"
          resourceLabel="User"
          {...props}
        />
      )}
    </>
  )
}

export const UserList: FC<ListProps> = (props) => {
  const classes = useStyles()
  const [deletefilter, setdeletefilter] = React.useState(false)
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filters={userFilters(isSmall)}
      filter={{ "meta.isDeleted": deletefilter }}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<DeleteActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
      aside={<Aside />}
      perPage={10}
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="name" />
        <NumberField source="phone" />
        <ReferenceArrayField source="roles" reference="users/roles">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Activated"
          source="isActivated"
          render={(record) =>
            record.isActivated ? (
              <CheckCircleIcon fontSize="small" className={classes.true} />
            ) : (
              <CancelIcon fontSize="small" className={classes.false} />
            )
          }
        />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
