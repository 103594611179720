import React, { useState } from "react"
import {
  Button,
  FormWithRedirect,
  SaveButton,
  TextInput,
  useNotify,
  useUpdate,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"
import { orange } from "@material-ui/core/colors"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "mui-rff"
import { Box, MenuItem, Typography } from "@material-ui/core"
import ShareIcon from "@material-ui/icons/Share"
import SocialShare from "../../components/SocialShare"

function ShareVatBillButton({ record }) {
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const url = `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : process.env.REACT_APP_BASE_URL
  }/api/admin/orders/vatbill/${record?.id}`

  const [update, { loading }] = useUpdate()
  const notify = useNotify()

  const handleSubmit = async (values) => {
    update(
      `orders/orders/share/vatbill`,
      record!.id,
      values,
      {},
      {
        onSuccess: () => {
          setShowDialog(false)
          notify("Pdf shared successfully.")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Vat Bill"
        variant="outlined"
        // style={{ color: orange[500] }}
      >
        <ShareIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Share"
      >
        <DialogTitle>Share Vat Bill</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <Box display="flex" gridGap={4} mb={2}>
                  <SocialShare title="Vat Bill" url={url} size={32} />
                </Box>
                <Typography variant="body1">Mail to: </Typography>
                <TextInput
                  source="recipient"
                  defaultValue={record?.user?.email}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                  variant="outlined"
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  label="Share"
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default ShareVatBillButton
