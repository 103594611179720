import { FC } from "react"
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  Link,
  List,
  ListProps,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin"

import GetAppIcon from "@material-ui/icons/GetApp"
import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"

import { IDField } from "../../../common/form"
import OrderItemV1Show from "./OrderItemV1Show"
import AssignPickupBoyButton from "./AssignPickupBoyButton"
import ShareButton from "./ShareButton"
import SocialShare from "../../../components/SocialShare"

const rowStyle = (record: any) => {
  if (record?.items[0]?.pickupBoy) {
    return {
      backgroundColor: green[50],
    }
  } else {
    return {
      backgroundColor: red[50],
    }
  }
}

const LinkField = ({ source, field, reference, value, label }: any) => (
  <FunctionField
    source={source}
    label={label}
    render={(record) =>
      record &&
      record[field] && (
        <Link to={`/${reference}/${record[field].id}`}>
          {record[field][value]}
        </Link>
      )
    }
  />
)

const OrderItemFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendorId"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      sort={{ field: "name", order: "ASC" }}
      alwaysOn
      emptyText="All Vendors"
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        { id: "pending", name: "New Orders" },
        { id: "confirmed", name: "Confirmed" },
        { id: "approve", name: "Approve" },
        { id: "pickup", name: "Pickup" },
        { id: "delivering", name: "Delivering" },
        { id: "delivered", name: "Delivered" },
        { id: "cancelled", name: "Cancelled" },
        { id: "defect", name: "Defect" },
        { id: "refund", name: "Refund" },
      ]}
    />
  </Filter>
)

const PrintPdfButton: FC<FieldProps> = (props) => {
  if (!props.record) return null
  const vendor = props.record?.vendor

  return (
    <Button
      label="PDF"
      href={`${
        process.env.NODE_ENV === "development" ? "http://localhost:4000" : ""
      }/api/admin/orders/items/v1/pdf/${vendor?.id}`}
    >
      <GetAppIcon />
    </Button>
  )
}

const SocialShareButton: FC<FieldProps> = (props) => {
  if (!props.record) return null

  const vendor = props.record?.vendor

  const url = `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000"
      : process.env.REACT_APP_BASE_URL
  }/api/admin/orders/items/v1/pdf/${vendor?.id}`

  return <SocialShare title="Order Item List" url={url} />
}

const OrderItemV1Pagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const OrderItemV1List: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<OrderItemV1Pagination />}
      filters={<OrderItemFilter />}
    >
      <Datagrid rowStyle={rowStyle} expand={<OrderItemV1Show />}>
        <IDField source="id" />
        <LinkField
          source="vendor.name"
          label="Vendor"
          field="vendor"
          reference="users/users"
          value="name"
        />
        <FunctionField
          source="items"
          label="Items Count"
          render={(record) =>
            record && record?.items?.length && record?.items?.length.toString()
          }
        />
        <TextField source="vendor.address" label="Address" />
        <FunctionField
          label="Total price"
          render={(record) =>
            record.totalPrice ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.totalPrice.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : null
          }
        />
        <FunctionField
          label="Vendor Payable"
          render={(record) =>
            record.totalVendorPayablePrice ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.totalVendorPayablePrice.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : null
          }
        />
        <FunctionField
          label="Margin"
          render={(record) =>
            record.totalPrice && record.totalVendorPayablePrice ? (
              <span style={{ fontWeight: "bold" }}>
                {(
                  ((record.totalPrice - record.totalVendorPayablePrice) * 100) /
                  record.totalPrice
                ).toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                &nbsp;%
              </span>
            ) : null
          }
        />
        <DateField source="createdAt" showTime />
        <AssignPickupBoyButton label="Pickup Boy" />
        <ShareButton />
        <PrintPdfButton />
      </Datagrid>
    </List>
  )
}

export { OrderItemV1List }
