import * as React from "react"
import { FC, useState, useCallback, useEffect } from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  Filter,
  FilterProps,
  ListProps,
  NumberField,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  useGetList,
  DatagridProps,
  useListContext,
  Identifier,
  ListContextProvider,
  Pagination,
  AutocompleteInput,
  BooleanField,
} from "react-admin"
import { Divider, Tab, Tabs } from "@material-ui/core"

import { IDField } from "../../common/form"
import AssignPickupBoyButton from "./AssignPickupBoyButton"
import RefundPaymentButton from "./RefundPaymentButton"

const condition = (val: string) => !!val && val.trim().length > 5

const OrderItemFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendorId"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      filterToQuery={(val: string) =>
        condition(val) ? { name: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>
  </Filter>
)

const OrderItemPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const AssignPickupBoyActionButtons = (props) => {
  return (
    <>
      <AssignPickupBoyButton lable="Assign Pickup Boy" {...props} />
      {props?.filterValues?.status === "refund" && (
        <RefundPaymentButton label="Paid to Admin" {...props} />
      )}
    </>
  )
}

const tabs = [
  { id: "pending", name: "New Orders" },
  { id: "confirmed", name: "Confirmed" },
  { id: "approve", name: "Approve" },
  { id: "pickup", name: "Pickup" },
  { id: "delivering", name: "Delivering" },
  { id: "delivered", name: "Delivered" },
  { id: "cancelled", name: "Cancelled" },
  { id: "defect", name: "Defect" },
  { id: "refund", name: "Refund" },
]

interface TabbedDatagridProps extends DatagridProps {}

const useGetTotals = (filterValues: any) => {
  const { total: totalPending } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "pending" }
  )
  const { total: totalConfirmed } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "confirmed" }
  )
  const { total: totalApprove } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "approve" }
  )
  const { total: totalPickup } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "pickup" }
  )
  const { total: totalDelivering } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "delivering" }
  )
  const { total: totalDelivered } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "delivered" }
  )
  const { total: totalCancelled } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "cancelled" }
  )
  const { total: totalDefect } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "defect" }
  )
  const { total: totalRefund } = useGetList(
    "orders/items",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, status: "refund" }
  )

  return {
    pending: totalPending,
    confirmed: totalConfirmed,
    approve: totalApprove,
    pickup: totalPickup,
    delivering: totalDelivering,
    delivered: totalDelivered,
    cancelled: totalCancelled,
    defect: totalDefect,
    refund: totalRefund,
  }
}

const TabbedDataGrid: FC<TabbedDatagridProps> = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [pending, setPending] = useState<Identifier[]>([] as Identifier[])
  const [confirmed, setConfirmed] = useState<Identifier[]>([] as Identifier[])
  const [approve, setApprove] = useState<Identifier[]>([] as Identifier[])
  const [pickup, setPickup] = useState<Identifier[]>([] as Identifier[])
  const [delivering, setDelivering] = useState<Identifier[]>([] as Identifier[])
  const [delivered, setDelivered] = useState<Identifier[]>([] as Identifier[])
  const [cancelled, setCancelled] = useState<Identifier[]>([] as Identifier[])
  const [defect, setDefect] = useState<Identifier[]>([] as Identifier[])
  const [refund, setRefund] = useState<Identifier[]>([] as Identifier[])

  const totals = useGetTotals(filterValues) as any

  console.log(totals)

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case "pending":
          setPending(ids)
          break
        case "confirmed":
          setConfirmed(ids)
          break
        case "approve":
          setApprove(ids)
          break
        case "pickup":
          setPickup(ids)
          break
        case "delivering":
          setDelivering(ids)
          break
        case "delivered":
          setDelivered(ids)
          break
        case "cancelled":
          setCancelled(ids)
          break
        case "defect":
          setDefect(ids)
          break
        case "refund":
          setRefund(ids)
          break
      }
    }
  }, [ids, filterValues.status])

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  // For mobile view
  const selectedIds =
    filterValues.status === "pending"
      ? pending
      : filterValues.status === "approve"
      ? approve
      : filterValues.status === "pickup"
      ? pickup
      : filterValues.status === "delivering"
      ? delivering
      : filterValues.status === "delivered"
      ? delivered
      : filterValues.status === "cancelled"
      ? cancelled
      : filterValues.status === "defect"
      ? defect
      : refund

  return (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.id]
                ? `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {filterValues.status === "pending" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "confirmed" && (
          <ListContextProvider value={{ ...listContext, ids: confirmed }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "approve" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "pickup" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "delivering" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "delivered" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "cancelled" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "defect" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}

        {filterValues.status === "refund" && (
          <ListContextProvider value={{ ...listContext, ids: pending }}>
            <Datagrid {...props} optimized>
              <IDField sortable={false} source="id" />
              <DateField label="Placed at" source="createdAt" showTime />
              <ReferenceField source="product" reference="products/products">
                <TextField source="title" />
              </ReferenceField>
              <ReferenceField source="vendorId" reference="users/users">
                <TextField source="name" />
              </ReferenceField>
              <BooleanField
                label="Paid to Admin"
                source="refundPaymentStatus.status"
              />
              <ReferenceField
                label="Pickup Boy"
                source="pickupBoyId"
                reference="users/users"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" />
              <TextField source="shippingClass" />
              <NumberField source="price" />
              <EditButton />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </>
  )
}

export const OrderItemList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<OrderItemFilter />}
      bulkActionButtons={<AssignPickupBoyActionButtons />}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={25}
      pagination={<OrderItemPagination />}
    >
      <TabbedDataGrid />
    </List>
  )
}
