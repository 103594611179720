import CategoryIcon from "@material-ui/icons/Category"
import { CategoryCMSCreate } from "./CategoryCMSCreate"
import { CategoryCMSEdit } from "./CategoryCMSEdit"
import { CategoryCMSList } from "./CategoryCMSList"

export default {
  list: CategoryCMSList,
  create: CategoryCMSCreate,
  edit: CategoryCMSEdit,
  icon: CategoryIcon,
}
