import * as React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { useShowController, ReferenceField } from "react-admin"

import MiniBasket from "./MiniBasket"

const useStyles = makeStyles({
  root: { width: 800, margin: "auto" },
  spacer: { height: 20 },
  invoices: { margin: "10px 0" },
})

const OrderShow = (props: any) => {
  const { record } = useShowController(props)
  const classes = useStyles()

  if (!record) return null
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Bhotahiti Invoice
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="right">
              Order #{record.id}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignContent="flex-end">
            <Typography gutterBottom>
              {record.shippingDetails}
              <br />
              {record.landmark}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom align="center">
              Date{" "}
            </Typography>
            <Typography gutterBottom align="center">
              {new Date(record.createdAt).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant="h6" gutterBottom align="center">
              Order Number
            </Typography>
            <Typography gutterBottom align="center">
              {record.orderNumber}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.invoices}>
          <ReferenceField
            resource="orders/orders"
            reference="orders/orders"
            source="id"
            basePath="/orders/orders"
            record={record}
            link={false}
          >
            <MiniBasket />
          </ReferenceField>
        </div>
        <Typography>
          Delivery Fees: रू{" "}
          {record?.shippingCost?.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default OrderShow
