import * as React from "react"
import { useTranslate } from "react-admin"
import Typography from "@material-ui/core/Typography"

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  )
}

export default SectionTitle
