import BlockIcon from "@material-ui/icons/Block"
import { PermissionCreate } from "./PermissionCreate"
import { PermissionEdit } from "./PermissionEdit"
import { PermissionList } from "./PermissionList"

export default {
  list: PermissionList,
  create: PermissionCreate,
  edit: PermissionEdit,
  icon: BlockIcon,
}
