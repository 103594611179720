import * as React from "react"
import { FC } from "react"

import CardWithIcon from "./CardWithIcon"

interface Props {
  value?: string
  icon?: any
  title: string
  style?: object
  to?: string
}

const MiniCard: FC<Props> = ({ value, icon, title, style, to = "/" }) => {
  return (
    <CardWithIcon
      to={to}
      icon={icon}
      title={title}
      subtitle={value}
      style={style}
    />
  )
}

export default MiniCard
