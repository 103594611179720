import { FC } from "react"
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  Link as ReactAdminLink,
  List,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin"

import { Link, Typography } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"

const OrderFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <NumberInput source="trackingNumber" alwaysOn />
    <ReferenceInput
      source="userShippingAreaId"
      reference="shippings/areas"
      emptyText="All areas"
      alwaysOn
    >
      <AutocompleteInput />
    </ReferenceInput>
    <SelectInput
      source="paymentType"
      choices={[
        { id: "cod", name: "Cash on Delivery" },
        { id: "ips", name: "IPS" },
        { id: "esewa", name: "E-sewa" },
        { id: "visa", name: "VISA" },
        { id: "fonePay", name: "fonePay" },
        { id: "khalti", name: "Khalti" },
        { id: "bankTransfer", name: "Bank Transfer" },
      ]}
      emptyText="All types"
      alwaysOn
    />
    <ReferenceInput
      source="userId"
      reference="users/users"
      emptyText="All Users"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const OrderPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
)

const OrderLinkField = (props: FieldProps) => {
  const { record } = props
  if (!record) return null
  return (
    <ReactAdminLink to={`/orders/orders/${record?.id}`}>
      {record.trackingNumber}
    </ReactAdminLink>
  )
}

const AccountingCancelledOrders = () => {
  return (
    <div>
      <br />
      <Typography variant="caption">
        All cancelled orders of user are listed here.
      </Typography>
      <List
        title="Sales"
        resource="orders/orders"
        basePath="orders/orders"
        perPage={25}
        sort={{ field: "createdAt", order: "DESC" }}
        filter={{ orderStatus: "cancelled" }}
        // exporter={exporter}
        pagination={<OrderPagination />}
        filters={<OrderFilter />}
        // actions={<ListActions />}
        // bulkActionButtons={false}
        hasCreate={false}
      >
        <Datagrid>
          <DateField source="createdAt" showTime />
          <OrderLinkField source="trackingNumber" />
          <FunctionField
            label="User"
            render={(record) =>
              record &&
              record.user && (
                <ReactAdminLink to={`/users/users/${record.user.id}`}>
                  {record.user.name}
                </ReactAdminLink>
              )
            }
          />
          <TextField label="Area" source="area.name" />
          <TextField source="" />
          <TextField source="cancellationReason" />
          <FunctionField
            label="Total Price"
            sortBy="totalPrice"
            render={(record) =>
              record.totalPrice ? (
                <span style={{ fontWeight: "bold" }}>
                  रू{" "}
                  {(record.totalPrice + record.shippingCost).toLocaleString(
                    "en-IN",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </span>
              ) : null
            }
          />
        </Datagrid>
      </List>
    </div>
  )
}

export default AccountingCancelledOrders
