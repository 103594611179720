import ProductList from "./ProductList"
import CollectionsIcon from "@material-ui/icons/Collections"
import ProductCreate from "./ProductCreate"
import ProductEdit from "./ProductEdit"

export default {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  icon: CollectionsIcon,
}
