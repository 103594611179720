import { Chip } from "@material-ui/core"
import {
  amber,
  blue,
  cyan,
  deepOrange,
  green,
  indigo,
  orange,
  purple,
  red,
  yellow,
} from "@material-ui/core/colors"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import DoneIcon from "@material-ui/icons/Done"
import DoneAllIcon from "@material-ui/icons/DoneAll"
import ListIcon from "@material-ui/icons/List"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import MoodBadIcon from "@material-ui/icons/MoodBad"
import RedoIcon from "@material-ui/icons/Redo"
import TouchAppIcon from "@material-ui/icons/TouchApp"
import PanToolIcon from "@material-ui/icons/PanTool"

export const orderStatus = [
  {
    id: "pending",
    name: "New Orders",
    color: amber[100],
    icon: <ListIcon style={{ color: amber[800] }} />,
  },
  {
    id: "confirmed",
    name: "Confirmed",
    color: indigo[50],
    icon: <DoneIcon style={{ color: indigo[500] }} />,
  },
  {
    id: "approve",
    name: "Approve",
    color: blue[100],
    icon: <DoneAllIcon style={{ color: blue[500] }} />,
  },
  {
    id: "pickup",
    name: "Pickup",
    color: cyan[50],
    icon: <CheckCircleOutlineIcon style={{ color: cyan[500] }} />,
  },
  {
    id: "delivering",
    name: "Delivering",
    color: purple[100],
    icon: <LocalShippingIcon style={{ color: purple[500] }} />,
  },
  {
    id: "notReceived",
    name: "Not Received",
    color: orange[100],
    icon: <PanToolIcon style={{ color: orange[500] }} />,
  },
  {
    id: "needReschedule",
    name: "Need Reschedule",
    color: red[100],
    icon: <LocalShippingIcon style={{ color: red[500] }} />,
  },
  {
    id: "delivered",
    name: "Delivered",
    color: green[100],
    icon: <CheckCircleIcon style={{ color: green[500] }} />,
  },
  {
    id: "cancelled",
    name: "Cancelled",
    color: red[100],
    icon: <CancelIcon style={{ color: red[500] }} />,
  },
  {
    id: "outOfStock",
    name: "Out of Stock",
    color: orange[100],
    icon: <MoodBadIcon style={{ color: orange[500] }} />,
  },
  {
    id: "defect",
    name: "Defect",
    color: deepOrange[50],
    icon: <MoodBadIcon style={{ color: deepOrange[500] }} />,
  },
  {
    id: "refund",
    name: "Refund",
    color: yellow[50],
    icon: <RedoIcon style={{ color: yellow[800] }} />,
  },
]

export const paymentTypes = [
  { id: "cod", name: "Cash on Delivery" },
  { id: "ips", name: "IPS" },
  { id: "esewa", name: "E-sewa" },
  { id: "visa", name: "VISA" },
  { id: "khalti", name: "Khalti" },
  { id: "bankTransfer", name: "Bank Transfer" },
  { id: "fonePay", name: "Fone Pay" },
]

export const paymentStatus = [
  {
    id: "pending",
    name: "Pending",
    color: orange[100],
    icon: <TouchAppIcon />,
  },
  { id: "failed", name: "Failed", color: red[100], icon: <CancelIcon /> },
  { id: "success", name: "Success", color: green[100], icon: <DoneIcon /> },
]

export const paymentToAdmin = [
  { id: true, name: "Yes", color: green[100], icon: <CheckCircleIcon /> },
  { id: false, name: "No", color: red[100], icon: <CancelIcon /> },
]

export const optionRenderer = (choice: {
  name: string
  color: string
  icon?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
}) => (
  <Chip
    label={choice.name}
    style={{ backgroundColor: choice.color }}
    icon={choice.icon}
  />
)
