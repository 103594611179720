import React, { useState } from "react"
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useUpdateMany,
  useRefresh,
  useUnselectAll,
  ReferenceInput,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"
import { orange } from "@material-ui/core/colors"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "mui-rff"
import { MenuItem } from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"

function AssignDeliveryBoyButton({ selectedIds }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [updateMany, { loading }] = useUpdateMany("orders/orders")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    updateMany(
      "orders/orders",
      selectedIds,
      { ...values },
      {
        onSuccess: () => {
          setShowDialog(false)
          refresh()
          notify("Order updated")
          unselectAll("orders/orders")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Update Delivery Boy"
        style={{ color: orange[500] }}
      >
        <CreateIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Update Delivery up Boy</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  source="deliveryBoy"
                  reference="users/users"
                  filter={{ role: "DELIVERYBOY" }}
                  sort={{ field: "name", order: "ASC" }}
                  perPage={1000}
                >
                  {/* @ts-ignore */}
                  <DeliveryBoySelectInput />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

const DeliveryBoySelectInput = ({ choices }) => {
  if (!choices) return <span>loading</span>
  return (
    <TextField
      name="deliveryBoy"
      label="Delivery Boy"
      variant="filled"
      size="small"
      select
    >
      {choices.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default AssignDeliveryBoyButton
