import green from "@material-ui/core/colors/green"
import red from "@material-ui/core/colors/red"

const rowStyle = (record: any) => {
  let style = {}
  if (!record) {
    return style
  }
  if (record.outOfStock === false)
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.outOfStock === true)
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  return style
}

export default rowStyle
