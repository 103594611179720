import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  ImageInput,
  ImageField,
  CreateProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateInput,
  NumberInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  placeholder: { width: 544 },
  brands: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const condition = (val: string) => !!val && val.trim().length > 5

export const BrandCMSCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "cms/brands",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`BrandCMS saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.brands.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <NumberInput
          source="placeholder"
          min={1}
          fullWidth
          formClassName={classes.placeholder}
        />
        <ReferenceArrayInput
          source="brands"
          reference="products/brands"
          sort={{ field: "title", order: "ASC" }}
          perPage={100}
          filterToQuery={(val: string) =>
            condition(val) ? { name: val.trim() } : {}
          }
          fullWidth
          formClassName={classes.brands}
        >
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <DateInput source="expiresAt" />
      </SimpleForm>
    </Create>
  )
}
