import CollectionsIcon from "@material-ui/icons/Collections"
import { ProductCMSCreate } from "./ProductCMSCreate"
import { ProductCMSEdit } from "./ProductCMSEdit"
import { ProductCMSList } from "./ProductCMSList"

export default {
  list: ProductCMSList,
  create: ProductCMSCreate,
  edit: ProductCMSEdit,
  icon: CollectionsIcon,
}
