import * as React from "react"
import PropTypes from "prop-types"
import { DateField, NumberField, TextField, useTranslate } from "react-admin"
import {
  Typography,
  Card,
  CardContent,
  Box,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import { Record, useGetList } from "react-admin"
import OrderIcon from "@material-ui/icons/AttachMoney"
import AccessTimeIcon from "@material-ui/icons/AccessTime"

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    marginRight: theme.spacing(2),
  },
}))

interface AsideProps {
  record?: Record
  basePath?: string
}

const UserAside = ({ record, basePath }: AsideProps) => {
  const classes = useAsideStyles()
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
    </div>
  )
}

UserAside.prototypes = {
  record: PropTypes.any,
  basePath: PropTypes.string,
}

interface EventListProps {
  record?: Record
  basePath?: string
}

const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
})

const EventList = ({ record, basePath }: EventListProps) => {
  const classes = useEventStyles()

  const { data: orders, ids: orderIds } = useGetList(
    "orders/orders",
    { page: 1, perPage: 12 },
    { field: "updatedAt", order: "DESC" },
    { userId: record && record.id }
  )

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              History
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Created At</Typography>
                    <DateField record={record} source="createdAt" />
                  </Box>
                </Box>
                {orderIds && orderIds.length > 0 && (
                  <Box display="flex">
                    <Box mr="1em">
                      <OrderIcon fontSize="small" color="disabled" />
                    </Box>
                    <Box flexGrow={1}>
                      <Typography>{orderIds.length} Orders</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Updated At</Typography>
                    <DateField record={record} source="updatedAt" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Stepper orientation="vertical" className={classes.stepper}>
        {orderIds.map((id) => (
          <Step key={id} expanded active completed>
            <StepLabel icon={<OrderIcon color="disabled" />} color="disabled">
              {new Date(orders[id].createdAt).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </StepLabel>
            <StepContent>
              <Order record={orders[id]} basePath={basePath} />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  )
}

interface OrderProps {
  record?: any
  basePath?: string
}

const Order = ({ record, basePath }: OrderProps) => {
  return record ? (
    <>
      <Typography variant="body2" gutterBottom>
        <Link to={`/orders/orders/${record.id}`} component={RouterLink}>
          Order #{record.trackingNumber}
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {record?.items?.length} {record?.items?.length == 1 ? "item" : "items"}{" "}
        &nbsp;-&nbsp;
        <NumberField
          source="totalPrice"
          options={{
            style: "currency",
            currency: "USD",
          }}
          record={record}
          basePath={basePath}
        />
        &nbsp;-&nbsp;
        <TextField source="orderStatus" record={record} basePath={basePath} />
      </Typography>
    </>
  ) : null
}

export default UserAside
