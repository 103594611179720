import PersonIcon from "@material-ui/icons/Person"
import { UserCreate } from "./UserCreate"
import { UserEdit } from "./UserEdit"
import { UserList } from "./UserList"

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: PersonIcon,
}
