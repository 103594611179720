import * as React from "react"
import { Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined"
import { FilterList, FilterListItem } from "react-admin"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { CheckCircleOutline } from "@material-ui/icons"
import {
  optionRenderer,
  paymentRequest,
  paymentStatus,
  saleType,
} from "./common"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "18em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const SaleAside = ({ totals }: any) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <FilterList
          label="resources.sales.filters.paymentRequest"
          icon={<AttachMoneyIcon />}
        >
          {paymentRequest.map((status, index) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={index}
              value={{ "paymentRequest.status": status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.sales.filters.paymentToVendor"
          icon={<AttachMoneyIcon />}
        >
          {paymentStatus.map((status, index) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={index}
              value={{ "paymentStatus.status": status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.sales.filters.refundPaymentStatus"
          icon={<AttachMoneyIcon />}
        >
          {paymentStatus.map((status, index) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={index}
              value={{ "refundPaymentStatus.status": status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.sales.filters.refundFromVendorStatus"
          icon={<AttachMoneyIcon />}
        >
          {paymentStatus.map((status, index) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={index}
              value={{ "refundFromVendorStatus.status": status.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.sales.filters.saleType"
          icon={<AttachMoneyIcon />}
        >
          {saleType.map((status, index) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={index}
              value={{ type: status.id }}
            />
          ))}
        </FilterList>
        {/* <FilterList
          label="resources.orders.filters.paymentType"
          icon={<LocalOfferIcon />}
        >
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              label={paymentType.name}
              key={paymentType.id}
              value={{ paymentType: paymentType.id }}
            />
          ))}
        </FilterList>
        <FilterList
          label="resources.orders.filters.paymentToAdmin"
          icon={<LocalOfferIcon />}
        >
          {paymentToAdmin.map((status) => (
            <FilterListItem
              label={optionRenderer(status)}
              key={status.name}
              value={{ "paymentToAdmin.status": status.id }}
            />
          ))}
        </FilterList> */}
      </CardContent>
    </Card>
  )
}

export default SaleAside
