import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { ShippingEdit } from "./ShippingEdit"
import { ShippingList } from "./ShippingList"
import { ShippingCreate } from "./ShippingCreate"

export default {
  list: ShippingList,
  edit: ShippingEdit,
  create: ShippingCreate,
  icon: LocalShippingIcon,
}
