import { makeStyles, TextField } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { useFormState } from "react-final-form"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  BooleanInput,
  SelectInput,
  minValue,
  DateTimeInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  required,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  roles: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  couponType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  startsAt: { display: "inline-block" },
  expiresAt: { display: "inline-block", marginLeft: 32 },
  minExpense: { display: "inline-block" },
  maxExpense: { display: "inline-block", marginLeft: 32 },
  total: { display: "inline-block" },
  perUserLimit: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const SpecialDiscountTitle = ({ record }: any) => (
  <span>
    Edit Special Discount {record ? `"${record.title}"` : `#${record.id}`}
  </span>
)

interface DiscountOnFieldsInputProps {}

const DiscountOnFieldsInput: FC<DiscountOnFieldsInputProps> = () => {
  const classes = useStyles()
  const { values } = useFormState()
  return values.discountOn && values.discountOn === "specific" ? (
    <div style={{ width: 544 }}>
      <SectionTitle label="resources.specialDiscounts.fieldGroups.specialDiscounts" />
      <ReferenceArrayInput
        label="Brands"
        source="brandIds"
        reference="products/brands"
        sort={{ field: "title", order: "ASC" }}
        perPage={100}
        fullWidth
        formClassName={classes.brands}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Categories"
        source="categoryIds"
        reference="products/categories"
        sort={{ field: "title", order: "ASC" }}
        filter={{ entry: true }}
        perPage={100}
        fullWidth
        formClassName={classes.categories}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Products"
        source="productIds"
        reference="products/products"
        sort={{ field: "title", order: "ASC" }}
        perPage={100}
        fullWidth
        formClassName={classes.products}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
    </div>
  ) : null
}

export const SpecialDiscountEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<SpecialDiscountTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.specialDiscounts.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          fullWidth
          formClassName={classes.description}
          multiline
        />
        <NumberInput
          source="value"
          validate={requiredValidate}
          helperText="Special discount percentage"
        />
        <SelectInput
          source="discountOn"
          choices={[
            { id: "all", name: "All products" },
            { id: "specific", name: "Special Products" },
          ]}
          helperText="Select the type of discount"
        />
        <ReferenceArrayInput
          label="Roles"
          source="roleIds"
          reference="users/roles"
          sort={{ field: "name", order: "ASC" }}
          perPage={1000}
          fullWidth
          formClassName={classes.roles}
          helperText="Assign to specific roles"
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <DiscountOnFieldsInput />
      </SimpleForm>
    </Edit>
  )
}
