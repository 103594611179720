import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  NumberInput,
  minValue,
  ReferenceInput,
  ImageInput,
  ImageField,
} from "react-admin"

import { useFormState } from "react-final-form"
import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  email: { display: "inline-block" },
  phone: { display: "inline-block", marginLeft: 32 },
  name: { width: 544 },
  dob: { width: 544 },
  address: { width: 544 },
  roles: { width: 544 },
  userType: { display: "inline-block" },
  PAN: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
  imageField: { width: 544 },
}

const useStyles = makeStyles(styles)

export const UserCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "users/users",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`User saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          err[e.field] = e.message
        }
        return err
      }
    },
    [mutate]
  )

  const condition = (val: string) => !!val && val.trim().length > 5

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.users.fieldGroups.information" />
        <TextInput source="email" formClassName={classes.email} />
        <TextInput source="phone" formClassName={classes.phone} />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <DateInput source="dob" formClassName={classes.dob} />
        <SelectInput
          source="userType"
          choices={[
            { id: "single", name: "Single" },
            { id: "bulk", name: "Bulk" },
          ]}
          formClassName={classes.userType}
        />
        <NumberInput label="PAN" source="PAN" formClassName={classes.PAN} />
        <ImageInput
          source="avatar"
          fullWidth
          formClassName={classes.imageField}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput
          source="panImage"
          fullWidth
          formClassName={classes.imageField}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <SectionTitle label="resources.users.fieldGroups.demographics" />
        <TextInput source="address" formClassName={classes.address} />
        <SectionTitle label="resources.users.fieldGroups.roles" />
        <ReferenceArrayInput
          label="Roles"
          source="roles"
          reference="users/roles"
          sort={{ field: "name", order: "ASC" }}
          perPage={100}
          filterToQuery={(val: string) =>
            condition(val) ? { title: val.trim() } : {}
          }
          fullWidth
          formClassName={classes.roles}
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <NumberInput
          label="Global Margin"
          source="vendorProps.globalMargin"
          helperText="Set if user is vendor."
          validate={[minValue(1)]}
        />
        <ReferenceInput
          source="specialDiscountId"
          reference="products/specialDiscounts"
        >
          <SelectInput
            optionText="title"
            optionValue="id"
            helperText="Assign special discount schema."
          />
        </ReferenceInput>
        <SectionTitle label="resources.users.fieldGroups.options" />
        <BooleanInput
          label="Suspended Status"
          source="isSuspended.status"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Activated"
          source="isActivated"
          formClassName={classes.boolean}
        />
        <SelectInput
          source="defaultPaymentMethod"
          allowEmpty
          choices={[
            { id: "cod", name: "Cash on Delivery" },
            { id: "ips", name: "IPS" },
            { id: "esewa", name: "E-sewa" },
            { id: "visa", name: "VISA" },
            { id: "fonePay", name: "fonePay" },
            { id: "khalti", name: "Khalti" },
            { id: "bankTransfer", name: "Bank Transfer" },
          ]}
        />
        <TextInput source="companyName" />
        <TextInput source="companyAddress" />
      </SimpleForm>
    </Create>
  )
}
