import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateInput,
  NumberInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  link: { width: 544 },
  position: { width: 544 },
}

const useStyles = makeStyles(styles)

export const TagCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        console.log("Tag Create", )
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/tags",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Tag saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error:any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.tags.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
      </SimpleForm>
    </Create>
  )
}
