import * as React from "react"
import { FC, useCallback, useState, useEffect } from "react"
import {
  Button,
  makeStyles,
  Tabs,
  Tab,
  Card,
  Divider,
  CardContent,
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  minValue,
  useMutation,
  useNotify,
  useRefresh,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Link,
  NumberField,
  PasswordInput,
  ImageInput,
  useEditController,
  DateField,
  EditButton,
  FormWithRedirect,
  Toolbar,
  InputProps,
  useGetList,
  useGetOne,
} from "react-admin"
import { useFormState } from "react-final-form"
// import { GoogleMap, Marker } from "react-google-maps"
import GoogleMapReact from "google-map-react"
import RoomIcon from "@material-ui/icons/Room"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PermIdentityIcon from "@material-ui/icons/PermIdentity"

import { SectionTitle } from "../../common/form"
import UserAside from "./UserAside"
import UserImageField from "./UserImageField"
import UpdateMapButton, { Places } from "./UpdateMapButton"

const AnyReactComponent = ({ text, lat, lng }: any) => (
  <div>
    <RoomIcon color="error" fontSize="large" />
  </div>
)
const styles = {
  id: { display: "inline-block" },
  phone: { display: "inline-block", marginLeft: 32 },
  altPhone: {},
  name: { width: 544 },
  email: { width: 544 },
  dob: { width: 544 },
  address: { width: 544 },
  roles: { width: 544 },
  userType: { display: "inline-block" },
  PAN: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
  imageField: { width: 544 },
  latitude: { display: "inline-block" },
  longitude: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const UserTitle = ({ record }: any) => (
  <span>Edit User {record ? `"${record.name}"` : `#${record.id}`}</span>
)

const tabs = [
  { id: "user", name: "User" },
  { id: "products", name: "Products" },
  { id: "orders", name: "Orders" },
]

const MyImageField = ({ record }: any) => {
  return (
    <div>
      <img
        src={
          record.media && record.media.length > 0
            ? `${process.env.REACT_APP_AWS_BUCKET_URL}${record.media[0]}`
            : ""
        }
        height={75}
        width="auto"
      />
    </div>
  )
}

const MapUpdateButton = (props: any) => {
  const { values } = useFormState()
  return (
    <UpdateMapButton
      userId={values?.id}
      lat={values?.location?.latitude}
      lng={values?.location?.longitude}
    />
  )
}

const UpdateLocation = (props: any) => {
  const { values } = useFormState()
  const [selected, setSelected] = useState<any>({
    lat: values.location?.latitude ?? 27.68804941124061,
    lng: values.location?.longitude ?? 85.31486513947398,
  })

  useEffect(() => {
    values.location.latitude = selected.lat
    values.location.longitude = selected.lng
  }, [selected])

  return (
    <div style={{ position: "relative" }}>
      <Places selected={selected} setSelected={setSelected} />
    </div>
  )
}

const MapField = (props: InputProps) => {
  const { values } = useFormState()

  const [lat, setLat] = useState(values.location?.latitude ?? 27.68804941124061)
  const [lng, setLng] = useState(
    values.location?.longitude ?? 85.31486513947398
  )

  values.location.latitude = lat
  values.location.longitude = lng

  return (
    <div
      style={{
        height: "50vh",
        width: "100%",
        // position: "fixed",
        // left: 0,
        // top: 0,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={{
          lat,
          lng,
        }}
        onClick={(e) => {
          setLat(e.lat)
          setLng(e.lng)
        }}
        defaultZoom={13}
        options={{
          fullscreenControl: false,
        }}
      >
        <AnyReactComponent lat={lat} lng={lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  )
}

export const UserEdit: FC<EditProps> = (props) => {
  const { record } = useEditController(props)
  console.log("Record = ", record)
  const [tab, setTab] = useState("user")
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const [mutate] = useMutation()
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "users/users",
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        )
        notify("User updated successfully.")
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTab(value)
  }

  const condition = (val: string) => !!val && val.trim().length > 5

  const { total: totalProducts } = useGetList(
    "products/products",
    { page: 1, perPage: 2 },
    { field: "updatedAt", order: "DESC" },
    { productBy: props.id }
  )

  const { total: totalOrders } = useGetList(
    "orders/orders",
    { page: 1, perPage: 2 },
    { field: "updatedAt", order: "DESC" },
    { userId: props.id }
  )

  const { data: settings, ids: settingIds } = useGetList(
    "settings/settings",
    { page: 1, perPage: 5 },
    { field: "updatedAt", order: "DESC" },
    { name: "BYPASS_PASSWORD" }
  )

  const BYPASS_PASSWORD = settings[settingIds[0]]?.value

  return (
    <Card>
      <Tabs
        variant="fullWidth"
        value={tab}
        // indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            label={
              tab.id === "products"
                ? `${tab.name} (${totalProducts})`
                : tab.id === "orders"
                ? `${tab.name} (${totalOrders})`
                : tab.name
            }
            value={tab.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {tab === "user" && (
          <Edit
            aside={<UserAside />}
            title={<UserTitle />}
            {...props}
            component="div"
          >
            <FormWithRedirect
              {...props}
              save={save}
              render={(formProps: any) => (
                <Box>
                  <Accordion expanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">Login Client</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Button
                        href={`https://bhotahiti.com/login?phone=${record?.phone}&password=${BYPASS_PASSWORD}`}
                        target="_blank"
                        variant="outlined"
                      >
                        Login <PermIdentityIcon />
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                  <form>
                    <CardContent>
                      <Box display="flex">
                        <Box flex={2} mr="0.5em">
                          <SectionTitle label="resources.users.fieldGroups.information" />
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput disabled source="id" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <TextInput source="phone" fullWidth />
                            </Box>
                          </Box>
                          <Box>
                            <TextInput source="name" fullWidth />
                          </Box>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput source="altPhone" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <TextInput source="email" fullWidth />
                            </Box>
                          </Box>
                          <Box>
                            <ReferenceArrayInput
                              label="Roles"
                              source="roles"
                              reference="users/roles"
                              sort={{ field: "name", order: "ASC" }}
                              perPage={100}
                              filterToQuery={(val: string) =>
                                condition(val) ? { title: val.trim() } : {}
                              }
                              fullWidth
                            >
                              <AutocompleteArrayInput
                                optionValue="id"
                                optionText="name"
                              />
                            </ReferenceArrayInput>
                          </Box>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <PasswordInput source="password" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <DateInput source="dob" fullWidth />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <ImageInput source="avatar" fullWidth>
                                <UserImageField />
                              </ImageInput>
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <ImageInput source="panImage" fullWidth>
                                <UserImageField />
                              </ImageInput>
                            </Box>
                          </Box>

                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <SelectInput
                                allowEmpty
                                emptyText="Select User Type"
                                source="userType"
                                choices={[
                                  { id: "single", name: "Single" },
                                  { id: "bulk", name: "Bulk" },
                                ]}
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <NumberInput label="PAN" source="PAN" fullWidth />
                            </Box>
                          </Box>
                          <Box>
                            <ReferenceInput
                              source="referedById"
                              reference="users/users"
                              fullWidth
                            >
                              <SelectInput optionText="name" optionValue="id" />
                            </ReferenceInput>
                          </Box>

                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput source="companyName" fullWidth />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <TextInput source="companyAddress" fullWidth />
                            </Box>
                          </Box>

                          <SectionTitle label="resources.users.fieldGroups.payment" />
                          <Box>
                            <SelectInput
                              source="defaultPaymentMethod"
                              allowEmpty
                              choices={[
                                { id: "cod", name: "Cash on Delivery" },
                                { id: "ips", name: "IPS" },
                                { id: "esewa", name: "E-sewa" },
                                { id: "visa", name: "VISA" },
                                { id: "fonePay", name: "fonePay" },
                                { id: "khalti", name: "Khalti" },
                                { id: "bankTransfer", name: "Bank Transfer" },
                              ]}
                              fullWidth
                            />
                          </Box>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                label="Bank Name"
                                source="bankDetails.bankName"
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <TextInput
                                label="Account Number"
                                source="bankDetails.accountNumber"
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <TextInput
                                label="Account Holder Name"
                                source="bankDetails.accountHolderName"
                                fullWidth
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <TextInput
                                label="Branch Name"
                                source="bankDetails.branchName"
                                fullWidth
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box flex={1} ml="0.5em">
                          <SectionTitle label="resources.users.fieldGroups.demographics" />
                          <Box>
                            <TextInput source="address" fullWidth />
                          </Box>

                          <Box>{/* <LocationField source="location" /> */}</Box>
                          <Box>
                            <SectionTitle label="resources.users.fieldGroups.vendorProps" />
                            <NumberInput
                              label="Global Margin"
                              source="vendorProps.globalMargin"
                              helperText="Set if user is vendor."
                              validate={[minValue(1)]}
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <ReferenceInput
                              source="specialDiscountId"
                              reference="products/specialDiscounts"
                              allowEmpty
                              emptyValue={null}
                              emptyText="No Special Discount"
                              fullWidth
                            >
                              <SelectInput
                                optionText="title"
                                optionValue="id"
                                helperText="Assign special discount schema."
                              />
                            </ReferenceInput>
                          </Box>
                          <Box>
                            <SectionTitle label="resources.users.fieldGroups.options" />
                            <Box display="flex">
                              <Box>
                                <BooleanInput
                                  label="Suspended Status"
                                  source="isSuspended.status"
                                />
                              </Box>
                              <Box>
                                <BooleanInput
                                  label="Activated"
                                  source="isActivated"
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <SectionTitle label="resources.users.fieldGroups.userPreference" />
                            <Box display="flex">
                              <Box>
                                <BooleanInput
                                  label="Notifications"
                                  source="preferences.isNotificationEnabled"
                                />
                              </Box>
                              <Box>
                                <BooleanInput
                                  label="Email"
                                  source="preferences.isEmailEnabled"
                                />
                              </Box>
                            </Box>
                            <Box>
                              <BooleanInput
                                label="SMS"
                                source="preferences.isSmsEnabled"
                              />
                            </Box>
                          </Box>
                          <SectionTitle label="resources.users.fieldGroups.socialMediaLinks" />
                          <Box>
                            <TextInput
                              label="Facebook"
                              source="socialMediaLinks.facebook"
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextInput
                              label="Email"
                              source="socialMediaLinks.email"
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextInput
                              label="Viber"
                              source="socialMediaLinks.viber"
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <TextInput
                              label="WhatsApp"
                              source="socialMediaLinks.whatsapp"
                              fullWidth
                            />
                          </Box>
                          <SectionTitle label="resources.users.fieldGroups.faveCategories" />
                          <Box>
                            <ReferenceArrayInput
                              source="faveCategories"
                              reference="products/categories"
                            >
                              <AutocompleteArrayInput optionText="title" />
                            </ReferenceArrayInput>
                          </Box>
                        </Box>
                      </Box>
                      {/* <MapUpdateButton /> */}
                      {/* <MapField source="location" /> */}
                      <SectionTitle label="resources.users.fieldGroups.map" />
                      <Box display="flex">
                        <Box display="flex" flex={4} gridGap={32}>
                          <Box>
                            <NumberInput
                              label="Latitude"
                              source="location.latitude"
                              fullWidth
                            />
                          </Box>
                          <Box>
                            <NumberInput
                              label="Longitude"
                              source="location.longitude"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box flex={2}></Box>
                      </Box>
                      <UpdateLocation source="location" />
                    </CardContent>
                    <Toolbar
                      record={formProps.record}
                      basePath={formProps.basePath}
                      undoable={true}
                      invalid={formProps.invalid}
                      handleSubmit={formProps.handleSubmit}
                      saving={formProps.saving}
                      resource="users"
                    />
                  </form>
                </Box>
              )}
            />
            {/* <SimpleForm save={save}>
                 
              
        
          
           
              
            
            </SimpleForm> */}
          </Edit>
        )}
        {tab === "products" && (
          <List
            title={<span />}
            hasCreate={false}
            basePath="/products/products"
            resource="products/products"
            filter={{ productBy: props.id }}
            sort={{ field: "updatedAt", order: "DESC" }}
            exporter={false}
            actions={false}
            perPage={15}
            component="div"
            bulkActionButtons={false}
            className=""
          >
            <Datagrid>
              <FunctionField
                label="Id"
                render={(record: any) => {
                  const recordId = record?.id
                    .toString()
                    .slice(record.id.toString().length - 5)

                  return (
                    <Link to={`/orders/orders/${record.id}`}>{recordId}</Link>
                  )
                }}
              />
              <MyImageField source="media" />
              <TextField source="title" />
              <DateField source="updatedAt" showTime />
              <EditButton />
            </Datagrid>
          </List>
        )}
        {tab === "orders" && (
          <List
            title={<span></span>}
            hasCreate={false}
            basePath="/orders/orders"
            resource="orders/orders"
            filter={{ userId: props.id }}
            exporter={false}
            actions={false}
            perPage={15}
            component="div"
            bulkActionButtons={false}
            className=""
          >
            <Datagrid>
              <FunctionField
                label="Id"
                render={(record: any) => {
                  const recordId = record?.id
                    .toString()
                    .slice(record.id.toString().length - 5)

                  return (
                    <Link to={`/orders/orders/${record.id}`}>{recordId}</Link>
                  )
                }}
              />
              <NumberField source="trackingNumber" />
              <NumberField source="itemsCount" />
              <TextField source="orderStatus" />
              <TextField source="paymentType" />
              <TextField source="paymentStatus" />
              <FunctionField
                label="Total Price"
                render={(record) =>
                  record.totalPrice ? (
                    <span style={{ fontWeight: "bold" }}>
                      रू{" "}
                      {record.totalPrice.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : null
                }
              />
            </Datagrid>
          </List>
        )}
      </div>
    </Card>
  )
}
