import * as React from "react"
import { makeStyles } from "@material-ui/core"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  ImageInput,
  SelectInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"
import { useFormState } from "react-final-form"

import { SectionTitle, requiredValidate } from "../../common/form"
import NotificationImageField from "./NotificationImageField"

const styles = {
  id: { display: "inline-block" },
  title: { width: 544 },
  image: { width: 544 },
  message: { width: 544 },
  users: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const UserInput = (props: any) => {
  const { values } = useFormState()
  return !values?.meta?.isPromotional ? (
    <ReferenceArrayInput
      source="users"
      label="Send to"
      reference="users/users"
      {...props}
    >
      <AutocompleteArrayInput optionText="name" optionValue="id" />
    </ReferenceArrayInput>
  ) : null
}

const NotificationTitle = ({ record }: any) => (
  <span>
    Edit Notification {record ? `"${record.title}"` : `#${record.id}`}
  </span>
)

export const NotificationEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  const choices = [
    { id: "info", name: "Info" },
    { id: "warning", name: "Warning" },
    { id: "error", name: "Error" },
    { id: "success", name: "Success" },
    { id: "promotional", name: "Promotional" },
    { id: "done", name: "Done" },
    { id: "pending", name: "New order" },
    { id: "confirmed", name: "Confirmed order" },
    { id: "approved", name: "Approved order" },
    { id: "picked", name: "Picked order" },
    { id: "delivering", name: "Delivering order" },
    { id: "delivered", name: "Delivered order" },
    { id: "cancelled", name: "Cancelled" },
    { id: "rejected", name: "Rejected" },
    { id: "returned", name: "Returned" },
    { id: "defective", name: "Defective" },
  ]

  console.log("I am hit")

  return (
    <Edit title={<NotificationTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.notifications.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="message"
          fullWidth
          formClassName={classes.message}
          multiline
        />
        <SelectInput source="type" choices={choices} />

        <RadioButtonGroupInput
          label="Send to"
          source="meta.isPromotional"
          choices={[
            { id: true, name: "All Users" },
            { id: false, name: "Choose Users" },
          ]}
        />
        <UserInput fullWidth formClassName={classes.users} />
        <SectionTitle label="resources.notifications.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Notification image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <NotificationImageField />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}
