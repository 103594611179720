import * as React from "react"
import { EditButton, Identifier, Link, List } from "react-admin"

import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Popper,
  Typography,
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import NotificationsIcon from "@material-ui/icons/Notifications"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      border: "1px solid",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    root: { margin: "0.2em", padding: "0.1em" },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "0.1rem 0",
    },
    cardTitleContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    cardContent: {
      ...theme.typography.body1,
      display: "flex",
      flexDirection: "column",
    },
  })
)

const Empty = () => {
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h5" paragraph>
        You don't have products to be reviewed.
      </Typography>
      <Typography variant="body2">
        You can see product list here that are waiting for review.
      </Typography>
    </Box>
  )
}

const Notifications = (props: any) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const id = open ? "simple-popper" : undefined

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={props.total} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1000 }}>
        <List
          title={<span></span>}
          pagination={false}
          hasCreate={false}
          basePath="/products/products"
          resource="products/products"
          filter={{ "meta.isApproved": false }}
          sort={{ field: "updatedAt", order: "DESC" }}
          exporter={false}
          actions={false}
          empty={<Empty />}
          perPage={5}
          bulkActionButtons={false}
        >
          <ProductGrid />
        </List>
      </Popper>
    </div>
  )
}

const ProductGrid = ({ ids, data, basePath }: Props) => {
  const [clickedOutside, setClickedOutside] = React.useState(false)

  const classes = useStyles()
  if (!ids || !data) return null

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: "#fcfcfa",
        width: "98%",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
      }}
    >
      <Typography variant="h6" style={{ padding: "0.4em 0" }}>
        Notifications
      </Typography>
      {ids.map((id: Identifier) => {
        return (
          <Card key={id} className={classes.card}>
            <CardHeader
              title={
                <div className={classes.cardTitleContent}>
                  <Typography variant="body1">
                    <strong>{`${data[id].title}`}</strong>
                  </Typography>
                  <EditButton
                    resource="products/products"
                    basePath={basePath}
                    record={data[id]}
                  />
                </div>
              }
              //  avatar={<AvatarField record={data[id]} size="45" />}
            />
          </Card>
        )
      })}
      <Link to='products/products?filter={"meta":{"isApproved":false},"meta.isDeleted":false}'>
        <p style={{ padding: "0.1em 0" }}>More... </p>
      </Link>
    </div>
  )
}

interface Props {
  ids?: Identifier[]
  data?: any
  basePath?: string
}

export default Notifications
