import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  FilterProps,
  ListProps,
  NumberField,
  BooleanField,
} from "react-admin"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"

const NotificationFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

export const NotificationList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  console.log("I am hit")
  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<NotificationFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="title" />
        <TextField source="message" />
        <BooleanField source="meta.isPromotional" label="Promotional" />
        <DateField source="updatedAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
