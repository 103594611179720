import * as React from "react"
import { FC, useCallback } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateInput,
  useNotify,
  useRefresh,
  useMutation,
  ImageInput,
  Link,
  useEditController,
} from "react-admin"

import { SectionTitle } from "../../common/form"
import SpecialDiscountImageField from "./SpecialDiscountImageField"

const styles = {
  user: { width: 544 },
  category: { display: "inline-block" },
  status: { display: "inline-block", marginLeft: 32 },
  image: { width: 544 },
  message: { width: 544 },
  approvedAt: { display: "inline-block" },
  rejectedAt: { display: "inline-block", marginLeft: 32 },
  approvedBy: { width: 544 },
}

const useStyles = makeStyles(styles)

const SpecialDiscountTitle = ({ record }: any) => (
  <span>
    Edit SpecialDiscount {record.name ? `"${record.name}"` : `#${record.id}`}
  </span>
)

export const SpecialDiscountRequestEdit: FC<EditProps> = (props) => {
  const classes = useStyles()
  const { record } = useEditController(props)

  const notify = useNotify()
  const refresh = useRefresh()

  const [mutate] = useMutation()
  const save = useCallback(
    async (values) => {
      try {
        await mutate(
          {
            type: "update",
            resource: "users/special-discount-requests",
            payload: { id: values.id, data: values },
          },
          { returnPromise: true }
        )
        notify("Special discount request updated successfully.")
        refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )

  return (
    <Edit title={<SpecialDiscountTitle />} {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.specialDiscountRequests.fieldGroups.information" />
        <ReferenceInput
          source="user"
          reference="users/users"
          fullWidth
          formClassName={classes.user}
        >
          <AutocompleteInput />
        </ReferenceInput>
        {record && record.id && (
          <Link to={`/users/users/${record.user}`}>Update discount scheme</Link>
        )}
        <SelectInput
          source="category"
          choices={[
            { id: "teacher", name: "Teacher" },
            { id: "student", name: "Student" },
            { id: "corporate", name: "Corporate" },
            { id: "farmers", name: "Farmers" },
            { id: "surakshaKarmi", name: "Suraksha Karmi" },
          ]}
          formClassName={classes.category}
        />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "approved", name: "Approved" },
            { id: "rejected", name: "Rejected" },
          ]}
          formClassName={classes.status}
        />
        <ImageInput
          source="image"
          label="ID card image"
          accept="image/*"
          placeholder={<p>Drop your identity card here</p>}
          formClassName={classes.image}
        >
          <SpecialDiscountImageField />
        </ImageInput>
        <TextInput
          source="message"
          multiline
          fullWidth
          formClassName={classes.message}
        />
        <DateInput source="approvedAt" formClassName={classes.approvedAt} />
        <DateInput source="rejectedAt" formClassName={classes.rejectedAt} />
        <ReferenceInput
          source="approvedBy"
          reference="users/users"
          formClassName={classes.approvedBy}
          fullWidth
        >
          <AutocompleteInput />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}
