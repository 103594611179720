import * as React from "react"
import { FC, useState } from "react"
import {
  List,
  Datagrid,
  SingleFieldList,
  TextField,
  EditButton,
  BooleanInput,
  Filter,
  SearchInput,
  DateField,
  DeleteButton,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  FilterProps,
  BulkDeleteButton,
  Pagination,
  AutocompleteInput,
  ReferenceInput,
  Link,
  Button,
  useUpdate,
  useRefresh,
} from "react-admin"
import { ListProps } from "@material-ui/core"

import ListActions from "../../common/listActions"
import BrandField from "./BrandField"
import CategoryField from "./CategoryField"
import MakeProductOutOfStockButton from "./MakeProductOutOfStockButton"
import rowStyle from "./rowStyle"
import MakeProductInStockButton from "./MakeProductInStockButton"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"
import RecoverButton from "../../common/RecoverButton"

const condition = (val: string) => !!val && val.trim().length > 5

const ProductFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="brand"
      reference="products/brands"
      perPage={1000}
      filterToQuery={(val: string) =>
        condition(val) ? { name: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput optionText="title" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      source="category"
      reference="products/categories"
      perPage={1000}
      filterToQuery={(val: string) =>
        condition(val) ? { name: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput optionText="title" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      source="productBy"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      filterToQuery={(val: string) =>
        condition(val) ? { name: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>

    <BooleanInput label="Has Variation" source="hasVariation" />
    <BooleanInput label="Verified" source="meta.isApproved" />
  </Filter>
)

const MyImageField = ({ record }: any) => {
  return (
    <div>
      <img
        src={
          record.media && record.media.length > 0
            ? `${process.env.REACT_APP_AWS_BUCKET_URL}${record.media[0]}`
            : ""
        }
        height={75}
        width="auto"
      />
    </div>
  )
}

const TitleField = (props) => {
  return (
    <React.Fragment>
      <TextField source="title" />
      <br />
      <EditButton {...props} label="edit" />
      <br />
      {/* @ts-ignore */}
      <Link to={`${props.basePath}/${props.record.id}`}>edit</Link>
      <DeleteButton />
    </React.Fragment>
  )
}

const PreviewField = (props) => (
  <a
    href={`${process.env.REACT_APP_BASE_URL}/products/${props.record.slug}`}
    target="blank"
    style={{ textDecoration: "none" }}
  >
    preview
  </a>
)

const DuplicateProductButton = (props) => {
  const id = props.record.id
  const refresh = useRefresh()
  const [update, { loading }] = useUpdate()
  const handleClick = () => {
    update("products/products/duplicate", id, {}, {})
    refresh(true)
  }
  return <Button label="Duplicate" onClick={handleClick} disabled={loading} />
}

const ProductActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {deletefilter && (
        <>
          <DeletePermanentlyButton
            label="Delete product permanently"
            resourceLabel="Product"
            {...props}
          />
          <RecoverButton {...props} />
        </>
      )}
      {!deletefilter && (
        <>
          <MakeProductInStockButton label="Make in stock" {...props} />
          <MakeProductOutOfStockButton label="Make out of stock" {...props} />
          <BulkDeleteButton {...props} />
        </>
      )}
    </>
  )
}

const ProductPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const ProductList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      bulkActionButtons={<ProductActionButtons deletefilter={deletefilter} />}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<ProductFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={10}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
      pagination={<ProductPagination />}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField label="Title" source="title" />
        {/* <TitleField label="Products" /> */}
        <MyImageField source="media" />
        <ReferenceField source="brand" reference="products/brands">
          <BrandField />
        </ReferenceField>
        <ReferenceArrayField
          source="categories"
          reference="products/categories"
        >
          <SingleFieldList>
            <CategoryField />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="productBy" reference="users/users">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="stock" />
        <DateField source="updatedAt" />
        <DuplicateProductButton />
        <PreviewField />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}

export default ProductList
