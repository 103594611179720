import { useCallback, useEffect, useState } from "react"
import {
  Button,
  useDataProvider,
  useNotify,
  useRefresh,
  useUpdate,
  useVersion,
} from "react-admin"
import { useLocation } from "react-router-dom"

import DeleteIcon from "@material-ui/icons/Delete"
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Link as MaterialLink,
} from "@material-ui/core"
import IconCancel from "@material-ui/icons/Cancel"
import SaveIcon from "@material-ui/icons/Save"
import { red } from "@material-ui/core/colors"

import PackagingBoxDetails from "./PackagingBoxDetails"
import PackagingBoxForm from "./PackagingBoxForm"
import OrderEditButton from "./OrderEditButton"
import { optionRenderer, orderStatus } from "../resources/orders/common"

const API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_URL
    : "http://localhost:4000"

const PackagingBox = () => {
  const dataProvider = useDataProvider()

  const [packagingBox, setPackagingBox] = useState<any>(null)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [showBoxDetailDialog, setShowBoxDetailDialog] = useState<boolean>(false)

  const [item, setItem] = useState<any>(null)
  const [boxNumber, setBoxNumber] = useState<number | undefined>(undefined)
  const [stateBox, setStateBox] = useState<any>(null)

  const { pathname } = useLocation()

  console.log("Path Name = ", pathname)

  const trackingNumber = pathname.split("/")[3]

  console.log("Tracking Number = ", trackingNumber)

  const version = useVersion()

  const fetchPackagingBoxByTrackingNumber = useCallback(
    async (trackingNumber: string) => {
      const { data } = await dataProvider.getOne("oms/boxes/oms", {
        id: trackingNumber,
      })
      setPackagingBox(data)
    },
    [dataProvider]
  )

  useEffect(() => {
    fetchPackagingBoxByTrackingNumber(trackingNumber)
  }, [version, trackingNumber, fetchPackagingBoxByTrackingNumber])

  const status = packagingBox?.order?.orderStatus

  const _orderStatus = orderStatus.find((item) => item.id === status)

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Packaging Order #{trackingNumber}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <PackagingBoxForm />
          </Box>
          <Box display="flex" gridGap={20}>
            {/* @ts-ignore */}
            {_orderStatus && optionRenderer(_orderStatus)}
            <OrderEditButton
              packagingBox={packagingBox}
              order={packagingBox?.order}
            />
            {packagingBox?.remainingItems?.length !== 0 && (
              <Typography variant="body1" gutterBottom>
                Remaining Items: {packagingBox?.remainingItems?.length}
              </Typography>
            )}
          </Box>
        </Box>
        <br />
        <PackagingBoxDetails
          packagingBox={packagingBox}
          setItem={setItem}
          setBoxNumber={setBoxNumber}
          setStateBox={setStateBox}
          setShowDialog={setShowDialog}
          setShowBoxDetailDialog={setShowBoxDetailDialog}
          trackingNumber={trackingNumber}
        />
        <AddItemToBoxDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          item={item}
          boxNumber={boxNumber}
          box={stateBox}
          trackingNumber={trackingNumber}
        />
        <BoxDetailDialog
          showDialog={showBoxDetailDialog}
          setShowDialog={setShowBoxDetailDialog}
          box={stateBox}
          packagingBox={packagingBox}
        />
      </CardContent>
    </Card>
  )
}

const AddItemToBoxDialog = ({
  showDialog,
  setShowDialog,
  item,
  box,
  boxNumber,
  trackingNumber,
}) => {
  const [quantity, setQuantity] = useState(1)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleDialogClose = useCallback(() => {
    setShowDialog(false)
  }, [setShowDialog])

  const updateAddItemToBox = useCallback(
    async (trackingNumber: string) => {
      try {
        const response = await fetch(
          `${API_URL}/api/admin/oms/boxes/add-item/${trackingNumber}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              boxId: box?._id,
              item: { ...item, item: item?.item?.id || item?.item?._id },
              quantity,
            }),
          }
        )
        console.log("Response = ", response)
        if (response.status === 200 || response.status === 201) {
          handleDialogClose()
          notify("Item entried successfully")
          refresh()
        }
      } catch (error) {
        console.log(error)
      }
    },
    [box?._id, item, quantity, handleDialogClose, notify, refresh]
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    updateAddItemToBox(trackingNumber)
  }

  return (
    <Dialog
      open={showDialog}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Item to Box {boxNumber + 1}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <QuantityInput
            quantity={quantity}
            remainingQuantity={item?.quantity}
            setQuantity={setQuantity}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleDialogClose}
            startIcon={<IconCancel />}
          />
          <Button
            label="Submit"
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            // onClick={handleSubmit}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

const BoxDetailDialog = ({ showDialog, setShowDialog, box, packagingBox }) => {
  const [removeBox] = useUpdate()
  const refresh = useRefresh()
  const [boxIndex, setBoxIndex] = useState<number | undefined>(undefined)
  const [boxTotal, setBoxTotal] = useState<number | undefined>(undefined)
  const handleRemoveBox = useCallback(
    async (id: string) => {
      await removeBox(
        "oms/boxes/remove",
        id,
        {},
        {},
        {
          onSuccess: () => {
            setShowDialog(false)
            refresh()
          },
          onFailure: () => {},
        }
      )
    },
    [refresh, removeBox, setShowDialog]
  )

  useEffect(() => {
    if (box) {
      const index = packagingBox?.boxes?.findIndex((b) => b._id === box._id)
      setBoxIndex(index + 1)
      setBoxTotal(packagingBox?.boxes?.length)
    }
  }, [packagingBox, box])

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      fullWidth
      fullScreen
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: "70%",
        height: "100vh",
        background: "#fff",
      }}
    >
      <DialogTitle>Box Details</DialogTitle>
      <DialogContent>
        <Box display="flex">
          <Box flex={2}>
            <Typography>Bar code:</Typography>
          </Box>
          <Box flex={2}>
            <strong>{box?.barcode}</strong>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={2}>
            <Typography>Box Status:</Typography>
          </Box>
          <Box flex={2}>
            <strong>{box?.closed ? "Closed" : "Open"}</strong>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={2}>
            <Typography>Items:</Typography>
          </Box>
          <Box flex={2}>
            <strong>{box?.items?.length}</strong>
          </Box>
        </Box>
        <br />
        <MaterialLink
          color="primary"
          href={`${
            process.env.NODE_ENV === "development"
              ? "http://localhost:4000"
              : ""
          }/api/admin/orders/packaging-slip/${
            packagingBox?.order?.id
          }?barcode=${box?.barcode}&box=${boxIndex}&totalbox=${boxTotal}`}
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          Print Packaging Slip
        </MaterialLink>
        {/* <pre>{JSON.stringify(box, null, 2)}</pre> */}
        <br />
        <DialogActions>
          <Button
            label="Delete"
            startIcon={<DeleteIcon style={{ color: red[500] }} />}
            style={{ padding: 0 }}
            onClick={() => handleRemoveBox(box._id)}
          />
          <Button
            label="Close"
            onClick={() => setShowDialog(false)}
            startIcon={<IconCancel />}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const QuantityInput = ({ quantity, remainingQuantity, setQuantity }) => {
  const [error, setError] = useState({ errorText: "", error: false })

  const onChangeQuantity = (event) => {
    const value = event.target.value
    if (value < 0) {
      setError({ errorText: "Quantity cannot be negative", error: true })
    } else if (value > remainingQuantity) {
      setError({
        errorText: "Qty can't be greater than ordered quantity.",
        error: true,
      })
    } else if (value <= remainingQuantity) {
      setError({ errorText: "", error: false })
      setQuantity(event.target.value)
    } else {
      setQuantity(event.target.value)
    }
  }

  return (
    <div>
      <TextField
        autoFocus={true}
        name="quantity"
        type="number"
        label="Quantity"
        variant="outlined"
        size="small"
        defaultValue={1}
        onChange={onChangeQuantity}
        error={error.error ? true : false}
        helperText={error.errorText}
      />
    </div>
  )
}

export default PackagingBox
