import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
  NumberInput,
  AutocompleteInput,
  ReferenceInput,
  ImageInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"
import ReviewImageField from "./ReviewImageField"

const styles = {
  title: { width: 544 },
  user: { display: "inline-block" },
  rating: { display: "inline-block", marginLeft: 32 },
  message: { width: 544 },
  images: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const ReviewTitle = ({ record }: any) => (
  <span>Edit Review {record.name ? `"${record.name}"` : `#${record.id}`}</span>
)

export const ReviewEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  const condition = (val: string) => !!val && val.trim().length > 5
  return (
    <Edit title={<ReviewTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.reviews.fieldGroups.information" />
        <ReferenceInput
          source="productId"
          reference="products/products"
          fullWidth
          formClassName={classes.title}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          source="userId"
          reference="users/users"
          formClassName={classes.user}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="rating" formClassName={classes.rating} />
        <TextInput
          source="message"
          multiline
          fullWidth
          formClassName={classes.message}
        />
        <ImageInput
          source="media"
          label="Review pictures"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          multiple
          formClassName={classes.images}
        >
          <ReviewImageField />
        </ImageInput>
        <SectionTitle label="resources.reviews.fieldGroups.options" />
        <BooleanInput
          label="Approved"
          source="meta.isApproved"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
