import { makeStyles } from "@material-ui/core"
import RichTextInput from "ra-input-rich-text"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  NumberInput,
  BooleanInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
  title: { width: 544 },
  shortDescription: { width: 544 },
  metaTitle: { width: 544 },
  metaDescription: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const PageCMSTitle = ({ record }: any) => (
  <span>Edit PageCMS {record ? `"${record.title}"` : `#${record.id}`}</span>
)

export const PageCMSEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<PageCMSTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.pageCMS.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="shortDescription"
          fullWidth
          formClassName={classes.shortDescription}
          multiline
        />
        <RichTextInput
          source="description"
          fullWidth
          formClassName={classes.description}
          toolbar={[
            ["bold", "italic", "underline", "link"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ align: [] }],
            ["image"],
            [
              "table",
              "column-left",
              "column-right",
              "row-above",
              "row-below",
              "row-remove",
              "column-remove",
            ],
          ]}
        />
        <SectionTitle label="resources.pageCMS.fieldGroups.seo" />
        <TextInput
          source="metaTitle"
          fullWidth
          formClassName={classes.metaTitle}
        />
        <TextInput
          source="metaDescription"
          fullWidth
          formClassName={classes.metaDescription}
        />
      </SimpleForm>
    </Edit>
  )
}
