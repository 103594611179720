import * as React from "react"
import { Admin, Resource, DataProvider } from "react-admin"
import polyglotI18nProvider from "ra-i18n-polyglot"

import themeReducer from "./redux/themeReducer"
import { Layout, Login } from "./layout"
import englishMessages from "./i18n/en"
import shippings from "./resources/shippings"
import shippingMethods from "./resources/shippingMethods"
import areaClusters from "./resources/areaClusters"
import areas from "./resources/areas"
import districts from "./resources/districts"
import states from "./resources/states"
import countries from "./resources/countries"
import products from "./resources/products"
import categories from "./resources/categories"
import brands from "./resources/brands"
import attributes from "./resources/attributes"
import customRoutes from "./routes"
import authProvider from "./authProvider"
import users from "./resources/users"
import permissionsResource from "./resources/permissions"
import roles from "./resources/roles"
import Dashboard from "./dashboard/Dashboard"
import orders from "./resources/orders"
import brandCMS from "./resources/cms/brands"
import categoryCMS from "./resources/cms/categories"
import productCMS from "./resources/cms/products"
import sliderCMS from "./resources/cms/sliders"
import pageCMS from "./resources/cms/pages"
import bannerCMS from "./resources/cms/banners"
import menuCMS from "./resources/cms/menus"
import sales from "./resources/sales"
import tags from "./resources/tags"
import orderItems from "./resources/orderItems"
import discounts from "./resources/discounts"
import coupons from "./resources/coupons"
import agents from "./resources/agents"
import agentScheme from "./resources/agentScheme"
import specialDiscounts from "./resources/specialDiscounts"
import specialDiscountRequest from "./resources/specialDiscountRequest"
import reviews from "./resources/reviews"
import notifications from "./resources/notifications"
import urlShortner from "./resources/url-shortner"
import transport from "./resources/transport"
import orderItemsV1 from "./resources/orderItems/v1"
import creditSchemes from "./resources/creditSchemes"
import creditTransactions from "./resources/creditTransactions"
import vendorPayment from "./resources/vendorPayment"
import packagingBox from "./resources/packagingBox"

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default)
  }

  // Always fallback on english
  return englishMessages
}, "en")
interface AppProps {
  dataProvider: DataProvider
}

const App = ({ dataProvider }: AppProps) => {
  return (
    <Admin
      title=""
      dashboard={Dashboard}
      // @ts-ignore
      authProvider={authProvider}
      dataProvider={dataProvider}
      customReducers={{ theme: themeReducer }}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      disableTelemetry
    >
      <Resource
        name="users/permissions"
        options={{ label: "Permissions" }}
        {...permissionsResource}
      />
      <Resource name="users/roles" options={{ label: "Roles" }} {...roles} />
      <Resource name="users/users" options={{ label: "Users" }} {...users} />
      <Resource
        name="users/special-discount-requests"
        options={{ label: "Special Discount Request" }}
        {...specialDiscountRequest}
      />
      <Resource
        name="agents/agents"
        options={{ label: "Agents" }}
        {...agents}
      />
      <Resource
        name="agents/agentScheme"
        options={{ label: "Agent Scheme" }}
        {...agentScheme}
      />
      <Resource
        name="products/attributes"
        options={{ label: "Attributes" }}
        {...attributes}
      />
      <Resource
        name="products/brands"
        options={{ label: "Brands" }}
        {...brands}
      />
      <Resource
        name="products/categories"
        options={{ label: "Categories" }}
        {...categories}
      />
      <Resource name="products/tags" options={{ label: "Tags" }} {...tags} />
      <Resource
        name="products/products"
        options={{ label: "Products" }}
        {...products}
      />
      <Resource name="products/variations" />
      <Resource name="products/products/variation" />
      <Resource
        name="shippings/countries"
        options={{ label: "Countries" }}
        {...countries}
      />
      <Resource
        name="products/reviews"
        options={{ label: "Reviews" }}
        {...reviews}
      />
      <Resource
        name="shippings/states"
        options={{ label: "States" }}
        {...states}
      />
      <Resource
        name="shippings/districts"
        options={{ label: "Districts" }}
        {...districts}
      />
      <Resource
        name="shippings/areas"
        options={{ label: "Areas" }}
        {...areas}
      />
      <Resource
        name="shippings/area-clusters"
        options={{ label: "Area Clusters" }}
        {...areaClusters}
      />
      <Resource
        name="shippings/methods"
        options={{ label: "Methods" }}
        {...shippingMethods}
      />
      <Resource
        name="shippings/shippings"
        options={{ label: "Shippings" }}
        {...shippings}
      />
      <Resource
        name="orders/orders"
        options={{ label: "Orders" }}
        {...orders}
      />
      <Resource name="orders/order-stats" />
      {/* CMS */}
      <Resource name="cms/brands" options={{ label: "Brands" }} {...brandCMS} />
      <Resource
        name="cms/categories"
        options={{ label: "Categories" }}
        {...categoryCMS}
      />
      <Resource
        name="cms/products"
        options={{ label: "Products" }}
        {...productCMS}
      />
      <Resource
        name="cms/sliders"
        options={{ label: "Sliders" }}
        {...sliderCMS}
      />
      <Resource name="cms/menus" options={{ label: "Menus" }} {...menuCMS} />
      <Resource name="cms/pages" options={{ label: "Pages" }} {...pageCMS} />
      <Resource
        name="cms/banners"
        options={{ label: "Banners" }}
        {...bannerCMS}
      />
      <Resource name="sales/sales" options={{ label: "Sales" }} {...sales} />
      <Resource
        name="orders/items/v1"
        options={{ label: "Order Items (new)" }}
        {...orderItemsV1}
      />
      <Resource
        name="orders/items"
        options={{ label: "Order Items" }}
        {...orderItems}
      />
      <Resource
        name="products/discounts"
        options={{ label: "Discounts" }}
        {...discounts}
      />
      <Resource
        name="products/coupons"
        options={{ label: "Coupons" }}
        {...coupons}
      />
      <Resource
        name="products/specialDiscounts"
        options={{ label: "Special Discounts" }}
        {...specialDiscounts}
      />
      <Resource name="dashboard/stats/topSellers" />
      <Resource name="dashboard/stats/fees-by-payment-type" />
      {/* Notifications */}
      <Resource
        name="notifications/notifications"
        options={{ label: "Notifications" }}
        {...notifications}
        // edit={notifications.edit}
      />
      {/* Url Shortner */}
      <Resource name="url-shortner" {...urlShortner} />
      <Resource
        name="transport/transport"
        {...transport}
        options={{ label: "Transport" }}
      />
      <Resource
        name="credit/schemes"
        {...creditSchemes}
        options={{ label: "Credit Scheme" }}
      />
      <Resource
        name="credit/transactions"
        {...creditTransactions}
        options={{ label: "Credit Transaction" }}
      />
      <Resource name="user-shippings" />
      <Resource name="settings/settings" />
      <Resource name="vendor-payments" {...vendorPayment} />
      <Resource name="oms/oms" />
      <Resource name="oms/boxes" {...packagingBox} />
      <Resource name="oms/boxes/oms" />
    </Admin>
  )
}

export default App
