import LinkIcon from "@material-ui/icons/Link"

import { URLShortnerCreate } from "./URLShortnerCreate"
import { URLShortnerEdit } from "./URLShortnerEdit"
import { URLShortnerList } from "./URLShortnerList"

export default {
  icon: LinkIcon,
  list: URLShortnerList,
  create: URLShortnerCreate,
  edit: URLShortnerEdit,
}
