import React, { useState } from "react"
import {
  Button,
  SaveButton,
  useNotify,
  FormWithRedirect,
  useUpdateMany,
  useRefresh,
  useUnselectAll,
  ReferenceInput,
  EditProps,
  useUpdate,
  InputProps,
  TextInput,
} from "react-admin"
import IconCancel from "@material-ui/icons/Cancel"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { TextField } from "mui-rff"
import { MenuItem } from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import EditIcon from "@material-ui/icons/Edit"

function humanize(str: string) {
  var i: number,
    frags = str.split("_")
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

const UpdateConfigButton: React.FC<Omit<InputProps, "source">> = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [update, { loading }] = useUpdate(props.resource)
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    update(props.resource, props.record.id, { ...values }, props.record, {
      onSuccess: () => {
        setShowDialog(false)
        refresh()
        notify("Configuration updated")
      },
      onFailure: ({ error }) => {
        notify(error.message, "error")
      },
    })
  }

  return (
    <>
      <Button onClick={handleClick} label="Edit">
        <EditIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update configuration"
      >
        <DialogTitle>Update {humanize(props.record.name)}</DialogTitle>

        <FormWithRedirect
          resource={props.resource}
          save={handleSubmit}
          initialValues={props.record}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="value" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default UpdateConfigButton
