import "@reach/combobox/styles.css"

import GoogleMapReact from "google-map-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, useNotify, useRefresh, useUpdate } from "react-admin"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"

import { blue } from "@material-ui/core/colors"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import AddIcon from "@material-ui/icons/Add"
import IconCancel from "@material-ui/icons/Cancel"
import MyLocationIcon from "@material-ui/icons/MyLocation"
import SaveIcon from "@material-ui/icons/Save"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"

export const Places = ({ selected, setSelected }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries: ["places"],
  })

  if (!isLoaded) return <div>Loading...</div>
  return <Map selected={selected} setSelected={setSelected} />
}

const containerStyle = {
  width: "100%",
  height: "80vh",
  zIndex: 1,
}

const Map = ({ selected, setSelected }) => {
  useEffect(() => {}, [])

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 10,
          width: "300px",
        }}
      >
        <PlacesAutoComplete setSelected={setSelected} />
      </div>
      <GoogleMap
        zoom={15}
        center={selected}
        mapContainerStyle={containerStyle}
        onClick={(e) => {
          setSelected({ lat: e.latLng?.lat(), lng: e.latLng?.lng() })
        }}
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </>
  )
}

const PlacesAutoComplete = ({ setSelected }: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const handleSelect = async (address) => {
    setValue(address, false)
    clearSuggestions()
    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
  }

  const handleGetCurrentLocation = (e) => {
    e.preventDefault()
    navigator.geolocation.getCurrentPosition((position) => {
      setSelected({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  return (
    <Combobox onSelect={handleSelect}>
      <div style={{ display: "flex" }}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          style={{
            width: "100%",
            padding: "0.5rem",
          }}
          placeholder="Search a location"
        />
        <button onClick={handleGetCurrentLocation}>
          <MyLocationIcon />
        </button>
      </div>
      <ComboboxPopover
        style={{
          zIndex: 9999999999,
        }}
      >
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

function UpdateMapButton({ userId, lat, lng }) {
  const [showDialog, setShowDialog] = useState(false)

  const [selected, setSelected] = useState<any>({ lat, lng })

  const refresh = useRefresh()
  const [update] = useUpdate("users/users")
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    update(
      `users/users`,
      userId,
      {
        location: {
          latitude: selected.lat,
          longitude: selected.lng,
        },
      },
      {},
      {
        onSuccess: () => {
          setShowDialog(false)
          refresh()
          notify("User is updated")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  const initialValues = useMemo(
    () => ({
      location: {
        latitude: lat,
        longitude: lng,
      },
    }),
    [lat, lng]
  )

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries: ["places"],
  })

  if (!isLoaded) return <div>Loading...</div>

  return (
    <div>
      <Button
        onClick={handleClick}
        label="Update Location"
        style={{ color: blue[500] }}
      >
        <AddIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update post"
        maxWidth="xl"
        style={{
          position: "relative",
        }}
      >
        {/* <DialogTitle>Update Location</DialogTitle> */}

        {/* <FormWithRedirect
          resource="users/users"
          initialValues={initialValues}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <> */}
        {/* <DialogContent> */}
        {/* <MapField source="location" /> */}
        {/* </DialogContent> */}
        <Map selected={selected} setSelected={setSelected} />
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
          {/* <SaveButton
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            label="Update"
            //   @ts-ignore
            pristine={pristine}
            saving={saving}
          /> */}
          <Button label="Update" variant="contained" onClick={handleSubmit}>
            <SaveIcon />
          </Button>
        </DialogActions>
        {/* </>
          )}
        /> */}
      </Dialog>
    </div>
  )
}

export default UpdateMapButton
