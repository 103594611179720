import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanInput,
  FilterProps,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField,
  SelectInput,
} from "react-admin"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import { ListProps } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const SpecialDiscountRequestFilter: FC<Omit<FilterProps, "children">> = (
  props
) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      emptyText="All"
      source="status"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
      ]}
      alwaysOn
    />
  </Filter>
)

export const SpecialDiscountRequestList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<SpecialDiscountRequestFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <ReferenceField source="user" reference="users/users">
          <ChipField source="name" />
        </ReferenceField>
        <TextField source="category" />
        <TextField source="status" />
        <DateField source="updatedAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
