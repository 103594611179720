import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  DateInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  ImageInput,
  ImageField,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"
import ReviewImageField from "./ReviewImageField"

const styles = {
  title: { width: 544 },
  user: { display: "inline-block" },
  rating: { display: "inline-block", marginLeft: 32 },
  message: { width: 544 },
  images: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

export const ReviewCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/reviews",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Review saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )

  const condition = (val: string) => !!val && val.trim().length > 5

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.reviews.fieldGroups.information" />
        <ReferenceInput
          source="productId"
          reference="products/products"
          fullWidth
          formClassName={classes.title}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <ReferenceInput
          source="userId"
          reference="users/users"
          formClassName={classes.user}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="rating" formClassName={classes.rating} />
        <TextInput
          source="message"
          multiline
          fullWidth
          formClassName={classes.message}
        />
        <ImageInput
          source="media"
          label="Review pictures"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          multiple
          formClassName={classes.images}
        >
          <ImageField src="src" title="title" />
        </ImageInput>
        <SectionTitle label="resources.reviews.fieldGroups.options" />
        <BooleanInput
          label="Approved"
          source="meta.isApproved"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Create>
  )
}
