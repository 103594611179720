import LocationCityIcon from "@material-ui/icons/LocationCity"
import { TransportList } from "./TransportList"
import { TransportEdit } from "./TransportEdit"
import { TransportCreate } from "./TransportCreate"

export default {
  list: TransportList,
  edit: TransportEdit,
  create: TransportCreate,
  icon: LocationCityIcon,
}
