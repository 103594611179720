import * as React from "react"
import { Fragment, useState, FC } from "react"
import { Button, useRefresh } from "react-admin"
import { green } from "@material-ui/core/colors"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { useFormState } from "react-final-form"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import { Checkboxes, CheckboxData } from "mui-rff"
import { makeStyles } from "@material-ui/core"

const styles = {
  dialog: { padding: 10 },
  image: { height: 160, margin: 10 },
}

const useStyles = makeStyles(styles)

interface VariationImageInputProps {
  name: string
  combinationIndex: number
}

const VariationImageInput: FC<VariationImageInputProps> = ({
  name,
  combinationIndex,
}) => {
  const classes = useStyles()
  const { values } = useFormState()

  const mediaIndexes = values.variation.combination[combinationIndex].media

  const [showDialog, setShowDialog] = useState(false)
  const handleClick = () => setShowDialog(true)

  const choices: any = []

  if (values.media) {
    values.media.map((media, index) => {
      choices.push({
        label: (
          <img
            src={
              media.undefined
                ? media.undefined
                : `${process.env.REACT_APP_AWS_BUCKET_URL}${media}`
            }
            className={classes.image}
          />
        ),
        value: media.undefined ? index : media,
      })
    })
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  return (
    <Fragment>
      <Button label="Select Image" variant="contained" onClick={handleClick} />
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Select image"
        className={classes.dialog}
      >
        <DialogTitle>Select Image</DialogTitle>
        <DialogContent>
          {choices && (
            <Checkboxes
              label="Check at least one..."
              name={name}
              data={choices}
            />
          )}
        </DialogContent>
        <Button label="Done" variant="contained" onClick={handleCloseClick} />
      </Dialog>
      <div>
        {values.media &&
          mediaIndexes &&
          mediaIndexes.map((indexOrMedia: any) => {
            let src
            if (typeof indexOrMedia === "number")
              src = values.media[indexOrMedia]
            if (typeof indexOrMedia === "string") src = indexOrMedia
            return (
              src && (
                <img
                  src={
                    src.undefined
                      ? src.undefined
                      : `${process.env.REACT_APP_AWS_BUCKET_URL}${src}`
                  }
                  className={classes.image}
                />
              )
            )
          })}
      </div>
    </Fragment>
  )
}

// record.undefined
// ? record.undefined
// : `${process.env.REACT_APP_AWS_BUCKET_URL}${record}`

export default VariationImageInput
