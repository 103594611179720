import * as React from "react"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts"
import { useTranslate, Loading } from "react-admin"
import { format, subDays, addDays } from "date-fns"

const lastDay = new Date()
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i))
const aMonthAgo = subDays(new Date(), 30)

const dateFormatter = (date: number): string =>
  new Date(date).toLocaleDateString()

const aggregateSalesByDay = (sales: any): { [key: string]: number } => {
  return sales.reduce((acc, curr) => {
    const d = new Date(curr.createdAt)
    const day = [
      d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-")

    if (!acc[day]) {
      acc[day] = 0
    }
    acc[day] += curr.vendorPayable + curr.margin
    return acc
  }, {} as { [key: string]: number })
}

const getRevenuePerDay = (sales: any[]): TotalByDay[] => {
  const daysWithRevenue = aggregateSalesByDay(sales)
  return lastMonthDays.map((date) => {
    const day = [
      date.getFullYear(),
      ("0" + (date.getMonth() + 1)).slice(-2),
      ("0" + date.getDate()).slice(-2),
    ].join("-")

    return {
      date: date.getTime(),
      total: daysWithRevenue[day] || 0,
    }
  })
}

const SaleChart = (props: { sales?: any[] }) => {
  const { sales } = props
  const translate = useTranslate()
  // if (!sales) return null

  return (
    <Card>
      <CardHeader title={translate("pos.dashboard.monthHistory")} />
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          {sales ? (
            <ResponsiveContainer>
              <AreaChart data={getRevenuePerDay(sales)}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  name="Date"
                  type="number"
                  scale="time"
                  domain={[
                    addDays(aMonthAgo, 1).getTime(),
                    new Date().getTime(),
                  ]}
                  tickFormatter={dateFormatter}
                />
                <YAxis dataKey="total" name="Revenue" unit="रू" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  cursor={{ strokeDasharray: "3 3" }}
                  formatter={(value) =>
                    new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "NPR",
                    }).format(value as any)
                  }
                  labelFormatter={(label: any) => dateFormatter(label)}
                />
                <Area
                  type="monotone"
                  dataKey="total"
                  stroke="#8884d8"
                  strokeWidth={2}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          ) : <Loading />}
        </div>
      </CardContent>
    </Card>
  )
}

interface TotalByDay {
  date: number
  total: number
}

export default SaleChart
