import LoyaltyIcon from "@material-ui/icons/Loyalty"
import { BrandCMSCreate } from "./BrandCMSCreate"
import { BrandCMSEdit } from "./BrandCMSEdit"
import { BrandCMSList } from "./BrandCMSList"

export default {
  list: BrandCMSList,
  create: BrandCMSCreate,
  edit: BrandCMSEdit,
  icon: LoyaltyIcon,
}
