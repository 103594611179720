import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
  SelectInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  shortDescription: { width: 544 },
  target: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  link: { width: 544 },
  caption: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const condition = (val: string) => !!val && val.trim().length > 5

export const SliderCMSCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "cms/sliders",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`SliderCMS saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.sliderCMS.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="shortDescription"
          fullWidth
          formClassName={classes.shortDescription}
          multiline
        />
        <TextInput source="link" fullWidth formClassName={classes.link} />
        <NumberInput source="position" />
        <SectionTitle label="resources.sliderCMS.fieldGroups.mobile" />
        <SelectInput
          source="target"
          choices={[
            { id: "SearchDetails", name: "Search Details" },
            { id: "ShopByBrand", name: "Shop By Brand" },
            { id: "ProductDetail", name: "Product Detail" },
            { id: "ShopByCategory", name: "Shop By Category" },
          ]}
          formClassName={classes.target}
        />
        <TextInput source="value" formClassName={classes.value} />
        <SectionTitle label="resources.sliderCMS.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Slider image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput
          source="keepOriginal"
          defaultValue={false}
          helperText="If enabled, the image won't be resized."
        />
        <TextInput source="caption" fullWidth formClassName={classes.caption} />
        <SectionTitle label="resources.sliderCMS.fieldGroups.option" />
        <BooleanInput source="isVisible" />
      </SimpleForm>
    </Create>
  )
}
