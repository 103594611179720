import * as React from "react"
import { FC, useState } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextField,
  EditButton,
  BooleanInput,
  SelectInput,
  Filter,
  SearchInput,
  DateField,
  DeleteButton,
  FilterProps,
  ListProps,
} from "react-admin"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"

const AttributeFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: "color", name: "Color" },
        { id: "image", name: "Image" },
        { id: "label", name: "Label" },
      ]}
    />
    <BooleanInput label="Visible" source="isVisible" />
  </Filter>
)

const AttributeList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }
  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<AttributeFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      // perPage={20}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid>
        <IDField sortable={false} source="id" />
        <TextField source="title" />
        <TextField source="type" />
        <DateField source="updatedAt" />
        {/* <BooleanField
          label="Visible"
          source="isVisible"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        /> */}
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}

export default AttributeList
