import {
  List,
  Datagrid,
  TextField,
  FilterProps,
  Filter,
  SearchInput,
  NumberField,
  DateField,
  EditButton,
  DeleteButton,
  ReferenceArrayField,
  ReferenceField,
  BooleanField,
} from "react-admin"

import { IDField } from "../../common/form"
import React, { FC } from "react"
import { ListProps } from "@material-ui/core"
import ListActions from "../../common/listActions"

const CreditTransactionFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

export const CreditTransactionList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<CreditTransactionFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <NumberField source="amount" />
        <ReferenceField source="userId" reference="users/users">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField label="Paid" source="payment.status" />
        <DateField source="updatedAt" showTime />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
