import React, { useState } from "react"
import {
  Button,
  FormWithRedirect,
  SaveButton,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from "react-admin"

import { green } from "@material-ui/core/colors"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCancel from "@material-ui/icons/Cancel"
import RestoreIcon from "@material-ui/icons/Restore"

function RecoverButton({ selectedIds, resource, resourceLabel }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [updateMany, { loading }] = useUpdateMany(resource)
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    updateMany(
      resource,
      selectedIds,
      { "meta.isDeleted": false },
      {
        onSuccess: () => {
          setShowDialog(false)
          refresh()
          notify("Recovered successfully")
          unselectAll(resource)
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Recover"
        style={{ color: green[500] }}
      >
        <RestoreIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Recover Items</DialogTitle>

        <FormWithRedirect
          resource="posts"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                Do you want to recover selected items?
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default RecoverButton
