import ExposureIcon from "@material-ui/icons/Exposure"
import { CouponCreate } from "./CouponCreate"
import { CouponEdit } from "./CouponEdit"
import { CouponList } from "./CouponList"

export default {
  list: CouponList,
  create: CouponCreate,
  edit: CouponEdit,
  icon: ExposureIcon,
}
