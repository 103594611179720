import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { FC } from "react"
import { Link, ShowProps, useShowController } from "react-admin"
import { Table } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const useStyles = makeStyles({
  root: { width: 900, margin: "auto" },
  bold: { fontWeight: "bold" },
})

const OrderItemV1Show: FC<ShowProps> = (props) => {
  const { record } = useShowController(props)
  const classes = useStyles()
  if (!record) return null
  if (record?.items?.length === 0) return null
  return record?.items && record?.items?.length > 0 ? (
    <Card className={classes.root}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Pickup Boy</TableCell>
              <TableCell>Media</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Vendor Payable</TableCell>
              <TableCell>Tota Vendor Payable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link to={`/orders/items/${item.id}`}>
                    #{item.id.slice(item.id.length - 5)}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={`/orders/orders/${item?.order?.id}`}>
                    {item.order?.orderNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  {" "}
                  <Link to={`/users/users/${item?.pickupBoy?.id}`}>
                    {item?.pickupBoy?.name}
                  </Link>
                </TableCell>
                <TableCell>
                  <img
                    src={
                      item.product && item.product.media.length > 0
                        ? `${process.env.REACT_APP_AWS_BUCKET_URL}${item.product.media[0]}`
                        : ""
                    }
                    height={75}
                    width="auto"
                  />
                </TableCell>
                <TableCell>
                  <Link to={`/products/products/${item.product.id}`}>
                    {item.product.title}
                  </Link>
                </TableCell>
                <TableCell>
                  {item?.hasVariation ? (
                    <>
                      {item.variations.map((variation) => (
                        <div>{variation.quantity}</div>
                      ))}
                    </>
                  ) : (
                    item.quantity
                  )}
                </TableCell>
                <TableCell>
                  {" "}
                  {item?.hasVariation ? (
                    item.variations.map((variation) => {
                      const { conf } = variation
                      const keys = Object.keys(conf)
                      return (
                        <div>
                          {keys &&
                            keys?.map((key) => (
                              <>
                                <span>{key}: </span>
                                <span className={classes.bold}>
                                  {conf[key]?.title},{" "}
                                </span>
                              </>
                            ))}{" "}
                          रू{" "}
                          {variation?.price?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      )
                    })
                  ) : (
                    <>
                      रू{" "}
                      {item?.price?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  {" "}
                  {item?.hasVariation ? (
                    item.variations.map((variation) => {
                      const { conf } = variation
                      const keys = Object.keys(conf)
                      return (
                        <div>
                          {keys &&
                            keys?.map((key) => (
                              <>
                                <span>{key}: </span>
                                <span className={classes.bold}>
                                  {conf[key]?.title},{" "}
                                </span>
                              </>
                            ))}{" "}
                          रू{" "}
                          {variation?.vendorPayablePrice?.toLocaleString(
                            "en-IN",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <>
                      रू{" "}
                      {item?.vendorPayablePrice?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <span className={classes.bold}>
                    रू{" "}
                    {item.totalVendorPayablePrice?.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  ) : (
    <></>
  )
}

export default OrderItemV1Show
