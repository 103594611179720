import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanInput,
  FilterProps,
  BulkDeleteButton,
} from "react-admin"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import { ListProps } from "@material-ui/core"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"

const CountryFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput label="Status" source="status" />
  </Filter>
)

const StateActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <DeletePermanentlyButton
          label="Delete District permanently"
          resourceLabel="District"
          {...props}
        />
      )}
    </>
  )
}

export const CountryList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<CountryFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<StateActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="name" />
        <DateField source="updatedAt" />
        <BooleanField
          label="Status"
          source="status"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
