import * as React from "react"
import { FC } from "react"
import {
  Edit,
  ReferenceInput,
  SelectInput,
  EditProps,
  FormWithRedirect,
  useTranslate,
  Labeled,
  DateField,
  SaveButton,
  BooleanInput,
  NumberInput,
  TextInput,
  NumberField,
  TextField,
  FunctionField,
  DateInput,
} from "react-admin"
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Link,
  Toolbar,
  Link as MaterialLink,
} from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { useFormState } from "react-final-form"

import ItemAddButton from "./ItemAddButton"
import Basket from "./Basket"
import Totals from "./Totals"
import OrderImageField from "./OrderImageField"
import UserShippingUpdateButton from "./UserShippingUpdateButton"
import ShareInvoiceButton from "./ShareInvoiceButton"
import ShareVatBillButton from "./ShareVatBillButton"
import { optionRenderer, orderStatus, paymentStatus } from "./common"
import ShareChallanButton from "./ShareChallanButton"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const useEditStyles = makeStyles({
  root: { alignItems: "flex-start" },
})

const OrderTitle = ({ record }: any) => {
  return <span>Edit Order #{record.trackingNumber ?? record.id}</span>
}

const OrderItemAddButton = (props: any) => {
  const { values } = useFormState()
  return <ItemAddButton orderId={values.id} />
}

const CustomerDetails = ({ record }: { record?: any }) => (
  <Box display="flex" flexDirection="column">
    <Typography
      component={RouterLink}
      color="primary"
      to={`/users/users/${record.user?.id}`}
      style={{ textDecoration: "none" }}
    >
      {record.user?.name}
    </Typography>
    <Typography
      component={Link}
      color="primary"
      href={`mailto:${record.user?.email}`}
      style={{ textDecoration: "none" }}
    >
      {record.user?.email}
    </Typography>
    <Typography
      component={Link}
      color="primary"
      href={`tel:${record.user?.phone}`}
      style={{ textDecoration: "none" }}
    >
      {record.user?.phone}
    </Typography>
  </Box>
)

const CustomerAddress = ({ record }: { record?: any }) => (
  <Box>
    <Typography>{record.shippingDetails}</Typography>
    <Typography>{record.landmark}</Typography>
    {/* <br /> */}
    <Typography>
      <strong>Additional Info: </strong>
      {record?.location?.additionalInfo}
    </Typography>
    <UserShippingUpdateButton location={record.location} record={record} />
  </Box>
)

const OrderEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const PaymentCODByDeliveryBoyInput = (props) => {
  const { values } = useFormState()
  return values.paymentType == "cod" ? <BooleanInput {...props} /> : null
}

const Spacer = () => <Box m={1}>&nbsp;</Box>

const OrderForm = (props: any) => {
  const translate = useTranslate()
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Box display="flex">
          <Box maxWidth="90em">
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="h6" gutterBottom>
                      {/* {translate("resources.orders.name")} */}
                      Order
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <Labeled
                            source="createdAt"
                            label="Date"
                            resource="orders/orders"
                          >
                            <DateField
                              source="createdAt"
                              resource="orders/orders"
                              record={formProps.record}
                            />
                          </Labeled>
                        </Box>
                        <Box>
                          <SelectInput
                            variant="outlined"
                            source="orderStatus"
                            choices={orderStatus}
                            optionText={optionRenderer}
                          />
                        </Box>

                        <Box>
                          <ReferenceInput
                            source="deliveryBoy"
                            reference="users/users"
                            filter={{ role: "DELIVERYBOY" }}
                            sort={{ field: "name", order: "ASC" }}
                            perPage={100}
                          >
                            <SelectInput
                              variant="outlined"
                              optionValue="id"
                              optionText="name"
                            />
                          </ReferenceInput>
                        </Box>
                        <Box>
                          <NumberInput
                            variant="outlined"
                            source="shippingCost"
                          />
                        </Box>
                        <Box>
                          <BooleanInput source="needVatBill" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography variant="h6">Payment</Typography>
                        <Box>
                          <SelectInput
                            variant="outlined"
                            source="paymentType"
                            choices={[
                              { id: "cod", name: "Cash on Delivery" },
                              { id: "ips", name: "IPS" },
                              { id: "esewa", name: "E-sewa" },
                              { id: "visa", name: "VISA" },
                              { id: "fonePay", name: "fonePay" },
                              { id: "khalti", name: "Khalti" },
                              { id: "bankTransfer", name: "Bank Transfer" },
                            ]}
                          />
                        </Box>
                        <Box>
                          <SelectInput
                            variant="outlined"
                            source="paymentStatus"
                            choices={paymentStatus}
                            optionText={optionRenderer}
                          />
                        </Box>
                        <Box>
                          <PaymentCODByDeliveryBoyInput
                            label="Payment of COD from Delivery Boy"
                            source="paymentToAdmin.status"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      {/* {translate("resources.commands.section.customer")} */}
                      Customer
                    </Typography>
                    {/* Customer Field */}
                    <CustomerDetails record={formProps.record} />
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                      {/* {translate("resources.commands.section.shipping_address")} */}
                      Shipping Address
                    </Typography>
                    <CustomerAddress record={formProps.record} />
                    <Typography variant="h6">
                      {/* {translate("resources.commands.section.shipping_address")} */}
                      Order Details
                    </Typography>
                    <span>Order Number: </span>
                    <NumberField label="Order Number" source="orderNumber" />
                    <br />
                    <span>Tracking Number: </span>
                    <TextField source="trackingNumber" />
                    <FunctionField
                      source="Items"
                      render={(record) =>
                        record?.items?.length ? (
                          <Typography variant="h6">
                            <strong>{record?.items?.length}</strong>
                            <em>items</em>
                          </Typography>
                        ) : null
                      }
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" gutterBottom>
                  Transport
                </Typography>
                <Box display="flex" gridGap={10}>
                  <Box flex={1}>
                    {formProps?.record?.district?.id && (
                      <ReferenceInput
                        source="transportId"
                        reference="transport/transport"
                        filter={{
                          districtId: formProps.record.district.id,
                        }}
                        allowEmpty
                        emptyText="No Transport"
                        // style={{ width: "80%" }}
                      >
                        <SelectInput
                          variant="outlined"
                          optionValue="id"
                          optionText="name"
                        />
                      </ReferenceInput>
                    )}
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      variant="outlined"
                      source="builtyNumber"
                      label="Builty Number"
                      // style={{ width: "80%" }}
                      // fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <SelectInput
                      variant="outlined"
                      source="transportCostPayment"
                      choices={[
                        { id: "paid", name: "Paid" },
                        { id: "toPay", name: "To Pay" },
                        {
                          id: "fullFilledByVendor",
                          name: "Fullfilled by Vendor",
                        },
                        {
                          id: "fullFilledByAdmin",
                          name: "Fullfilled by Admin",
                        },
                      ]}
                      allowEmpty
                      emptyText=""
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <DateInput
                      variant="outlined"
                      source="transportEta"
                      label="ETA"
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <NumberInput
                      variant="outlined"
                      source="transportCost"
                      label="Transport Cost"
                      // style={{ width: "80%" }}
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box display="flex" gridGap={20}>
                  <Box flex={1}>
                    <TextInput variant="outlined" source="cancellationReason" />
                  </Box>
                  <Box flex={1}>
                    <TextInput variant="outlined" source="userNote" />
                  </Box>
                  <Box flex={1}>
                    <TextInput variant="outlined" source="adminNote" />
                  </Box>
                  <Box flex={2} />
                </Box>

                <br />
                <Typography variant="h6" gutterBottom>
                  {/* {translate("resources.commands.section.items")} */}
                  Items
                  <OrderItemAddButton />
                </Typography>
                <Box>
                  <Basket record={formProps.record} />
                </Box>
                <Typography variant="h6" gutterBottom>
                  {/* {translate("resources.commands.section.total")} */}
                  Totals
                </Typography>
                <Box>
                  {/* <Totals record={formProps.record} /> */}
                  <Totals record={formProps.record} />
                </Box>
              </CardContent>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box display="flex" gridGap={8}>
                    <SaveButton
                      saving={formProps.saving}
                      handleSubmitWithRedirect={
                        formProps.handleSubmitWithRedirect
                      }
                    />
                    <ShareInvoiceButton record={formProps.record} />
                    <ShareVatBillButton record={formProps.record} />
                    <ShareChallanButton record={formProps.record} />
                  </Box>
                  <MaterialLink
                    color="primary"
                    href={`${
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:4000"
                        : ""
                    }/api/admin/orders/labels/${formProps.record?.id}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    Print Label
                  </MaterialLink>
                  {/* @ts-ignore */}
                  <MaterialLink
                    color="primary"
                    href={`${
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:4000"
                        : ""
                    }/api/admin/orders/invoice/${formProps.record?.id}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    Print Invoice
                  </MaterialLink>
                  {/* @ts-ignore */}
                  <MaterialLink
                    color="primary"
                    href={`${
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:4000"
                        : ""
                    }/api/admin/orders/challan/${formProps.record?.id}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    Print Challan
                  </MaterialLink>
                  {/* @ts-ignore */}
                  {!["refund"].includes(formProps.record.orderStatus) &&
                    formProps.record.needVatBill && (
                      <MaterialLink
                        color="primary"
                        href={`${
                          process.env.NODE_ENV === "development"
                            ? "http://localhost:4000"
                            : ""
                        }/api/admin/orders/vat-bill/${formProps.record?.id}`}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        Print Vat Bill
                      </MaterialLink>
                    )}
                  <MaterialLink
                    color="primary"
                    href={`${
                      process.env.NODE_ENV === "development"
                        ? "http://localhost:4000"
                        : ""
                    }/api/admin/orders/packaging-slip/${formProps.record?.id}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    Print Packaging Slip
                  </MaterialLink>
                  {/* <Link to=`/orders/invoice/${formProps.record.id}`>
                </Link> */}
                </Box>
              </Toolbar>
            </Card>
          </Box>
          {formProps.record?.voucherImage && (
            <Box marginLeft={1}>
              <Card>
                <CardContent>
                  <OrderImageField record={formProps.record?.voucherImage} />
                </CardContent>
              </Card>
            </Box>
          )}
        </Box>
      )}
    />
  )
}

export const OrderEdit: FC<EditProps> = (props) => {
  const classes = useEditStyles()

  return (
    <Edit title={<OrderTitle />} classes={classes} {...props} component="div">
      <OrderForm />
    </Edit>
  )
}
