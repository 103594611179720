import * as React from "react"
import { useState } from "react"
import { Card, CardHeader, CardContent, Radio } from "@material-ui/core"
import { subDays } from "date-fns"
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"

export default function FeesCollectionsBarChart(props: any) {
  const aMonthAgo = subDays(new Date(), 30)
  const aWeekAgo = subDays(new Date(), 7)
  const aDayAgo = subDays(new Date(), 1)
  const [selectedValue, setSelectedValue] = useState("month")

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    if (event.target.value === "month") {
      props.setFeesByPaymentTypeFilter(aMonthAgo.toISOString())
    } else if (event.target.value === "week") {
      props.setFeesByPaymentTypeFilter(aWeekAgo.toISOString())
    } else if (event.target.value === "day") {
      props.setFeesByPaymentTypeFilter(aDayAgo.toISOString())
    }
  }
  return (
    <Card>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CardHeader title="Fees Collection By Payment Type" />
        <div style={{ display: "flex", flexDirection: "row-reverse", padding: 5 }}>
          <div>
            <Radio
              checked={selectedValue === "month"}
              onChange={handleChange}
              value="month"
              name="radio-buttons"
            />
            <span>Monthly</span>
          </div>
          <div>
            <Radio
              checked={selectedValue === "week"}
              onChange={handleChange}
              value="week"
              name="radio-buttons"
            />
            <span>Weekly</span>
          </div>
          <div>
            <Radio
              checked={selectedValue === "day"}
              onChange={handleChange}
              value="day"
              name="radio-buttons"
            />
            <span>Daily</span>
          </div>
        </div>
      </div>
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          {props.data ? (
            <BarChart
              width={500}
              height={300}
              data={props.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="paymentType" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="productPrice" stackId="a" fill="#82ca9d" />
              <Bar dataKey="shippingCost" stackId="a" fill="#8884d8" />
            </BarChart>
          ) : null}
        </div>
      </CardContent>
    </Card>
  )
}
