import * as React from "react"
import { useSelector } from "react-redux"
import { Layout, LayoutProps, Sidebar } from "react-admin"
import { AppState } from "../ts/types"
import AppBar from "./AppBar"
import Menu from "./Menu"
import { darkTheme, lightTheme } from "./themes"

const CustomSidebar = (props: any) => <Sidebar {...props} />

export default (props: LayoutProps) => {
  const theme = useSelector((state: AppState) =>
    state.theme === "dark" ? darkTheme : lightTheme
  )
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
    />
  )
}
