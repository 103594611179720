import MoneyOffIcon from "@material-ui/icons/MoneyOff"
import { DiscountCreate } from "./DiscountCreate"
import { DiscountEdit } from "./DiscountEdit"
import { DiscountList } from "./DiscountList"

export default {
  list: DiscountList,
  create: DiscountCreate,
  edit: DiscountEdit,
  icon: MoneyOffIcon,
}
