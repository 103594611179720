import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { useFormState } from "react-final-form"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  minValue,
  required,
} from "react-admin"

import { SectionTitle, requiredValidate, Separator } from "../../common/form"

const styles = {
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  userIds: { width: 544 },
  couponType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  startsAt: { display: "inline-block" },
  expiresAt: { display: "inline-block", marginLeft: 32 },
  minExpense: { display: "inline-block" },
  maxExpense: { display: "inline-block", marginLeft: 32 },
  total: { display: "inline-block" },
  perUserLimit: { display: "inline-block", marginLeft: 32 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const VisibleInput = (props) => {
  const { values } = useFormState()
  const defaultValue = values.startsAt <= Date.now() ? true : false
  return <BooleanInput {...props} defaultValue={defaultValue} />
}

const CouponOnInput = (props) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <>
      {values.isForAllProducts ? null : (
        <>
          <ReferenceArrayInput
            label="Brands"
            source="couponOn.brandIds"
            reference="products/brands"
            sort={{ field: "title", order: "ASC" }}
            perPage={10}
            filterToQuery={(val: string) =>
              condition(val) ? { title: val.trim() } : {}
            }
            fullWidth
            formClassName={classes.brands}
          >
            <AutocompleteArrayInput optionValue="id" optionText="title" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Categories"
            source="couponOn.categoryIds"
            reference="products/categories"
            sort={{ field: "title", order: "ASC" }}
            filter={{ entry: true }}
            perPage={10}
            filterToQuery={(val: string) =>
              condition(val) ? { title: val.trim() } : {}
            }
            fullWidth
            formClassName={classes.categories}
          >
            <AutocompleteArrayInput optionValue="id" optionText="title" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Products"
            source="couponOn.productIds"
            reference="products/products"
            sort={{ field: "title", order: "ASC" }}
            perPage={10}
            filterToQuery={(val: string) =>
              condition(val) ? { title: val.trim() } : {}
            }
            fullWidth
            formClassName={classes.products}
          >
            <AutocompleteArrayInput optionValue="id" optionText="title" />
          </ReferenceArrayInput>
        </>
      )}
      {values.isForAllUsers ? null : (
        <ReferenceArrayInput
          label="Users"
          source="userIds"
          reference="users/users"
          sort={{ field: "name", order: "ASC" }}
          perPage={10}
          fullWidth
          formClassName={classes.userIds}
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
      )}
    </>
  )
}

const condition = (val: string) => !!val && val.trim().length > 6

export const CouponCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/coupons",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Coupon saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save} initialValues={() => ({ from: "coupon" })}>
        <SectionTitle label="resources.coupons.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="slug"
          fullWidth
          formClassName={classes.slug}
          helperText="It must be unique through coupons list."
        />
        <TextInput
          source="description"
          fullWidth
          multiline
          formClassName={classes.description}
        />

        <SectionTitle label="resources.coupons.fieldGroups.discounts" />
        <BooleanInput
          source="isForAllProducts"
          formClassName={classes.boolean}
        />
        <BooleanInput source="isForAllUsers" formClassName={classes.boolean} />
        <CouponOnInput formClassName={classes.brands} />
        <SelectInput
          source="couponType"
          choices={[
            { id: "rupees", name: "Fixed Rupees" },
            { id: "percentage", name: "Percentage" },
          ]}
          validate={required()}
          formClassName={classes.couponType}
        />
        <NumberInput
          source="value"
          validate={[required(), minValue(0)]}
          formClassName={classes.value}
        />
        <div />
        <NumberInput
          source="minExpense"
          validate={[minValue(0), required()]}
          formClassName={classes.minExpense}
        />
        <NumberInput
          source="MaxExpense"
          validate={[minValue(0)]}
          formClassName={classes.maxExpense}
        />
        <div />
        <NumberInput
          label="Total Coupons"
          validate={[minValue(0), required()]}
          source="total"
          formClassName={classes.total}
        />
        <NumberInput
          source="perUserLimit"
          defaultValue={1}
          formClassName={classes.perUserLimit}
        />
        <div />
        <DateTimeInput
          source="startsAt"
          validate={required()}
          formClassName={classes.startsAt}
        />
        <DateTimeInput
          source="expiresAt"
          validate={required()}
          formClassName={classes.expiresAt}
        />
        <SectionTitle label="resources.coupons.fieldGroups.options" />
        <VisibleInput label="Visible" source="isVisible" />
      </SimpleForm>
    </Create>
  )
}
