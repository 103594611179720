import SlideshowIcon from "@material-ui/icons/Slideshow"
import { SliderCMSCreate } from "./SliderCMSCreate"
import { SliderCMSEdit } from "./SliderCMSEdit"
import { SliderCMSList } from "./SliderCMSList"

export default {
  list: SliderCMSList,
  create: SliderCMSCreate,
  edit: SliderCMSEdit,
  icon: SlideshowIcon,
}
