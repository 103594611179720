import GroupIcon from "@material-ui/icons/Group"
import { RoleCreate } from "./RoleCreate"
import { RoleEdit } from "./RoleEdit"
import { RoleList } from "./RoleList"

export default {
  list: RoleList,
  create: RoleCreate,
  edit: RoleEdit,
  icon: GroupIcon,
}
