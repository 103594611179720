import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanField,
  NumberField,
  FilterProps,
  ListProps,
} from "react-admin"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

const CouponFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

export const CouponList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<CouponFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="title" />
        <TextField source="couponType" />
        <NumberField source="value" />
        <DateField source="startsAt" showTime />
        <DateField source="expiresAt" showTime />
        <BooleanField
          label="Visible"
          source="isVisible"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
