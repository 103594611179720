import * as React from "react"
import { FC, useState } from "react"
import {
  BooleanInput,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FieldProps,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
} from "react-admin"

import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"
import { IDField } from "../../common/form"
import ListActions from "../../common/listActions"
import RecoverButton from "../../common/RecoverButton"
import { Brand } from "../../ts/types"

const BrandFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <BooleanInput label="Featured" source="isFeatured" />
    <BooleanInput label="Visible" source="isVisible" />
  </Filter>
)

const DeleteActionButtons = (props) => {
  const { deletefilter } = props
  return (
    deletefilter && (
      <>
        <DeletePermanentlyButton
          label="Delete brand permanently"
          resourceLabel="Brand"
          {...props}
        />
        <RecoverButton {...props} />
      </>
    )
  )
}

const MyImageField: FC<FieldProps<Brand>> = ({ record }) => {
  return (
    <div>
      <img
        src={`${process.env.REACT_APP_AWS_BUCKET_URL}${record?.image}`}
        width="auto"
        height={75}
      />
    </div>
  )
}

export const BrandList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<BrandFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<DeleteActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="title" />
        <TextField source="slug" />
        <MyImageField source="image" />
        <DateField source="updatedAt" />
        {/* <BooleanField label="Featured" source="isFeatured" />
        <BooleanField
          label="Visible"
          source="isVisible"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        /> */}
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
