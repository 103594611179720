import * as React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { fetchEnd, fetchStart, useTranslate, useVersion } from "react-admin"
import MiniCard from "./MiniCard"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { useDispatch } from "react-redux"

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
  statsNumber: { marginRight: "0.4em", marginLeft: "0.4em", fontSize: "1.8em" },
  flexChild: { flex: "1 0 30%", margin: "5px" },
}

const Spacer = () => <span style={{ width: "1em" }} />

const VerticalSpacer = () => <span style={{ height: "1em" }} />

interface State {
  vendorPayable?: number
  totalPayable?: number
  totalReceivableFromDeliveryBoy?: number
  totalReceivable?: number
}

const Accounting = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const version = useVersion()

  const [state, setState] = useState<State>({})

  const fetchStats = () => {
    dispatch(fetchStart())
    const request = new Request("api/admin/accounting/stats", {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    fetch(request)
      .then((res) => {
        if (res.ok) return res.json()
        else console.error("Not fetching")
      })
      .then((data) => {
        const {
          vendorPayable,
          totalPayable,
          totalReceivableFromDeliveryBoy,
          totalReceivable,
        } = data
        setState((state) => ({
          ...state,
          vendorPayable,
          totalPayable,
          totalReceivableFromDeliveryBoy,
          totalReceivable,
        }))
      })
      .finally(() => {
        dispatch(fetchEnd())
      })
  }

  useEffect(() => {
    fetchStats()
  }, [version])

  const {
    vendorPayable,
    totalPayable,
    totalReceivableFromDeliveryBoy,
    totalReceivable,
  } = state

  return (
    <div>
      <br />
      {/* <Typography
        variant="h6"
        gutterBottom
        align="center"
        style={{ textTransform: "uppercase" }}
      >
        Accounting
      </Typography> */}
      <div>
        <div style={styles.flex}>
          <div style={styles.leftCol}>
            <div style={styles.flex}>
              <MiniCard
                to={`/accounting/payable`}
                title={translate("pos.accountings.totalPayable")}
                icon={MonetizationOnIcon}
                value={`$${totalPayable ? totalPayable.toFixed(2) : `0.00`}`}
              />
              <Spacer />
              <MiniCard
                to={`/accounting/receivable`}
                title={translate("pos.accountings.totalReceivable")}
                icon={MonetizationOnIcon}
                value={`$${
                  totalReceivable ? totalReceivable.toFixed(2) : `0.00`
                }`}
              />
              {/* <Spacer />
              <MiniCard
                to={`orders/orders?filter={"orderStatus":"pending"}`}
                title={translate("pos.dashboard.shippings")}
                icon={MonetizationOnIcon}
                value={`$${0}`}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <br />
      <Typography
        variant="h6"
        gutterBottom
        align="center"
        style={{ textTransform: "uppercase" }}
      >
        Reports
      </Typography>
      <div style={{ ...styles.flex, flexWrap: "wrap" }}>
        <Button
          variant="outlined"
          style={styles.flexChild}
          component={Link}
          to="/accounting/sales"
          value="/accounting/sales"
        >
          Sales Report <ExitToAppIcon />
        </Button>
        <Button
          variant="outlined"
          style={styles.flexChild}
          component={Link}
          to="/accounting/vat-bill"
          value="/accounting/vat-bill"
        >
          Vat bill report <ExitToAppIcon />
        </Button>
        <Button
          variant="outlined"
          style={styles.flexChild}
          component={Link}
          to="/accounting/cancelled-orders"
          value="/accounting/cancled-orders"
        >
          Cancelled orders report <ExitToAppIcon />
        </Button>
        {/* <Button variant="outlined" style={styles.flexChild}>
          Monthly sales summary by customer <ExitToAppIcon />
        </Button> */}
      </div>
    </div>
  )
}

export default Accounting
