import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  name: { display: "inline-block", marginLeft: 32 },
  permissions: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const RoleTitle = ({ record }: any) => (
  <span>Edit Role {record ? `"${record.name}"` : `#${record.id}`}</span>
)

export const RoleEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  const condition = (val: string) => !!val && val.trim().length > 10
  return (
    <Edit title={<RoleTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.roles.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <ReferenceArrayInput
          label="Permissions"
          source="permissions"
          reference="users/permissions"
          sort={{ field: "name", order: "ASC" }}
          perPage={500}
          filterToQuery={(val: string) =>
            condition(val) ? { title: val.trim() } : {}
          }
          fullWidth
          formClassName={classes.permissions}
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <SectionTitle label="resources.roles.fieldGroups.options" />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
