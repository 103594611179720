import * as React from "react"
import { FC, memo } from "react"
import { Avatar, Chip } from "@material-ui/core"
import { FieldProps } from "react-admin"

const BrandField: FC<FieldProps> = ({ record }) => {
  return record && record?.title ? (
    <Chip
      label={record && record.title}
      avatar={
        record.image ? (
          <Avatar
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}${record.image}`}
          />
        ) : (
          <Avatar>{record.title.charAt(0)}</Avatar>
        )
      }
      clickable
      //   color="primary"
      //   variant="outlined"
    />
  ) : null
}

export default memo<FieldProps>(BrandField)
