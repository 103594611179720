import React, { useState } from "react"
import {
  AutocompleteInput,
  Button,
  FormWithRedirect,
  ReferenceInput,
  SaveButton,
  TextInput,
  useNotify,
  useRefresh,
  useUpdate,
} from "react-admin"

import { Box } from "@material-ui/core"
import { blue } from "@material-ui/core/colors"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconCancel from "@material-ui/icons/Cancel"
import CreateIcon from "@material-ui/icons/Create"

function UserShippingUpdateButton({ location, record }) {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()

  const [update, { loading }] = useUpdate("user-shippings")
  const [updateOrder, { loading: orderUpdateLoading }] = useUpdate("orders")

  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async (values) => {
    updateOrder("orders/orders", record.id, {
      ...record,
      userShippingAreaId: values.areaId,
    })
    update(
      "user-shippings",
      location._id,
      { ...values },
      {},
      {
        onSuccess: () => {
          setShowDialog(false)
          refresh()
          notify("User location updated.")
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Update User Location"
        style={{ color: blue[700], margin: "4px 0" }}
        variant="outlined"
      >
        <CreateIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Update user location"
      >
        <DialogTitle>Update user location</DialogTitle>

        <FormWithRedirect
          resource="user-shippings"
          save={handleSubmit}
          initialValues={location}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <TextInput source="shippingTitle" fullWidth />
                <ReferenceInput
                  source="areaId"
                  reference="shippings/areas"
                  fullWidth
                >
                  <AutocompleteInput optionText="name" optionValue="id" />
                </ReferenceInput>
                <TextInput source="landmark" fullWidth />
                <Box display="flex" gridGap={20}>
                  <Box flex={1}>
                    <TextInput source="from" fullWidth />
                  </Box>
                  <Box flex={1}>
                    <TextInput source="to" fullWidth />
                  </Box>
                </Box>
                <TextInput source="additionalInfo" fullWidth />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  //   @ts-ignore
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default UserShippingUpdateButton
