import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  SearchInput,
  Filter,
  BooleanInput,
  ReferenceField,
  FilterProps,
  ListProps,
  BulkDeleteButton,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"

// const condition = (val: string) => !!val && val.trim().length > 6

const TransportFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    {/* <ReferenceInput
      source="district"
      reference="shippings/districts"
      perPage={100}
      alwaysOn
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={(val: string) =>
        condition(val) ? { title: val.trim() } : {}
      }
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
  </Filter>
)

const TransportActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <DeletePermanentlyButton
          label="Delete Transport method permanently"
          resourceLabel="Transport"
          {...props}
        />
      )}
    </>
  )
}

export const TransportList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<TransportFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<TransportActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <TextField source="name" />
        <TextField source="contactPerson" />
        <TextField source="phone" />
        <TextField source="address" />
        <DateField source="updatedAt" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
