import * as React from "react"
import {
  AutocompleteArrayInput,
  Create,
  CreateProps,
  InputProps,
  NumberInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  useCreateController,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin"
import { FieldArray } from "react-final-form-arrays"

import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import { requiredValidate, SectionTitle } from "../../common/form"

const styles = {
  name: { width: 544 },
  address: { width: 544 },
  latitude: { display: "inline-block" },
  longitude: { display: "inline-block", marginLeft: 32 },
  contactPerson: { width: 544 },
  districts: { width: 544 },
  boolean: { display: "inline-block" },
  branch: { width: 544 },
}

const useStyles = makeStyles(styles)

const BranchInput: React.FC<InputProps> = ({ source }) => {
  return (
    <>
      <FieldArray name={source}>
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <Box
                key={index}
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "0.5rem",
                  marginBottom: "0.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Typography variant="h6">Branch #{index + 1}</Typography>
                <Box display="flex" gridGap={32}>
                  <Box flex={1}>
                    <TextInput
                      source={`${name}.address`}
                      label="Address"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source={`${name}.contactPerson`}
                      label="Contact Person"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box display="flex" gridGap={32}>
                  <Box flex={1}>
                    <TextInput
                      source={`${name}.phone`}
                      label="Phone"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <IconButton
                      type="button"
                      onClick={() => fields.remove(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ))}
            <Button
              onClick={() =>
                fields.push({
                  name: "",
                  address: "",
                  contactPerson: "",
                })
              }
            >
              <AddIcon /> Add branch
            </Button>
          </>
        )}
      </FieldArray>
    </>
  )
}

export const TransportCreate: React.FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "transport/transport",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Transport saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.transport.fieldGroups.information" />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <TextInput
          source="address"
          fullWidth
          formClassName={classes.address}
          validate={requiredValidate}
        />
        <TextInput source="phone" validate={requiredValidate} />
        <TextInput
          source="location.latitude"
          label="Latitude"
          formClassName={classes.latitude}
        />
        <TextInput
          source="location.longitude"
          label="Longitude"
          formClassName={classes.longitude}
        />
        <TextInput
          source="contactPerson"
          fullWidth
          formClassName={classes.contactPerson}
        />

        <ReferenceArrayInput
          label="District"
          source="districts"
          reference="shippings/districts"
          validate={requiredValidate}
          sort={{ field: "name", order: "ASC" }}
          perPage={20}
          fullWidth
          formClassName={classes.districts}
        >
          <AutocompleteArrayInput optionText="name" optionValue="id" />
        </ReferenceArrayInput>
        <BranchInput source="branches" formClassName={classes.branch} />
      </SimpleForm>
    </Create>
  )
}
