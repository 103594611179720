import { FC } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  Link,
  List,
  ListProps,
  NumberField,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin"

import { Chip } from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"

import { paymentRequest, paymentStatus } from "./common"
import MakePaymentButton from "./MakePaymentButton"
import SaleAside from "./SaleAside"

const rowStyle = (record: any) => {
  let style = {}
  if (!record) {
    return style
  }
  if (record.paymentStatus.status === true)
    style = {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.paymentStatus.status === false)
    style = {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  return style
}

const SaleFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="vendorId"
      reference="users/users"
      filter={{ role: "VENDOR" }}
      alwaysOn
    >
      <AutocompleteInput optionValue="id" optionText="name" />
    </ReferenceInput>
    <DateInput source="dateGte" label="Date from" alwaysOn />
    <DateInput source="dateLt" label="Date to" alwaysOn />

    <BooleanInput label="Payment Status" source="paymentStatus.status" />
    <BooleanInput label="Request for payment" source="paymentRequest.status" />
    <SelectInput
      source="type"
      choices={[
        { id: "sale", name: "Sale" },
        { id: "sale_return", name: "Sale Return" },
        { id: "purchase", name: "Purchase" },
        { id: "purchase_return", name: "Purchase Return" },
      ]}
    />
  </Filter>
)

const SalePagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const SalePaymentUpdateActionButtons = (props) => (
  <MakePaymentButton lable="Make payment" {...props} />
)

const LinkField = ({ source, field, reference, value, label }: any) => (
  <FunctionField
    source={source}
    label={label}
    render={(record) =>
      record &&
      record[field] && (
        <Link to={`/${reference}/${record[field].id}`}>
          {record[field][value]}
        </Link>
      )
    }
  />
)

export const SaleList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={<SalePaymentUpdateActionButtons />}
      filter={{ "meta.isDeleted": false }}
      filters={<SaleFilter />}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={25}
      pagination={<SalePagination />}
      aside={<SaleAside />}
    >
      <Datagrid rowStyle={rowStyle}>
        <DateField source="createdAt" label="Sales at" showTime />
        <TextField source="type" style={{ textTransform: "uppercase" }} />
        <LinkField
          source="product.title"
          label="Product"
          field="product"
          reference="products/products"
          value="title"
        />
        <LinkField
          source="user.name"
          label="User"
          field="user"
          reference="users/users"
          value="name"
        />
        <LinkField
          source="vendor.name"
          label="Vendor"
          field="vendor"
          reference="users/users"
          value="name"
        />
        <LinkField
          source="order.id"
          label="Order"
          field="order"
          reference="orders/orders"
          value="orderNumber"
        />
        <FunctionField
          label="Request for payment"
          source="paymentRequest.status"
          render={(record) => (
            <Chip
              label={
                paymentRequest[
                  paymentRequest
                    .map((item) => item.id)
                    .indexOf(record?.paymentRequest?.status)
                ]?.name
              }
              icon={
                paymentRequest[
                  paymentRequest
                    .map((item) => item.id)
                    .indexOf(record?.paymentRequest?.status)
                ]?.icon
              }
              style={{
                backgroundColor:
                  paymentRequest[
                    paymentRequest
                      .map((item) => item.id)
                      .indexOf(record?.paymentRequest?.status)
                  ]?.color,
              }}
            />
          )}
        />
        <FunctionField
          label="Payment to Vendor"
          source="paymentStatus.status"
          render={(record) => (
            <Chip
              label={
                paymentStatus[
                  paymentStatus
                    .map((item) => item.id)
                    .indexOf(record?.paymentStatus?.status)
                ]?.name
              }
              icon={
                paymentStatus[
                  paymentStatus
                    .map((item) => item.id)
                    .indexOf(record?.paymentStatus?.status)
                ]?.icon
              }
              style={{
                backgroundColor:
                  paymentStatus[
                    paymentStatus
                      .map((item) => item.id)
                      .indexOf(record?.paymentStatus?.status)
                  ]?.color,
              }}
            />
          )}
        />
        <FunctionField
          label="Refund Payment from Vendor"
          source="refundPaymentFromVendor.status"
          render={(record) =>
            record?.refundFromVendorStatus ? (
              <Chip
                label={
                  paymentStatus[
                    paymentStatus
                      .map((item) => item.id)
                      .indexOf(record?.refundFromVendorStatus?.status)
                  ]?.name
                }
                icon={
                  paymentStatus[
                    paymentStatus
                      .map((item) => item.id)
                      .indexOf(record?.refundFromVendorStatus?.status)
                  ]?.icon
                }
                style={{
                  backgroundColor:
                    paymentStatus[
                      paymentStatus
                        .map((item) => item.id)
                        .indexOf(record?.paymentStatus?.status)
                    ]?.color,
                }}
              />
            ) : (
              `N/A`
            )
          }
        />
        <FunctionField
          label="Refund Payment to Customer"
          source="refundPaymentStatus.status"
          render={(record) =>
            record?.type === "sale_return" ? (
              <Chip
                label={
                  paymentStatus[
                    paymentStatus
                      .map((item) => item.id)
                      .indexOf(record?.refundPaymentStatus?.status)
                  ]?.name
                }
                icon={
                  paymentStatus[
                    paymentStatus
                      .map((item) => item.id)
                      .indexOf(record?.refundPaymentStatus?.status)
                  ]?.icon
                }
                style={{
                  backgroundColor:
                    paymentStatus[
                      paymentStatus
                        .map((item) => item.id)
                        .indexOf(record?.refundPaymentStatus?.status)
                    ]?.color,
                }}
              />
            ) : (
              `N/A`
            )
          }
        />
        {/* <VariationField label="Variation" /> */}
        <NumberField source="quantity" />
        <FunctionField
          label="Margin"
          render={(record) =>
            record.margin ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.margin.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : null
          }
        />
        {/* <FunctionField
          label="Vendor Payable"
          render={(record) =>
            record.vendorPayable ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {record.vendorPayable.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            ) : null
          }
        /> */}
        <FunctionField
          label="Price"
          render={(record) =>
            record.vendorPayable && record.margin ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {(record.vendorPayable + record.margin).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </span>
            ) : null
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
