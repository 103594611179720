import React from "react"
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import SettingsIcon from "@material-ui/icons/Settings"
import GroupAddIcon from "@material-ui/icons/GroupAdd"
import VideoLabelIcon from "@material-ui/icons/VideoLabel"
import StepConnector from "@material-ui/core/StepConnector"
import { StepIconProps } from "@material-ui/core/StepIcon"
import {
  Card,
  CardHeader,
  CardContent,
  List as MuiList,
  ListItem,
  Typography,
} from "@material-ui/core"
import {
  AirportShuttle,
  Announcement,
  Cancel,
  Check,
  CheckCircle,
  Close,
  DoneAll,
  KeyboardReturn,
  Reorder,
  SportsHandball,
} from "@material-ui/icons"
import { orange, red } from "@material-ui/core/colors"

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
})

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <Reorder />,
    2: <Check />,
    3: <DoneAll />,
    4: <SportsHandball />,
    5: <AirportShuttle />,
    6: <CheckCircle />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statsNumber: {
      marginRight: "0.4em",
      marginLeft: "0.4em",
      fontSize: "1.8em",
    },
  })
)

export default function OrderStatusSteppers({ totals }: any) {
  const classes = useStyles()
  const orderStatus = [
    { id: "pending", name: "New Orders" },
    { id: "confirmed", name: "Confirmed" },
    { id: "approve", name: "Approve" },
    { id: "pickup", name: "Pickup" },
    { id: "delivering", name: "Delivering" },
    { id: "delivered", name: "Delivered" },
    // { id: "cancelled", name: "Cancelled" },
    // { id: "defect", name: "Defect" },
    // { id: "refund", name: "Refund" }
  ]

  return (
    <Card>
      <CardHeader title="Order Processing" />
      <CardContent>
        <Stepper
          alternativeLabel
          activeStep={7}
          connector={<ColorlibConnector />}
        >
          {orderStatus.map((status) => (
            <Step key={status.id}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {status.name} ({totals[status.id]})
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <MuiList>
          <ListItem>
            <Cancel htmlColor={red[500]} />
            <Typography className={classes.statsNumber}>
              {totals["cancelled"]}
            </Typography>
            <Typography>Cancelled</Typography>
          </ListItem>
          <ListItem>
            <Announcement htmlColor={orange[500]} />
            <Typography className={classes.statsNumber}>
              {totals["defect"]}
            </Typography>
            <Typography>Defect</Typography>
          </ListItem>
          <ListItem>
            <KeyboardReturn htmlColor={red[500]} />
            <Typography className={classes.statsNumber}>
              {totals["refund"]}
            </Typography>
            <Typography>Refund</Typography>
          </ListItem>
        </MuiList>
      </CardContent>
    </Card>
  )
}
