import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Button, Link, useNotify, useRefresh } from "react-admin"

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import IconCancel from "@material-ui/icons/Cancel"
import LinkIcon from "@material-ui/icons/Link"
import SaveIcon from "@material-ui/icons/Save"

import BarcodeScanner from "./BarcodeScanner"

const API_URL =
  process.env.NODE_ENV == "production"
    ? process.env.REACT_APP_BASE_URL
    : "http://localhost:4000"

const useStyles = makeStyles((theme) => ({
  main: {},
  item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  productImage: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
}))

const PackagingBoxForm = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [barcode, setBarcode] = useState("")
  const [orderItem, setOrderItem] = useState<any>(null)

  const notify = useNotify()

  const fetchOrderItem = useCallback(
    async (barcode: string) => {
      if (barcode.length < 4) return
      try {
        const res = await fetch(
          `${API_URL}/api/admin/oms/boxes/scan/${barcode}`
        )
        if (res.status === 200) {
          const data = await res.json()
          setOrderItem(data)
          setShowDialog(true)
        } else {
          const data = await res.json()
          setOrderItem(null)
          if (data?.errors) {
            notify(data.errors[0].message)
          }
        }
      } catch (error) {
        console.error(error)
        setOrderItem(null)
      }
    },
    [barcode]
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    fetchOrderItem(barcode)
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box display="flex" gridGap={10}>
          <BarcodeScanner barcode={barcode} setBarcode={setBarcode} />
          <Button
            variant="contained"
            type="submit"
            size="small"
            color="primary"
            label="Submit"
          />
        </Box>
      </form>
      <ProductItemDialog
        barcode={barcode}
        setBarcode={setBarcode}
        orderItem={orderItem}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </div>
  )
}

const QuantityInput = ({ quantity, remainingQuantity, setQuantity }) => {
  const [error, setError] = useState({ errorText: "", error: false })

  const onChangeQuantity = (event) => {
    const value = event.target.value
    if (value < 0) {
      setError({ errorText: "Quantity cannot be negative", error: true })
    } else if (value > remainingQuantity) {
      setError({
        errorText: "Qty can't be greater than ordered quantity.",
        error: true,
      })
    } else if (value <= remainingQuantity) {
      setError({ errorText: "", error: false })
      setQuantity(event.target.value)
    } else {
      setQuantity(event.target.value)
    }
  }

  return (
    <div>
      <TextField
        autoFocus={true}
        name="quantity"
        type="number"
        label="Quantity"
        variant="outlined"
        size="small"
        defaultValue={1}
        onChange={onChangeQuantity}
        error={error.error ? true : false}
        helperText={error.errorText}
      />
    </div>
  )
}

const ProductItemDialog = ({
  barcode,
  setBarcode,
  orderItem,
  showDialog,
  setShowDialog,
}) => {
  const [quantity, setQuantity] = useState(1)
  const refresh = useRefresh()

  const classes = useStyles()

  console.log("OrderItem", orderItem)

  const notify = useNotify()

  const postOms = useCallback(async () => {
    try {
      const res = await fetch(
        `${API_URL}/api/admin/oms/boxes/add-item-barcode`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ barcode, quantity }),
        }
      )
      if (res.status === 201 || res.status === 200) {
        setShowDialog(false)
        refresh()
        notify("Item entried successfully")
        setBarcode("")
        setQuantity(1)
        document.getElementById("barcode")?.focus()
      } else {
        const data = await res.json()
        if (data?.errors) {
          notify(data.errors[0].message, "error")
        }
      }
    } catch (error) {
      console.error(error)
      // setOrderItem(null)
    }
  }, [barcode, quantity])

  const handleSubmit = (e) => {
    e.preventDefault()
    postOms()
  }

  const handleDialogClose = () => {
    // setOrderItem(null)
    setShowDialog(false)
    setBarcode("")
    setQuantity(1)
    document.getElementById("barcode")?.focus()
  }

  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Tracking #{orderItem?.order?.trackingNumber}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Paper className={classes.item}>
            <Box>
              <Link to={`/products/products/${orderItem?.item?.product?.id}`}>
                {/* <Avatar
                  src={
                    process.env.REACT_APP_AWS_BUCKET_URL +
                    orderItem?.product?.media[0]
                  }
                  className={classes.productImage}
                /> */}
                <Typography variant="h6" gutterBottom>
                  {orderItem?.item?.product?.title}
                </Typography>
                <img
                  className={classes.productImage}
                  src={
                    process.env.REACT_APP_AWS_BUCKET_URL +
                    orderItem?.item?.product?.media[0]
                  }
                />
              </Link>
            </Box>
            <Box>
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <Typography variant="body1" gutterBottom>
                Ordered Quantity: <strong>{orderItem?.quantity}</strong>
              </Typography>
            </Box>
            <QuantityInput
              remainingQuantity={orderItem?.quantity}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleDialogClose}
            startIcon={<IconCancel />}
          />
          <Button
            label="Submit"
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}
            // onClick={handleSubmit}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default PackagingBoxForm
