import * as React from "react"
import { FC } from "react"

import CardWithIcon from "./CardWithIcon"

interface Props {
  value?: string
  value2?: string
  icon?: any
  title: string
  style?: object
  to?: string
}

const MiniCard: FC<Props> = ({
  value,
  icon,
  title,
  style,
  value2,
  to = "/",
}) => {
  return (
    <CardWithIcon
      to={to}
      icon={icon}
      title={title}
      subtitle={value}
      subtitle2={value2}
      style={style}
    />
  )
}

export default MiniCard
