import { makeStyles, Typography } from "@material-ui/core"
import * as React from "react"
import {
  DateField,
  Edit,
  EditProps,
  ImageInput,
  InputProps,
  Link,
  List,
  Loading,
  ReferenceArrayField,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core"
import VendorPaymentImageField from "./VendorPaymentImageField"

const useStyles = makeStyles({
  vendor: { display: "inline-block" },
  paymentDate: { display: "inline-block", marginLeft: 32 },
  paymentMethod: { display: "inline-block", width: 256 },
  amount: { display: "inline-block", width: 256, marginLeft: 32 },
  paymentReference: { display: "inline-block", width: 256 },
  paymentNote: { display: "inline-block", width: 256, marginLeft: 32 },
  otherDetails: { width: 544 },
  orderItems: { width: 544 },
  image: { width: 544 },
})

const OrderItemList: React.FC<Omit<InputProps, "source">> = ({ record }) => {
  const { orderItems } = record
  if (!orderItems) return <Loading />

  return (
    <>
      <Typography variant="h6">Order Item List</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderItems?.map((orderItem) => (
            <TableRow key={orderItem.id}>
              <TableCell>
                <Link to={`/orders/items/${orderItem.id}`}>
                  {orderItem.id
                    .toString()
                    .slice(orderItem.id.toString().length - 5)}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={`/products/products/${orderItem.product?.id}`}>
                  {orderItem.product?.title}
                </Link>
              </TableCell>
              <TableCell>{orderItem.quantity}</TableCell>
              <TableCell>
                <strong>{orderItem.vendorPayablePrice}</strong>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell>
              <strong>
                {orderItems
                  ?.reduce((acc, cur) => {
                    if (cur.status == "delivered")
                      return acc + cur.vendorPayablePrice
                    else return acc
                  }, 0)
                  .toFixed(2)}
              </strong>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}

export const VendorPaymentEdit: React.FC<EditProps> = (props) => {
  const classes = useStyles()
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField
          source="vendorId"
          reference="users/users"
          fullWidth
          formClassName={classes.vendor}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          source="paymentDate"
          showTime
          fullWidth
          formClassName={classes.paymentDate}
        />
        <span />
        <TextInput
          source="paymentMethod"
          fullWidth
          formClassName={classes.paymentMethod}
        />
        <TextInput source="amount" fullWidth formClassName={classes.amount} />
        <span />
        <TextInput
          source="paymentReference"
          fullWidth
          formClassName={classes.paymentReference}
        />
        <TextInput
          source="paymentNote"
          fullWidth
          formClassName={classes.paymentNote}
        />
        <span />
        <TextInput
          source="otherDetails"
          fullWidth
          formClassName={classes.otherDetails}
        />
        <ImageInput
          source="paymentImage"
          label="Brand image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <VendorPaymentImageField />
        </ImageInput>
        <OrderItemList formClassName={classes.orderItems} />
      </SimpleForm>
    </Edit>
  )
}
