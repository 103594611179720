import * as React from "react"
import { FC } from "react"
import {
  List,
  Datagrid,
  BooleanField,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  Filter,
  BooleanInput,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  FieldProps,
  NumberField,
  FilterProps,
  BulkDeleteButton,
} from "react-admin"
import VisibilityIcon from "@material-ui/icons/Visibility"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import ListActions from "../../common/listActions"
import { IDField } from "../../common/form"
import { ListProps } from "@material-ui/core"
import DeletePermanentlyButton from "../../common/DeletePermanentlyButton"

const ShippingFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <BooleanInput label="Status" source="status" />
  </Filter>
)

const ShippingActionButtons = (props) => {
  const { deletefilter } = props
  return (
    <>
      {!deletefilter && <BulkDeleteButton {...props} />}
      {deletefilter && (
        <DeletePermanentlyButton
          label="Delete shipping permanently"
          resourceLabel="Shipping"
          {...props}
        />
      )}
    </>
  )
}

interface ParameterProps extends FieldProps {
  record?: any
}

const ParameterField: FC<ParameterProps> = ({ record }) => {
  return (
    <div>
      {record.parameter}: {record.parameterMin}-{record.parameterMax}
    </div>
  )
}

interface DeliveryProps extends FieldProps {
  record?: any
}

const DeliveryField: FC<DeliveryProps> = ({ record }) => {
  return (
    <div>
      {record.deliveryTimeType}: {record.minDeliveryTime}-
      {record.maxDeliveryTime}
    </div>
  )
}

export const ShippingList: FC<ListProps> = (props) => {
  const [deletefilter, setdeletefilter] = React.useState(false)

  const updateDeleteFilter = () => {
    setdeletefilter(!deletefilter)
  }

  return (
    <List
      {...props}
      filter={{ "meta.isDeleted": deletefilter }}
      filters={<ShippingFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={<ShippingActionButtons deletefilter={deletefilter} />}
      actions={
        <ListActions
          deletefilter={deletefilter}
          updatedeletefilter={updateDeleteFilter}
        />
      }
    >
      <Datagrid rowClick="edit">
        <IDField sortable={false} source="id" />
        <ReferenceField
          label="Method"
          source="method"
          reference="shippings/methods"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          source="clusters"
          reference="shippings/area-clusters"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="updatedAt" />
        <ParameterField label="Parameter" />
        <DeliveryField label="Delivery" />
        <NumberField source="cost" />
        <BooleanField
          label="Status"
          source="status"
          TrueIcon={VisibilityIcon}
          FalseIcon={VisibilityOffIcon}
        />
        <BooleanField label="Cash on Delivery" source="cod" />
        <EditButton />
        {!deletefilter ? <DeleteButton /> : <></>}
      </Datagrid>
    </List>
  )
}
