import LocationCityIcon from '@material-ui/icons/LocationCity';
import { AreaList } from "./AreaList"
import { AreaEdit } from "./AreaEdit"
import { AreaCreate } from "./AreaCreate"

export default {
  list: AreaList,
  edit: AreaEdit,
  create: AreaCreate,
  icon: LocationCityIcon,
}
