import * as React from "react"
import { FC, cloneElement } from "react"
import {
  useListContext,
  TopToolbar,
  ExportButton,
  CreateButton,
  Button,
  sanitizeListRestProps,
  ListActionsProps,
} from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined"

const useStyles = makeStyles({
  button: {
    backgroundColor: "#DFE7FD",
  },
})

interface ListActions extends ListActionsProps {
  maxResults?: number
  deletefilter: boolean
  updatedeletefilter: any
}

const ListActions: FC<ListActions> = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    deletefilter,
    updatedeletefilter,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()

  const styles = useStyles()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {/* Add your custom actions */}
      <Button
        onClick={updatedeletefilter}
        label="Trash Bin"
        className={deletefilter ? styles.button : ""}
      >
        <DeleteSweepOutlinedIcon />
      </Button>
    </TopToolbar>
  )
}

export default ListActions
