import * as React from "react"
import { Route } from "react-router-dom"
import Accounting from "../components/Accounting"
import AccountingPayble from "../components/AccountingPayable"
import AccountingVatBill from "../components/AccountingVatBill"
import AccountingCancelledOrders from "../components/AccountingCancelledOrders"
import Configuration from "../configuration/Configuration"
import Sale from "../resources/sales/Sale"
import AccountingReceivable from "../components/AccountingReceiveable"
import OMS from "../components/OMS"
import PackagingBox from "../components/PackagingBox"

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route exact key="oms" path="/oms" component={OMS} />,
  <Route exact path="/oms/scan/:trackingNumber" component={PackagingBox} />,
  <Route exact path="/configuration" render={() => <Configuration />} />,
  <Route path="/accounting/sales" component={Sale} />,
  <Route path="/accounting/payable" component={AccountingPayble} />,
  <Route path="/accounting/receivable" component={AccountingReceivable} />,
  <Route path="/accounting/vat-bill" component={AccountingVatBill} />,
  <Route
    path="/accounting/cancelled-orders"
    component={AccountingCancelledOrders}
  />,
  <Route path="/accounting" component={Accounting} />,
]
