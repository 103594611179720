import { FC, useState } from "react"
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
  usePermissions,
  useTranslate,
} from "react-admin"
import { useSelector } from "react-redux"

import { Box, Theme, useMediaQuery } from "@material-ui/core"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn"
import ProductIcon from "@material-ui/icons/Collections"
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import MoneyOffIcon from "@material-ui/icons/MoneyOff"
import PaymentIcon from "@material-ui/icons/Payment"
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline"
import PostAddIcon from "@material-ui/icons/PostAdd"
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import SettingsIcon from "@material-ui/icons/Settings"
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox"

import agents from "../resources/agents"
import agentScheme from "../resources/agentScheme"
import areaCluster from "../resources/areaClusters"
import areas from "../resources/areas"
import attributes from "../resources/attributes"
import brands from "../resources/brands"
import categories from "../resources/categories"
import bannerCMS from "../resources/cms/banners"
import brandCMS from "../resources/cms/brands"
import categoryCMS from "../resources/cms/categories"
import menuCMS from "../resources/cms/menus"
import pageCMS from "../resources/cms/pages"
import productCMS from "../resources/cms/products"
import sliderCMS from "../resources/cms/sliders"
import countries from "../resources/countries"
import coupons from "../resources/coupons"
import creditSchemes from "../resources/creditSchemes"
import creditTransactions from "../resources/creditTransactions"
import discounts from "../resources/discounts"
import districts from "../resources/districts"
import notifications from "../resources/notifications"
import orderItems from "../resources/orderItems"
import orderItemsV1 from "../resources/orderItems/v1"
import orders from "../resources/orders"
import permissionsResource from "../resources/permissions"
import products from "../resources/products"
import reviews from "../resources/reviews"
import roles from "../resources/roles"
import sales from "../resources/sales"
import shippingMethods from "../resources/shippingMethods"
import shippings from "../resources/shippings"
import specialDiscountRequest from "../resources/specialDiscountRequest"
import specialDiscounts from "../resources/specialDiscounts"
import states from "../resources/states"
import tags from "../resources/tags"
import UrlShortner from "../resources/url-shortner"
import users from "../resources/users"
import SubMenu from "./SubMenu"

type MenuName =
  | "menuUsers"
  | "menuAgents"
  | "menuProducts"
  | "menuDiscounts"
  | "menuShippings"
  | "menuOrders"
  | "menuCMS"
  | "menuCredits"

const Menu: FC<MenuProps> = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    menuUsers: false,
    menuAgents: false,
    menuProducts: false,
    menuDiscounts: false,
    menuShippings: false,
    menuOrders: false,
    menuCMS: false,
    menuCredits: false,
  })
  const translate = useTranslate()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
  //   useSelector((state: AppState) => state.theme) // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  const { permissions } = usePermissions()

  const hasPerm = (perm: string) => {
    if (permissions.roles.includes("ADMIN")) return true
    if (permissions.permissions.includes(perm)) return true
    return false
  }

  return (
    <Box mt={1}>
      {permissions?.roles.includes("ADMIN") ||
      permissions?.roles.includes("STAFF") ||
      permissions?.roles.includes("DATA_ENTRY") ? (
        <>
          <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
          {/* @ts-ignore */}
          <MenuItemLink
            to="/oms"
            primaryText={translate("resources.oms.name", {
              smart_count: 2,
            })}
            leftIcon={<AssignmentTurnedInIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {/* @ts-ignore */}
          <MenuItemLink
            to="/oms/boxes"
            primaryText={translate("resources.packagingBox.name", {
              smart_count: 2,
            })}
            leftIcon={<MarkunreadMailboxIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {/* @ts-ignore */}
          <MenuItemLink
            to={`/accounting`}
            primaryText={translate(`resources.accounting.name`, {
              smart_count: 2,
            })}
            leftIcon={<AccountBalanceIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {hasPerm("notification_read") && (
            // @ts-ignore
            <MenuItemLink
              to={`/notifications/notifications`}
              primaryText={translate(`resources.notifications.name`, {
                smart_count: 2,
              })}
              leftIcon={<notifications.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              // dense={dense}
            />
          )}
          {(hasPerm("permission_read") ||
            hasPerm("role_read") ||
            hasPerm("user_read") ||
            hasPerm("specialdiscountrequest_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuUsers")}
              isOpen={state.menuUsers}
              sidebarIsOpen={open}
              name="pos.menu.users"
              icon={<PeopleOutlineIcon />}
              dense={dense}
            >
              {hasPerm("permission_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/users/permissions`}
                  primaryText={translate(`resources.permissions.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<permissionsResource.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("role_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/users/roles`}
                  primaryText={translate(`resources.roles.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<roles.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("user_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/users/users`}
                  primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<users.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("specialdiscountrequest_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`users/special-discount-requests`}
                  primaryText={translate(
                    `resources.specialDiscountRequests.name`,
                    {
                      smart_count: 2,
                    }
                  )}
                  leftIcon={<specialDiscountRequest.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("agent_read") || hasPerm("agentscheme_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuAgents")}
              isOpen={state.menuAgents}
              sidebarIsOpen={open}
              name="pos.menu.agents"
              icon={<RecordVoiceOverIcon />}
              dense={dense}
            >
              {hasPerm("agent_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/agents/agents`}
                  primaryText={translate(`resources.agents.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<agents.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("agentscheme_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/agents/agentScheme`}
                  primaryText={translate(`resources.agentScheme.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<agentScheme.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("attribute_read") ||
            hasPerm("brand_read") ||
            hasPerm("category_read") ||
            hasPerm("tag_read") ||
            hasPerm("product_read") ||
            hasPerm("review_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuProducts")}
              isOpen={state.menuProducts}
              sidebarIsOpen={open}
              name="pos.menu.products"
              icon={<ProductIcon />}
              dense={dense}
            >
              {hasPerm("attribute_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/attributes`}
                  primaryText={translate(`resources.attributes.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<attributes.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("brand_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/brands`}
                  primaryText={translate(`resources.brands.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<brands.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("category_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/categories`}
                  primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<categories.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("tag_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/tags`}
                  primaryText={translate(`resources.tags.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<tags.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("product_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/products`}
                  primaryText={translate(`resources.products.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<products.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("review_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/reviews`}
                  primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<reviews.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("discount_read") ||
            hasPerm("coupon_read") ||
            hasPerm("specialdiscount_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuDiscounts")}
              isOpen={state.menuDiscounts}
              sidebarIsOpen={open}
              name="pos.menu.discounts"
              icon={<MoneyOffIcon />}
              dense={dense}
            >
              {hasPerm("discount_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/discounts`}
                  primaryText={translate(`resources.discounts.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<discounts.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("coupon_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/coupons`}
                  primaryText={translate(`resources.coupons.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<coupons.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("specialdiscount_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/products/specialDiscounts`}
                  primaryText={translate(`resources.specialDiscounts.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<specialDiscounts.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("country_read") ||
            hasPerm("state_read") ||
            hasPerm("district_read") ||
            hasPerm("area_read") ||
            hasPerm("areacluster_read") ||
            hasPerm("method_read") ||
            hasPerm("shipping_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuShippings")}
              isOpen={state.menuShippings}
              sidebarIsOpen={open}
              name="pos.menu.shippings"
              icon={<LocalShippingIcon />}
              dense={dense}
            >
              {hasPerm("country_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/countries`}
                  primaryText={translate(`resources.countries.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<countries.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("state_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/states`}
                  primaryText={translate(`resources.states.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<states.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("district_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/districts`}
                  primaryText={translate(`resources.districts.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<districts.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("area_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/areas`}
                  primaryText={translate(`resources.areas.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<areas.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("areacluster_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/area-clusters`}
                  primaryText={translate(`resources.areaCluster.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<areaCluster.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("method_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/methods`}
                  primaryText={translate(`resources.shippingMethods.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<shippingMethods.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("shipping_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/shippings/shippings`}
                  primaryText={translate(`resources.shippings.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<shippings.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("order_read") ||
            hasPerm("orderitem_read") ||
            hasPerm("sale_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuOrders")}
              isOpen={state.menuOrders}
              sidebarIsOpen={open}
              name="pos.menu.orders"
              icon={<AddShoppingCartIcon />}
              dense={dense}
            >
              {hasPerm("order_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/orders/orders`}
                  primaryText={translate(`resources.orders.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<orders.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("orderitem_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/orders/items/v1?filter={"status":"pending"}`}
                  primaryText={translate(`resources.orderItemsV1.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<orderItemsV1.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("orderitem_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/orders/items?filter={"status":"pending"}`}
                  primaryText={translate(`resources.orderItems.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<orderItems.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("sale_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/sales/sales`}
                  primaryText={translate(`resources.sales.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<sales.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("cmsbrand_read") ||
            hasPerm("cmscategory_read") ||
            hasPerm("cmsproduct_read") ||
            hasPerm("cmsslider_read") ||
            hasPerm("cmsmenu_read") ||
            hasPerm("cmspage_read") ||
            hasPerm("cmsbanner_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuCMS")}
              isOpen={state.menuCMS}
              sidebarIsOpen={open}
              name="pos.menu.cms"
              icon={<PostAddIcon />}
              dense={dense}
            >
              {hasPerm("cmsbrand_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/brands`}
                  primaryText={translate(`resources.brandCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<brandCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmscategory_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/categories`}
                  primaryText={translate(`resources.categoryCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<categoryCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmsproduct_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/products`}
                  primaryText={translate(`resources.productCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<productCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmsslider_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/sliders`}
                  primaryText={translate(`resources.sliderCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<sliderCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmsmenu_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/menus`}
                  primaryText={translate(`resources.menuCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<menuCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmspage_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/pages`}
                  primaryText={translate(`resources.pageCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<pageCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("cmsbanner_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/cms/banners`}
                  primaryText={translate(`resources.bannerCMS.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<bannerCMS.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {(hasPerm("creditscheme_read") ||
            hasPerm("credittransaction_read")) && (
            <SubMenu
              handleToggle={() => handleToggle("menuCredits")}
              isOpen={state.menuCredits}
              sidebarIsOpen={open}
              name="pos.menu.credits"
              icon={<creditSchemes.icon />}
              dense={dense}
            >
              {hasPerm("creditscheme_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/credit/schemes`}
                  primaryText={translate(`resources.creditSchemes.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<creditSchemes.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
              {hasPerm("credittransaction_read") && (
                // @ts-ignore
                <MenuItemLink
                  to={`/credit/transactions`}
                  primaryText={translate(`resources.creditTransactions.name`, {
                    smart_count: 2,
                  })}
                  leftIcon={<creditTransactions.icon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                />
              )}
            </SubMenu>
          )}
          {hasPerm("urlshortner_read") && (
            // @ts-ignore
            <MenuItemLink
              to={`/url-shortner`}
              primaryText={translate(`pos.menu.urlShortner`, {
                smart_count: 2,
              })}
              leftIcon={<UrlShortner.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {/* @ts-ignore */}
          <MenuItemLink
            to={`/transport/transport`}
            primaryText={translate(`resources.transport.name`, {
              smart_count: 2,
            })}
            leftIcon={<FlightTakeoffIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </>
      ) : null}
      {/* @ts-ignore */}
      <MenuItemLink
        to={`/vendor-payments`}
        primaryText={translate(`resources.vendorPayment.name`, {
          smart_count: 2,
        })}
        leftIcon={<PaymentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {isXSmall && (
        // @ts-ignore
        <MenuItemLink
          to="/configuration"
          primaryText={translate("pos.configuration")}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </Box>
  )
}

export default Menu
