import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  NumberInput,
  BooleanInput,
  ImageInput,
  SelectInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"
import SliderImageField from "./SliderImageField"

const styles = {
  id: { display: "inline-block" },
  title: { display: "inline-block", marginLeft: 32 },
  shortDescription: { width: 544 },
  link: { width: 544 },
  description: { width: 544 },
  target: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  image: { width: 544 },
  caption: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const SliderCMSTitle = ({ record }: any) => (
  <span>Edit SliderCMS {record ? `"${record.title}"` : `#${record.id}`}</span>
)

export const SliderCMSEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<SliderCMSTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.sliderCMS.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="title"
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="shortDescription"
          fullWidth
          formClassName={classes.shortDescription}
          multiline
        />
        <TextInput source="link" fullWidth formClassName={classes.link} />
        <NumberInput source="position" />
        <SectionTitle label="resources.sliderCMS.fieldGroups.mobile" />
        <SelectInput
          source="target"
          choices={[
            { id: "SearchDetails", name: "Search Details" },
            { id: "ShopByBrand", name: "Shop By Brand" },
            { id: "ProductDetail", name: "Product Detail" },
            { id: "ShopByCategory", name: "Shop By Category" },
          ]}
          formClassName={classes.target}
        />
        <TextInput source="value" formClassName={classes.value} />
        <SectionTitle label="resources.sliderCMS.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Slider image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <SliderImageField />
        </ImageInput>
        <BooleanInput
          source="keepOriginal"
          defaultValue={false}
          helperText="If enabled, the image won't be resized."
        />
        <TextInput source="caption" fullWidth formClassName={classes.caption} />
        <SectionTitle label="resources.sliderCMS.fieldGroups.option" />
        <BooleanInput source="isVisible" />
      </SimpleForm>
    </Edit>
  )
}
