import * as React from "react"
import { FC } from "react"
import { useCallback, useMemo } from "react"
import {
  useMutation,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useCreateController,
  ToolbarProps,
  CreateProps,
} from "react-admin"
import {
  makeStyles,
  Box,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core"
import { FieldArray } from "react-final-form-arrays"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
  values: { width: 544 },
  valueTitle: { display: "inline-block" },
  valueValue: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const AttributeCreateToolbar: FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save and Show" redirect="edit" submitOnEnter={true} />
      <SaveButton
        label="Save and Add"
        redirect={false}
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  )
}

interface RenderValuesInputProps {
  name: string
}

const RenderValueInput: FC<RenderValuesInputProps> = ({ name }) => {
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <div>
          {fields.map((name, index) => (
            <div key={name}>
              <Typography variant="subtitle2">
                {`Value #${index + 1}`}
              </Typography>
              <Box display="flex" mb="0.5em" width={544}>
                <Box flex={1} mr="0.5em">
                  <TextInput
                    source={`${name}.title`}
                    label="Title"
                    fullWidth
                    validate={requiredValidate}
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <TextInput
                    source={`${name}.value`}
                    label="Value"
                    fullWidth
                    validate={requiredValidate}
                  />
                </Box>

                <IconButton type="button" onClick={() => fields.remove(index)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </div>
          ))}
          <Button
            onClick={() =>
              fields.push({
                title: "",
                value: "",
              })
            }
          >
            <AddIcon /> Add value
          </Button>
        </div>
      )}
    </FieldArray>
  )
}

const AttributeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/attributes",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Attribute saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error:any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )

  const initialValues = useMemo(
    () => ({
      values: [
        {
          title: "",
          value: "",
        },
      ],
    }),
    []
  )

  return (
    <Create {...props}>
      <SimpleForm
        toolbar={<AttributeCreateToolbar />}
        initialValues={initialValues}
        save={save}
      >
        <SectionTitle label="resources.attributes.fieldGroups.information" />
        <TextInput
          source="title"
          autoFocus
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          formClassName={classes.description}
        />
        <SelectInput
          source="type"
          choices={[
            { id: "color", name: "Color" },
            { id: "image", name: "Image" },
            { id: "label", name: "Label" },
          ]}
        />

        <SectionTitle label="resources.attributes.fieldGroups.values" />
        <RenderValueInput name="values" />
        <SectionTitle label="resources.attributes.fieldGroups.options" />
        <BooleanInput source="isVisible" label="Visible" />
      </SimpleForm>
    </Create>
  )
}

export default AttributeCreate
