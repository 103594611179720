import RateReviewIcon from '@material-ui/icons/RateReview';
import { ReviewCreate } from "./ReviewCreate"
import { ReviewEdit } from "./ReviewEdit"
import { ReviewList } from "./ReviewList"

export default {
  list: ReviewList,
  create: ReviewCreate,
  edit: ReviewEdit,
  icon: RateReviewIcon,
}
