import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  EditProps,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin"

const styles = {
  amount: { width: 544 },
  userIds: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const CreditSchemeTitle = ({ record }: any) => (
  <span>Edit Credit scheme `#${record.id}`</span>
)

export const CreditSchemeEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<CreditSchemeTitle />} {...props}>
      <SimpleForm>
        <NumberInput source="amount" fullWidth formClassName={classes.amount} />
        <ReferenceArrayInput
          source="userIds"
          reference="users/users"
          fullWidth
          formClassName={classes.amount}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}
