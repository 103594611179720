import StreetviewIcon from "@material-ui/icons/Streetview"
import { StateList } from "./StateList"
import { StateEdit } from "./StateEdit"
import { StateCreate } from "./StateCreate"

export default {
  list: StateList,
  edit: StateEdit,
  create: StateCreate,
  icon: StreetviewIcon,
}
