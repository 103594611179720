import * as React from "react"
import { FC, useState } from "react"
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  ImageInput,
  ImageField,
  CreateProps,
  AutocompleteArrayInput,
  useCreateSuggestionContext,
} from "react-admin"
import { useFormState } from "react-final-form"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField as MuiTextField,
} from "@material-ui/core"
import RichTextField from "ra-input-rich-text"

import { SectionTitle, requiredValidate } from "../../common/form"
import SeoKeywordInput from "../../common/form/SeoKeywordInput"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
  metaTitle: { width: 544 },
  metaDescription: { width: 544 },
}

const useStyles = makeStyles(styles)

export const BrandCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/brands",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Brand saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let e of error.body.errors) {
          e.map((e: any) => {
            err[e.field] = e.message
          })
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.brands.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          formClassName={classes.description}
        />
        <SectionTitle label="resources.brands.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Brand image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <SectionTitle label="resources.brands.fieldGroups.options" />
        <BooleanInput
          label="Featured"
          source="isFeatured"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Visible"
          source="isVisible"
          formClassName={classes.boolean}
        />
        <SectionTitle label="resources.brands.fieldGroups.seo" />
        <TextInput
          label="Meta title"
          source="seo.meta_title"
          fullWidth
          formClassName={classes.metaTitle}
        />
        <TextInput
          label="Meta description"
          source="seo.meta_description"
          fullWidth
          formClassName={classes.metaDescription}
        />
        <SeoKeywordInput />
      </SimpleForm>
    </Create>
  )
}
