import * as React from "react"
import { FC } from "react"
import { makeStyles } from "@material-ui/core"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"
import { AreaCluster } from "../../ts/types"

interface AreaClusterProps {
  record?: AreaCluster
}

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const AreaClusterTitle: FC<AreaClusterProps> = ({ record }) => (
  <span>Edit AreaCluster {record ? `"${record.name}"` : null}</span>
)

export const AreaClusterEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  const condition = (val: string) => !!val && val.trim().length > 5

  return (
    <Edit title={<AreaClusterTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.areaCluster.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />

        <ReferenceArrayInput
          label="Areas"
          source="areas"
          reference="shippings/areas"
          sort={{ field: "name", order: "ASC" }}
          perPage={10000}
          filterToQuery={(val: string) =>
            condition(val) ? { name: val.trim() } : {}
          }
          validate={requiredValidate}
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>

        <SectionTitle label="resources.areaCluster.fieldGroups.options" />
        <BooleanInput
          label="Free"
          source="isFree"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
