import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import { useFormState } from "react-final-form"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  NumberInput,
  SelectInput,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  title: { width: 544 },
  slug: { width: 544 },
  description: { width: 544 },
  roles: { width: 544 },
  brands: { width: 544 },
  categories: { width: 544 },
  products: { width: 544 },
  specialDiscountType: { display: "inline-block" },
  value: { display: "inline-block", marginLeft: 32 },
  startsAt: { display: "inline-block" },
  expiresAt: { display: "inline-block", marginLeft: 32 },
  minExpense: { display: "inline-block" },
  maxExpense: { display: "inline-block", marginLeft: 32 },
  total: { display: "inline-block" },
  perUserLimit: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

interface DiscountOnFieldsInputProps {}

const DiscountOnFieldsInput: FC<DiscountOnFieldsInputProps> = () => {
  const classes = useStyles()
  const { values } = useFormState()
  return values.discountOn && values.discountOn === "specific" ? (
    <div style={{ width: 544 }}>
      <SectionTitle label="resources.specialDiscounts.fieldGroups.specialDiscounts" />
      <ReferenceArrayInput
        label="Brands"
        source="brandIds"
        reference="products/brands"
        sort={{ field: "title", order: "ASC" }}
        perPage={100}
        fullWidth
        formClassName={classes.brands}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Categories"
        source="categoryIds"
        reference="products/categories"
        sort={{ field: "title", order: "ASC" }}
        filter={{ entry: true }}
        perPage={100}
        fullWidth
        formClassName={classes.categories}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Products"
        source="productIds"
        reference="products/products"
        sort={{ field: "title", order: "ASC" }}
        perPage={100}
        fullWidth
        formClassName={classes.products}
      >
        <AutocompleteArrayInput optionValue="id" optionText="title" />
      </ReferenceArrayInput>
    </div>
  ) : null
}

export const SpecialDiscountCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "products/specialDiscounts",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`Special discount saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.specialDiscounts.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          fullWidth
          formClassName={classes.description}
          multiline
        />
        <NumberInput
          source="value"
          validate={requiredValidate}
          helperText="Special discount percentage"
        />
        <SelectInput
          source="discountOn"
          choices={[
            { id: "all", name: "All products" },
            { id: "specific", name: "Special Products" },
          ]}
          helperText="Select the type of discount"
        />
        <ReferenceArrayInput
          label="Roles"
          source="roleIds"
          reference="users/roles"
          sort={{ field: "name", order: "ASC" }}
          perPage={10}
          fullWidth
          formClassName={classes.roles}
          helperText="Assign to specific roles"
        >
          <AutocompleteArrayInput optionValue="id" optionText="name" />
        </ReferenceArrayInput>
        <DiscountOnFieldsInput />
      </SimpleForm>
    </Create>
  )
}
