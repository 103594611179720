import * as React from "react"
import { FC, memo } from "react"
import { makeStyles } from "@material-ui/core"
import { FieldProps } from "react-admin"

const styles = {
  image: { width: 250, margin: 10 },
}

const useStyles = makeStyles(styles)

const VendorPaymentImageField: FC = ({ record, className }: any) => {
  const classes = useStyles()
  return (
    <img
      src={
        record.undefined
          ? record.undefined
          : `${process.env.REACT_APP_AWS_BUCKET_URL}${record}`
      }
      className={`${classes.image} ${className}`}
    />
  )
}

export default memo<FieldProps>(VendorPaymentImageField)
