import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  EditProps,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../common/form"

const styles = {
  id: { width: 544 },
  name: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const ShippingMethodTitle = ({ record }: any) => (
  <span>
    Edit shippings Method {record ? `"${record.name}"` : `#${record.id}`}
  </span>
)

export const ShippingMethodEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<ShippingMethodTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.shippingMethods.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput
          source="name"
          fullWidth
          formClassName={classes.name}
          validate={requiredValidate}
        />
        <SectionTitle label="resources.shippingMethods.fieldGroups.options" />
        <BooleanInput
          label="Status"
          source="status"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
