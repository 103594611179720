import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver"
import { AgentCreate } from "./AgentCreate"
import { AgentEdit } from "./AgentEdit"
import { AgentList } from "./AgentList"

export default {
  create: AgentCreate,
  list: AgentList,
  edit: AgentEdit,
  icon: RecordVoiceOverIcon,
}
