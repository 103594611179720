import { makeStyles } from "@material-ui/core"
import RichTextInput from "ra-input-rich-text"
import * as React from "react"
import { FC } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useCreateController,
  useMutation,
  CreateProps,
  NumberInput,
  BooleanInput,
  ImageInput,
  ImageField,
} from "react-admin"

import { SectionTitle, requiredValidate } from "../../../common/form"

const styles = {
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  shortDescription: { width: 544 },
  metaTitle: { width: 544 },
  metaDescription: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const condition = (val: string) => !!val && val.trim().length > 5

export const PageCMSCreate: FC<CreateProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const { basePath } = useCreateController(props)

  const [mutate] = useMutation()
  const save = React.useCallback(
    async (values, redirect) => {
      try {
        const mutant = await mutate(
          {
            type: "create",
            resource: "cms/pages",
            payload: { data: values },
          },
          { returnPromise: true }
        )
        notify(`PageCMS saved`)
        if (!redirect) {
          redirectTo("create", basePath)
        }
        redirectTo(redirect, basePath, mutant.data.id, mutant.data)
        // refresh()
      } catch (error: any) {
        let err: any = {}
        for (let i in error.body.errors) {
          err[error.body.errors[i].field] = error.body.errors[i].message
        }
        return err
      }
    },
    [mutate]
  )
  return (
    <Create {...props}>
      <SimpleForm save={save}>
        <SectionTitle label="resources.pageCMS.fieldGroups.information" />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="shortDescription"
          fullWidth
          formClassName={classes.shortDescription}
          multiline
        />
        <RichTextInput
          source="description"
          fullWidth
          formClassName={classes.description}
          toolbar={[
            ["bold", "italic", "underline", "link"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ font: [] }],
            [{ align: [] }],
            ["image"],
            [
              "table",
              "column-left",
              "column-right",
              "row-above",
              "row-below",
              "row-remove",
              "column-remove",
            ],
          ]}
        />
        <SectionTitle label="resources.pageCMS.fieldGroups.seo" />
        <TextInput
          source="metaTitle"
          fullWidth
          formClassName={classes.metaTitle}
        />
        <TextInput
          source="metaDescription"
          fullWidth
          formClassName={classes.metaDescription}
        />
      </SimpleForm>
    </Create>
  )
}
