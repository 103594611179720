import * as React from "react"
import { FC, memo } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { FieldProps } from "react-admin"

const styles = {
  image: { width: 500, margin: 10 },
}

const useStyles = makeStyles(styles)

const OrderImageField: FC = ({ record, className }: any) => {
  const classes = useStyles()
  return (
    <div>
      <Typography variant="h6">Voucher Image</Typography>
      <img
        src={
          record.undefined
            ? record.undefined
            : `${process.env.REACT_APP_AWS_BUCKET_URL}${record}`
        }
        className={`${classes.image} ${className}`}
      />
    </div>
  )
}

export default memo<FieldProps>(OrderImageField)
