import * as React from "react"
import inflection from "inflection"
import { Card, CardContent } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import GroupIcon from "@material-ui/icons/Group"
import { FilterList, FilterListItem, useGetList } from "react-admin"
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1,
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

const Aside = () => {
  const { data: roleData, ids: roleIds } = useGetList(
    "users/roles",
    { page: 1, perPage: 100 },
    { field: "name", order: "ASC" },
    {}
  )

  const activationStatus = [
    { id: true, name: "Yes" },
    { id: false, name: "No" },
  ]

  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <FilterList label="resources.users.filters.roles" icon={<GroupIcon />}>
          {roleIds &&
            roleData &&
            roleIds.map((id: any) => (
              <FilterListItem
                label={inflection.humanize(roleData[id].name)}
                key={roleData[id].id}
                value={{ role: roleData[id].name }}
              />
            ))}
        </FilterList>
        <FilterList
          label="resources.users.filters.activationStatus"
          icon={<LocalOfferIcon />}
        >
          {activationStatus.map((status) => (
            <FilterListItem
              label={status.name}
              key={status.name}
              value={{ isActivated: status.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  )
}

export default Aside
