import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  EditProps,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  TextField,
  NumberInput,
  InputProps,
  DateField,
  Link,
} from "react-admin"
import { useFormState } from "react-final-form"
import { makeStyles, Box, Typography } from "@material-ui/core"
import { FieldArray } from "react-final-form-arrays"
import Alert from "@material-ui/lab/Alert"

import { SectionTitle } from "../../common/form"

const styles = {
  id: { display: "inline-block" },
  status: { display: "inline-block", marginLeft: 32 },
  quantity: { display: "inline-block" },
  price: { display: "inline-block", marginLeft: 32 },
  pickupBoy: { display: "inline-block" },
  adminPayment: { display: "inline-block", marginLeft: 32 },
}

const useStyles = makeStyles(styles)

const OrderItemTitle = ({ record }: any) => (
  <span>Edit OrderItem {record && `#${record.id}`}</span>
)

const condition = (val: string) => !!val && val.trim().length > 5

const ConfField = ({ data }: any) => {
  if (!data) return null
  const keys = Object.keys(data)
  return (
    <Typography>
      {keys.map((key) => (
        <span>
          {key}: <strong>{data[key].title}</strong>
        </span>
      ))}
    </Typography>
  )
}

const OrderLinkField = ({ record }: any) => {
  if (!record) return null
  return (
    <>
      <Typography variant="caption">Order</Typography>
      <Link to={`/orders/orders/${record?.order?.id}`}>
        <Typography>{record?.order?.trackingNumber}</Typography>
        {/* <TextField source="order" defaultValue={record?.order?.trackingNumber} /> */}
      </Link>
    </>
  )
}

const UpdatePriceInput = (props: InputProps) => {
  const { values } = useFormState()

  values["powerUpdate"] = true

  if (!values) return null

  return (
    <Box width={580}>
      <Typography variant="h6" gutterBottom>
        Price and Quantity
      </Typography>
      <Box width={544}>
        <Alert severity="info">
          <Typography variant="body2" gutterBottom>
            This is power user update section. Please, be wise. And perform
            actions only when you know what you are doing.
          </Typography>
        </Alert>
      </Box>
      {!values.hasVariation && (
        <Box display="flex">
          <Box flex={1}>
            <NumberInput {...props} source="quantity" fullWidth />
          </Box>
          <Box flex={1}>
            <NumberInput {...props} source="price" fullWidth />
          </Box>
        </Box>
      )}
      {values.hasVariation && (
        <>
          <FieldArray name="variations">
            {({ fields }) => (
              <>
                {fields.map((name, index) => (
                  <>
                    <ConfField data={values.variations[index]?.conf} />
                    <Box display="flex" width={544}>
                      <Box flex={1}>
                        <NumberInput
                          source={`${name}.quantity`}
                          label="Quantity"
                        />
                      </Box>
                      <Box display={1}>
                        <NumberInput source={`${name}.price`} label="Price" />
                      </Box>
                    </Box>
                  </>
                ))}
              </>
            )}
          </FieldArray>
        </>
      )}
    </Box>
  )
}

export const OrderItemEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<OrderItemTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.orderItems.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "approve", name: "Approve" },
            { id: "pickup", name: "Pickup" },
            { id: "delivering", name: "Delivering" },
            { id: "delivered", name: "Delivered" },
            { id: "cancelled", name: "Cancelled" },
            { id: "defect", name: "Defect" },
            { id: "refund", name: "Refund" },
          ]}
          formClassName={classes.status}
        />
        <div />
        <ReferenceInput
          source="pickupBoyId"
          reference="users/users"
          filter={{ role: "DELIVERYBOY" }}
          formClassName={classes.pickupBoy}
        >
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <SelectInput
          label="Paid to Admin"
          source="refundPaymentStatus.status"
          choices={[
            { id: true, name: "Yes" },
            { id: false, name: "No" },
          ]}
          formClassName={classes.adminPayment}
        />
        <ReferenceField source="product" reference="products/products">
          <TextField source="title" />
        </ReferenceField>
        <OrderLinkField source="order" />
        <UpdatePriceInput source="" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleForm>
    </Edit>
  )
}
