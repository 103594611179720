import EditAttributesIcon from "@material-ui/icons/EditAttributes"
import AttributeCreate from "./AttributeCreate"
import AttributeEdit from "./AttributeEdit"
import AttributeList from "./AttributeList"

export default {
  create: AttributeCreate,
  list: AttributeList,
  edit: AttributeEdit,
  icon: EditAttributesIcon,
}
