import * as React from "react"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import {
  useTranslate,
  useLocale,
  useSetLocale,
  Title,
  useGetList,
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
} from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import { changeTheme } from "./actions"
import { AppState } from "../ts/types"
import { Typography } from "@material-ui/core"
import UpdateConfigButton from "./UpdateConfigButton"

const useStyles = makeStyles({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "0px" },
})

function humanize(str: string): string {
  var i: number,
    frags = str.split("_")
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

const Configuration = () => {
  const translate = useTranslate()
  const locale = useLocale()
  const setLocale = useSetLocale()
  const classes = useStyles()
  const theme = useSelector((state: AppState) => state.theme)
  const dispatch = useDispatch()

  // const { data, ids, loading, error } = useGetList(
  //   "settings/settings",
  //   { page: 1, perPage: 100 },
  //   { field: "id", order: "ASC" }
  // )

  return (
    <Card>
      <Title title={translate("pos.configuration")} />
      <CardContent>
        <div className={classes.label}>
          <Typography>{translate("pos.theme.name")}</Typography>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === "light" ? "primary" : "default"}
          onClick={() => dispatch(changeTheme("light"))}
        >
          {translate("pos.theme.light")}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === "dark" ? "primary" : "default"}
          onClick={() => dispatch(changeTheme("dark"))}
        >
          {translate("pos.theme.dark")}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>
          <Typography>{translate("pos.language")}</Typography>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === "en" ? "primary" : "default"}
          onClick={() => setLocale("en")}
        >
          en
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === "fr" ? "primary" : "default"}
          onClick={() => setLocale("fr")}
        >
          fr
        </Button>
      </CardContent>
      <CardContent>
        <Box display="flex">
          <Box flex={1}>
            <List
              title={<span></span>}
              pagination={false}
              hasCreate={false}
              basePath="/settings/settings"
              resource="settings/settings"
              sort={{ field: "updatedAt", order: "DESC" }}
              exporter={false}
              bulkActionButtons={false}
              actions={false}
              empty={<></>}
              perPage={5}
            >
              <Datagrid>
                <FunctionField
                  label="Name"
                  source="name"
                  render={(record: any) => (
                    <Typography>{humanize(record.name)}</Typography>
                  )}
                />
                <TextField source="value" />
                <DateField source="updatedAt" showTime />
                <UpdateConfigButton />
              </Datagrid>
            </List>
          </Box>
          <Box flex={1}></Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default Configuration
