import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"
import OmsForm from "./OmsForm"
import { Link, useGetList, useUpdate } from "react-admin"

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    alignItems: "center",
    borderColor: green[500],
    backgroundColor: green[50],
    borderRadius: 5,
  },
  paperDanger: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    alignItems: "center",
    borderColor: red[500],
    backgroundColor: red[50],
    borderRadius: 5,
  },
}))

export default function OMS() {
  const { data, ids } = useGetList(
    "oms/oms",
    {
      page: 1,
      perPage: 1000,
    },
    { field: "updatedAt", order: "DESC" },
    { isCompleted: false }
  )

  const { total } = useGetList(
    "oms/boxes",
    {
      page: 1,
      perPage: 1,
    },
    { field: "updatedAt", order: "DESC" }
  )

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Order Management System
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <OmsForm />
          <Box display="flex" gridGap={20}>
            <Typography variant="body1" gutterBottom>
              Packaging List: {total}
            </Typography>
          </Box>
        </Box>

        <OmsDetails data={data} ids={ids} />
      </CardContent>
    </Card>
  )
}

const OmsDetails = ({ data, ids }) => {
  const classes = useStyles()

  const [update] = useUpdate()

  const handleUpdate = (id, data) => {
    update("oms/oms", id, data)
  }

  return (
    <div className={classes.main}>
      <Typography variant="h6" gutterBottom>
        Orders and Management
      </Typography>
      <Grid container spacing={1}>
        {ids &&
          ids.map((id, _) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <div className={classes.title}>
                    <Typography variant="body1">
                      <strong>Order #{data[id]?.order?.trackingNumber}</strong>
                    </Typography>
                    <CircularProgressWithLabel
                      value={
                        (data[id]?.entriedItemsCount /
                          data[id]?.totalItemsCount) *
                        100
                      }
                    />
                  </div>
                  <hr />
                  <Typography variant="body1">Entried Items</Typography>
                  <List>
                    {data[id]?.entriedItems
                      ?.slice(0, 5)
                      .map((entriedItem, index: number) => {
                        const item = data[id]?.entriedItemDocs?.find(
                          (doc) => doc._id === entriedItem.item
                        )

                        return (
                          <>
                            <Paper className={classes.paper}>
                              <Link
                                to={`/products/products/${item?.product?._id}`}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gridGap={10}
                                >
                                  <Avatar
                                    src={
                                      process.env.REACT_APP_AWS_BUCKET_URL +
                                      item?.product?.media[0]
                                    }
                                  />
                                  <div>
                                    <Typography variant="body1">
                                      {item?.product?.title}
                                    </Typography>
                                    <Typography variant="body2">
                                      {entriedItem.combinationTitle
                                        ? `${entriedItem.combinationTitle}`
                                        : ""}
                                    </Typography>
                                  </div>
                                </Box>
                              </Link>
                              <Typography variant="body1">
                                X{entriedItem.quantity}
                              </Typography>
                            </Paper>
                          </>
                        )
                      })}
                    {data[id]?.entriedItems?.length > 5 && (
                      <Typography variant="body1">
                        {data[id]?.entriedItems?.length - 5} more items
                      </Typography>
                    )}
                  </List>
                  <Typography variant="body1">Remaining Items</Typography>
                  <List>
                    {data[id]?.remainingItems
                      ?.slice(0, 5)
                      .map((remainingItem, index) => {
                        const item = data[id]?.remainingItemDocs?.find(
                          (doc) => doc._id === remainingItem.item
                        )

                        return (
                          <>
                            <Paper className={classes.paperDanger}>
                              <Link
                                to={`/products/products/${item?.product?._id}`}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gridGap={10}
                                >
                                  <Avatar
                                    src={
                                      process.env.REACT_APP_AWS_BUCKET_URL +
                                      item?.product?.media[0]
                                    }
                                  />
                                  <div>
                                    <Typography variant="body1">
                                      {item?.product?.title}
                                    </Typography>
                                    <Typography variant="body2">
                                      {remainingItem.combinationTitle
                                        ? `${remainingItem.combinationTitle}`
                                        : ""}
                                    </Typography>
                                  </div>
                                </Box>
                              </Link>
                              <Typography variant="body1">
                                X{remainingItem.quantity}
                              </Typography>
                            </Paper>
                          </>
                        )
                      })}
                    {data[id]?.remainingItems?.length > 5 && (
                      <Typography variant="body1">
                        {data[id]?.remainingItems?.length - 5} more items
                      </Typography>
                    )}
                    {!data[id]?.remainingItems?.length && (
                      <>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          All items have been entered
                        </Typography>
                        <Link to={`/oms/scan/${data[id].trackingNumber}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              handleUpdate(id, { isCompleted: true })
                            }
                          >
                            Proceed packaging
                          </Button>
                        </Link>
                      </>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        {ids.length === 0 && (
          <Typography variant="body2" style={{ marginLeft: 6 }}>
            No orders are being managed.
          </Typography>
        )}
      </Grid>
    </div>
  )
}

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}
