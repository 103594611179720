import {
  Avatar,
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Grid,
  Link as MuiLink,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import { useCallback, useState } from "react"
import { Button, Link, useRefresh, useUpdate } from "react-admin"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import InfoIcon from "@material-ui/icons/Info"
import InputIcon from "@material-ui/icons/Input"
import MoreIcon from "@material-ui/icons/MoreVert"
import PrintIcon from "@material-ui/icons/Print"

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    alignItems: "center",
    borderRadius: 5,
  },
}))

const PackagingBoxDetails = ({
  packagingBox,
  setItem,
  setBoxNumber,
  setStateBox,
  setShowDialog,
  setShowBoxDetailDialog,
  trackingNumber,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElBox, setAnchorElBox] = useState<null | HTMLElement>(null)

  const refresh = useRefresh()

  const [removeBox, { loading: loadingRemove, error: errorRemove }] =
    useUpdate()
  const [addItemToBox, { loading: loadingAddItem, error: errorAddItem }] =
    useUpdate()
  const [addBox, { loading, error }] = useUpdate()
  const [updateBox, { loading: loadingUpdate, error: errorUpdate }] =
    useUpdate()

  const handleAddItemMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBox(event.currentTarget)
  }

  const handleAddItemToBoxClose = () => {
    setAnchorEl(null)
    setItem(null)
    setBoxNumber(undefined)
    setStateBox(null)
  }

  const handleAddItemToBox = (remainingItemIndex, stateBox, boxNumber) => {
    setShowDialog(true)
    setItem(packagingBox?.remainingItems[remainingItemIndex])
    setBoxNumber(boxNumber)
    setStateBox(stateBox)
  }

  const handleMoreMenuClose = () => {
    setAnchorElBox(null)
  }

  const handleRemoveBox = useCallback(
    async (id: string) => {
      await removeBox(
        "oms/boxes/remove",
        id,
        {},
        {},
        {
          onSuccess: () => {
            // fetchPackagingBoxByTrackingNumber(trackingNumber)
            setAnchorElBox(null)
            refresh()
          },
          onFailure: () => {
            setAnchorElBox(null)
          },
        }
      )
    },
    [trackingNumber]
  )

  const handleAddBox = useCallback(async () => {
    await addBox(
      "oms/boxes/add",
      trackingNumber,
      {},
      {},
      {
        onSuccess: () => {
          refresh()
        },
      }
    )
  }, [trackingNumber])

  const handleUpdateBox = useCallback(
    async (id: string, data: any) => {
      await updateBox(
        "oms/boxes/update-box",
        id,
        data,
        {},
        {
          onSuccess: () => {
            refresh()
          },
        }
      )
    },
    [trackingNumber]
  )

  return (
    <Grid container spacing={2}>
      {/* Aside */}
      <Grid item xs={4} lg={3}>
        <Card>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              <strong>Items</strong>
            </Typography>
            {packagingBox?.remainingItems?.map(
              (item: any, remainingItemIndex: number) => (
                <div key={item.id}>
                  {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                  <Paper className={classes.paper}>
                    <Link to={`/products/products/${item?.item?.product?._id}`}>
                      <Box display="flex" alignItems="center" gridGap={10}>
                        <Avatar
                          src={
                            process.env.REACT_APP_AWS_BUCKET_URL +
                            item?.item?.product?.media[0]
                          }
                        />
                        <div>
                          <Typography variant="body1">
                            {item?.item?.product?.title}
                          </Typography>
                          <Typography variant="body2">
                            {item?.combinationTitle
                              ? `${item?.combinationTitle}`
                              : ""}
                          </Typography>
                          <Typography variant="body1">
                            {item?.barcode}
                          </Typography>
                        </div>
                      </Box>
                    </Link>
                    <Typography variant="body1">X{item?.quantity}</Typography>
                  </Paper>
                </div>
              )
            )}
            {!packagingBox?.remainingItems?.length && (
              <Typography variant="body1">All items are packaged.</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      {/* Main Content */}
      <Grid item xs={8} lg={9}>
        <Card>
          <CardContent>
            <Typography variant="body1" gutterBottom>
              Packaging Details
            </Typography>
            <Grid container spacing={2}>
              {packagingBox?.boxes?.map((box: any, index: number) => (
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gridGap={10}
                        marginBottom={2}
                      >
                        <Typography variant="body1">
                          <strong>Box {index + 1}</strong>
                        </Typography>
                        {/* <pre>{JSON.stringify(box, null, 2)}</pre> */}
                        {/* <Button
                        startIcon={
                          <DeleteIcon style={{ color: red[500] }} />
                        }
                        style={{ padding: 0 }}
                        onClick={() => handleRemoveBox(box._id)}
                      /> */}
                        <div>
                          <Switch
                            // value={true}
                            checked={box?.closed}
                            onChange={(e) =>
                              handleUpdateBox(box._id, {
                                closed: !box?.closed,
                              })
                            }
                          ></Switch>
                          <MuiLink
                            href={`${
                              process.env.NODE_ENV === "development"
                                ? "http://localhost:4000"
                                : ""
                            }/api/admin/orders/packaging-slip/${
                              packagingBox?.order?.id
                            }?barcode=${box?.barcode}&box=${
                              index + 1
                            }&totalbox=${packagingBox?.boxes?.length}`}
                            target="_blank"
                          >
                            <Button
                              startIcon={
                                <PrintIcon
                                  style={{ color: "gray", fontSize: 22 }}
                                />
                              }
                            />
                          </MuiLink>
                          <Button
                            label=""
                            startIcon={
                              <DeleteIcon
                                style={{ color: red[500], fontSize: 22 }}
                              />
                            }
                            onClick={() => handleRemoveBox(box._id)}
                          />
                        </div>
                      </Box>
                      {/* {JSON.stringify(box, null, 2)} */}
                      <hr />
                      {box?.items?.map((boxItem: any, index: number) => (
                        <>
                          {/* <pre>{boxItem?.item?.product?.title}</pre> */}
                          <Paper className={classes.paper}>
                            <Link
                              to={`/products/products/${boxItem?.item?.product?._id}`}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                gridGap={10}
                              >
                                <Avatar
                                  src={
                                    process.env.REACT_APP_AWS_BUCKET_URL +
                                    boxItem?.item?.product?.media[0]
                                  }
                                />
                                <div>
                                  <Typography variant="body1">
                                    {boxItem?.item?.product?.title}
                                  </Typography>
                                  <Typography variant="body2">
                                    {boxItem?.combinationTitle
                                      ? `${boxItem?.combinationTitle}`
                                      : ""}
                                  </Typography>
                                </div>
                              </Box>
                            </Link>
                            <Typography variant="body1">
                              X{boxItem?.quantity}
                            </Typography>
                          </Paper>
                        </>
                      ))}
                      {/* <pre>{JSON.stringify(box, null, 2)}</pre> */}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Button
                      label="Add Box"
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleAddBox}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PackagingBoxDetails
