import { TextField } from "@material-ui/core"

export default function BarcodeScanner({ barcode, setBarcode }: any) {
  const onChangeBarcode = (event) => {
    setBarcode(event.target.value)
  }

  const barcodeAutoFocus = () => {
    document.getElementById("barcode")?.focus()
  }

  return (
    <TextField
      name="barcode"
      label="Barcode"
      id="barcode"
      type="number"
      placeholder="Start Scanning"
      autoFocus={true}
      variant="outlined"
      // onBlur={barcodeAutoFocus}
      size="small"
      onChange={onChangeBarcode}
      value={barcode}
    />
  )
}
