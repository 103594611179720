import { makeStyles } from "@material-ui/core"
import * as React from "react"
import { FC } from "react"
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ImageInput,
  ImageField,
  EditProps,
} from "react-admin"
import RichTextField from "ra-input-rich-text"

import { SectionTitle, requiredValidate } from "../../common/form"
import BrandImageField from "./BrandImageField"
import SeoKeywordInput from "../../common/form/SeoKeywordInput"

const styles = {
  id: { display: "inline-block" },
  slug: { display: "inline-block", marginLeft: 32 },
  title: { width: 544 },
  description: { width: 544 },
  image: { width: 544 },
  boolean: { display: "inline-block" },
}

const useStyles = makeStyles(styles)

const BrandTitle = ({ record }: any) => (
  <span>Edit Brand {record ? `"${record.title}"` : `#${record.id}`}</span>
)

export const BrandEdit: FC<EditProps> = (props) => {
  const classes = useStyles()

  return (
    <Edit title={<BrandTitle />} {...props}>
      <SimpleForm>
        <SectionTitle label="resources.brands.fieldGroups.information" />
        <TextInput disabled source="id" formClassName={classes.id} />
        <TextInput disabled source="slug" formClassName={classes.slug} />
        <TextInput
          source="title"
          fullWidth
          formClassName={classes.title}
          validate={requiredValidate}
        />
        <TextInput
          source="description"
          multiline
          fullWidth
          formClassName={classes.description}
        />
        <SectionTitle label="resources.brands.fieldGroups.image" />
        <ImageInput
          source="image"
          label="Brand image"
          accept="image/*"
          placeholder={<p>Drop your image here</p>}
          formClassName={classes.image}
        >
          <BrandImageField />
        </ImageInput>
        <SeoKeywordInput />
        <SectionTitle label="resources.brands.fieldGroups.options" />
        <BooleanInput
          label="Featured"
          source="isFeatured"
          formClassName={classes.boolean}
        />
        <BooleanInput
          label="Visible"
          source="isVisible"
          formClassName={classes.boolean}
        />
      </SimpleForm>
    </Edit>
  )
}
