import { subDays } from "date-fns"
import * as React from "react"
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DatagridProps,
  DateField,
  EditButton,
  FieldProps,
  Filter,
  FilterProps,
  FunctionField,
  Link,
  List,
  ListProps,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useGetList,
  useListContext,
} from "react-admin"

import { Theme, useMediaQuery } from "@material-ui/core"

import green from "@material-ui/core/colors/green"
import orange from "@material-ui/core/colors/orange"
import red from "@material-ui/core/colors/red"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"

import OrderAside from "./OrderAside"
import AssignDeliveryBoyButton from "./AssignDeliveryBoyButton"
import MakeCODPaymentReceivedButton from "./MakeCODPaymentReceivedButton"
import OrderShow from "./OrderShow"
import {
  optionRenderer,
  orderStatus,
  paymentStatus,
  paymentTypes,
} from "./common"

const aMonthAgo = subDays(new Date(), 30).toISOString()
const aWeekAgo = subDays(new Date(), 7).toISOString()
const aDayAgo = subDays(new Date(), 1).toISOString()

const rowStyle = (record: any) => {
  let style = {}
  if (!record) {
    return style
  }
  if (record.paymentStatus === "success")
    style = {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.paymentStatus === "pending")
    style = {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.paymentStatus === "failed")
    style = {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
    }
  if (record.createdAt > aDayAgo)
    style = { ...style, backgroundColor: green[50] }
  if (record.createdAt < aDayAgo && record.createdAt > aWeekAgo)
    style = { ...style, backgroundColor: orange[50] }
  if (record.createdAt < aWeekAgo && record.createdAt > aMonthAgo)
    style = { ...style, backgroundColor: red[50] }
  return style
}

const ProductField: React.FC<FieldProps> = ({ record }: any) => {
  const { items } = record
  if (!items) return null
  return items
    ? items.map((item, index) =>
        item.product ? (
          <div key={index}>
            {/* @ts-ignore */}
            <Link to={`/products/products/${item.product.id}`}>
              {item.product.title}
            </Link>
          </div>
        ) : null
      )
    : null
}

const OrderFilter: React.FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <TextInput source="trackingNumber" alwaysOn />
  </Filter>
)

const condition = (val: string) => !!val && val.trim().length > 6

const orderFilters = (isSmall: any) => {
  const filters = [<TextInput source="trackingNumber" alwaysOn />]
  filters.push(<NumberInput source="orderNumber" alwaysOn />)
  filters.push(
    <ReferenceInput
      source="userShippingAreaId"
      reference="shippings/areas"
      perPage={10000}
      filterToQuery={(val: string) =>
        condition(val) ? { title: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput />
    </ReferenceInput>
  )
  filters.push(
    <ReferenceInput
      source="deliveryBoy"
      reference="users/users"
      filter={{ role: "DELIVERYBOY" }}
      sort={{ field: "name", order: "ASC" }}
      perPage={1000}
      filterToQuery={(val: string) =>
        condition(val) ? { title: val.trim() } : {}
      }
      alwaysOn
    >
      <AutocompleteInput />
    </ReferenceInput>
  )
  filters.push(<BooleanInput source="needVatBill" />)
  filters.push(
    <ReferenceInput source="userId" reference="users/users" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  )
  if (isSmall) {
    filters.push(<SelectInput source="orderStatus" choices={orderStatus} />)
    filters.push(<SelectInput source="paymentType" choices={paymentTypes} />)
    filters.push(
      <SelectInput
        source="paymentStatus"
        choices={paymentStatus}
        optionText={optionRenderer}
      />
    )
    filters.push(
      <BooleanInput label="Payment To Admin" source="paymentToAdmin.status" />
    )
  }
  return filters
}

const OrderBulkActionButtons = (props) => {
  const { filterValues } = props
  const orderStatusList = ["pending", "confirmed", "approve", "pickup"]
  return (
    <>
      {filterValues.orderStatus === "delivered" ? (
        <MakeCODPaymentReceivedButton lable="Update COD" {...props} />
      ) : null}
      {orderStatusList.includes(filterValues.orderStatus) ||
      Object.keys(filterValues).length === 0 ? (
        <AssignDeliveryBoyButton label="Update Delivery Boy" {...props} />
      ) : null}
    </>
  )
}

const OrderPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const UserField: React.FC<FieldProps> = ({ record }: any) =>
  record && record.user ? (
    // @ts-ignore
    <Link to={`/users/users/${record.user.id}`}>{record.user.name}</Link>
  ) : null

const useGetTotals = (filterValues: any) => {
  const { total: totalPending } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "pending" }
  )
  const { total: totalApprove } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "approve" }
  )
  const { total: totalConfirmed } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "confirmed" }
  )
  const { total: totalPickup } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "pickup" }
  )
  const { total: totalDelivering } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "delivering" }
  )
  const { total: totalDelivered } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "delivered" }
  )
  const { total: totalOutOfStock } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "outOfStock" }
  )
  const { total: totalCancelled } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "cancelled" }
  )
  const { total: totalDefect } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "defect" }
  )
  const { total: totalRefund } = useGetList(
    "orders/orders",
    { perPage: 2, page: 1 },
    { field: "updatedAt", order: "ASC" },
    { ...filterValues, orderStatus: "refund" }
  )

  return {
    pending: totalPending,
    confirmed: totalConfirmed,
    approve: totalApprove,
    pickup: totalPickup,
    delivering: totalDelivering,
    delivered: totalDelivered,
    outOfStock: totalOutOfStock,
    cancelled: totalCancelled,
    defect: totalDefect,
    refund: totalRefund,
  }
}

export const OrderList: React.FC<ListProps> = (props) => {
  const listContext = useListContext()
  const { filterValues } = listContext
  const totals = useGetTotals(filterValues) as any
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"))
  return (
    <List
      {...props}
      filters={orderFilters(isSmall)}
      bulkActionButtons={<OrderBulkActionButtons />}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={25}
      pagination={<OrderPagination />}
      aside={<OrderAside totals={totals} />}
    >
      <Datagrid
        optimized
        rowStyle={rowStyle}
        rowClick="expand"
        expand={<OrderShow />}
      >
        <DateField source="createdAt" showTime />
        <FunctionField
          label="Tracking Number"
          render={(record) =>
            record.trackingNumber ? (
              <span style={{ fontWeight: "bold" }}>
                {record.trackingNumber}
              </span>
            ) : null
          }
        />
        <FunctionField
          label="Order Status"
          render={(record) =>
            optionRenderer({
              name: orderStatus[
                orderStatus.map((item) => item.id).indexOf(record?.orderStatus)
              ]?.name,
              color:
                orderStatus[
                  orderStatus
                    .map((item) => item.id)
                    .indexOf(record?.orderStatus)
                ]?.color,
              icon: orderStatus[
                orderStatus.map((item) => item.id).indexOf(record?.orderStatus)
              ]?.icon,
            })
          }
        />
        <TextField
          source="paymentType"
          style={{ textTransform: "uppercase" }}
        />
        <UserField source="user" />
        <TextField label="Area" source="area.name" />
        <ReferenceField source="deliveryBoy" reference="users/users">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Payment to admin"
          source="paymentToAdmin.status"
          render={(record) =>
            record.paymentType === "cod" && record.paymentToAdmin.status ? (
              <CheckCircleIcon fontSize="small" style={{ color: green[500] }} />
            ) : record.paymentType !== "cod" ? (
              record.paymentStatus == "success" ? (
                <CheckCircleIcon
                  fontSize="small"
                  style={{ color: green[500] }}
                />
              ) : (
                <CancelIcon fontSize="small" style={{ color: red[500] }} />
              )
            ) : (
              <CancelIcon fontSize="small" style={{ color: red[500] }} />
            )
          }
        />
        <NumberField source="itemsCount" />
        <FunctionField
          label="Total Price"
          sortBy="totalPrice"
          render={(record) =>
            record.totalPrice ? (
              <span style={{ fontWeight: "bold" }}>
                रू{" "}
                {(record.totalPrice + record.shippingCost).toLocaleString(
                  "en-IN",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </span>
            ) : null
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  )
}
